<template>
    <div>
        <section class="bg-forest">
            <MDBContainer class="p-5 bg-form">
                <MDBRow class="mb-3">
                    <MDBCol md="12" class="mx-auto text-light item-align-center">
                        <MDBCardText style="text-align : justify;" class="">
                            <h2 class="mb-4 text-light text-center">Artistic Modification of Range Office Campus at Shimla Water Catchment</h2>
                            <p>
                                <span class="para-img ml-20" style="text-align : right">
                                    <img src="/assets/images/updates/2023/october/001/1.jpeg" class="img-round-corner" style="max-height : 375px; width : auto; max-width : 100%;">
                                    <br>
                                    <!-- <small><i>- Administrative map of Shimla Water Catchment Wildlife Sanctuary</i></small> -->
                                </span>
                                <span class="para-img float-left mr-20" style="text-align : right">
                                    <img src="/assets/images/updates/2023/october/001/5.jpeg" class="img-round-corner" style="max-height : 345px; width : auto; max-width : 100%;">
                                    <br>
                                    <!-- <small><i>- Administrative map of Shimla Water Catchment Wildlife Sanctuary</i></small> -->
                                </span>
                                The Shimla Water Catchment Wildlife Sanctuary is a unique protected area located few
                                kilometers away from the heart of Shimla City. Considering it is an urban protected area, there is great
                                scope in terms of promoting wildlife awareness and conservation education in the sanctuary which is
                                home to incredible biodiversity in the form of 166 native plants, 21 species of terrestrial orchids, 146
                                species of birds, 54 species of butterflies and numerous reptilian and mammalian species.
                                <br><br> 
                                A thought emanated that entrance point of the sanctuary and the range office campus in total should depict the feel of the forests and sanctuary giving visitors an impression of the woody nature walk and the water and biodiversity held within. Pursuant to this, a design was carved out to modify the range office and the eco shop building giving it a makeover with wooden texture and carvings engulfing the building resembling a forest setting with the pillars of the building transformed to tree shape.
                                <br><br>
                                The wall at the entrance was painted with the art depicting the journey of the eco tourists inside the area- the streams that crisscross the sanctuary, the occasional deer that passes, the majestic Koklass pheasant for which the area is famed, the flowers that bloom the landscape encapsulating the total feel of the sanctuary area on a bike or on foot. With the addition of grey langur on the entrance arch and its repainting along with wooden hanging board, the sanctuary has now a fitting entrance area immersed in art and biodiversity in synchrony with the heritage it is renowned for and the immense value of conservation education it provides.
                                
                            </p>
                            

                        </MDBCardText>
                    </MDBCol>
                </MDBRow>                     
            </MDBContainer>
        </section>        
    </div>
</template>
<script>
  import { ref } from "vue";
  import { 
    MDBCol, MDBRow, MDBContainer,
    MDBInput,
    MDBCheckbox,
    MDBBtn,
    MDBTextarea,
    MDBModal,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBCarousel,
    MDBCardText,
    MDBCardImg, mdbRipple

  } from "mdb-vue-ui-kit";
  export default {
    name : "Updates_October_001",
    components: {
        MDBCol, MDBRow, MDBContainer,
        MDBInput,
        MDBCheckbox,
        MDBBtn,
        MDBTextarea,
        MDBModal,
        MDBModalHeader,
        MDBModalTitle,
        MDBModalBody,
        MDBModalFooter,
        MDBCarousel,
        MDBCardText,
        MDBCardImg
    },
    directives: {
      mdbRipple
    },
    setup() {
      const items1 = [
        {
          src: "/assets/images/contact-us.jpg",
          alt: "Shimla Wildlife Division",
        }
      ];
      const exampleModal = ref(false);
      const carousel1 = ref(0);
      return {
        exampleModal,
        items1,
        carousel1,
        form : {
            name : '',
            email : '',
            phone : '',
            query : '',
            city : '',
            newsletter : true
        },
        modal : {
            show : true,
            title : 'SUCCESS!!',
            body : 'Your query has been posted successfully! We will get back to you soon'
        }
      };
    },
    methods : {
        submitForm(){

            this.exampleModal = true;
        }
    }
  };
</script>
<style scoped>

    .bg-dark-green {
        background-color: rgb(77,169,80);
    }

    .para-img {
        float: right;
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
    }

    .bg-contact {
        background: linear-gradient(rgba(44, 95, 45, 0.5), rgba(44, 95, 45,0.5)), url('/assets/img/contact-bg.svg');
    }

    .float-left {
        float: left !important;
    }

    .mr-20 {
        margin-right : 20px
    }

    .ml-20 {
        margin-left : 20px
    }

    .bg-trago {
        background: linear-gradient(rgba(44, 95, 45, 0.9), rgba(44, 95, 45,0.9)), url('/assets/img/re_6.jpeg') center ;
    }

    .bg-forest {
        background: url('/assets/img/Untitled design-15.png') ;
        background-size: cover;
    }

    .img-round-corner {
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
    }

    .bg-form {
        background-color : rgb(66,136,121);
    }

    .form-title {

        border-left: 5px solid rgb(138,250,160);
        padding-left: 10px;
    }

    .form-color {
        color : #4F4F4F !important;
        border-color: transparent !important;
        background-color: white !important;
    }
    .update-container {
        overflow: hidden;        
    }
    .responsive-iframe {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

</style>