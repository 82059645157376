<template>
    <div>
        <MDBCarousel
            v-model="carousel1"
            :items="items1"
            fade
            :controls="false"
            :indicators="false"
        />
        <section >
            <MDBContainer class="p-5">
                <h4 class="mb-4 text-dark">INTRODUCTION</h4>
                <MDBRow class="mb-2">
                    <MDBCol class="mb-2 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                <!-- <span class="para-img ml-20" style="text-align : right">
                                    <img src="/assets/images/rescue-banner" class="img-round-corner" style="max-height : 375px; width : auto; max-width : 100%;">
                                    <br>
                                    <small><i>- Souvenir Shop at HNP Kufri</i></small>
                                </span> -->
                                Rescue and Rehabilitation Centre Shimla is the only full fledged rescue centre for rescued injured and conflict animals of the state. It is situated about 1 Km from Shimla main bus stand on Bye-pass road and is connected by metalled road. The terrain is hilly and slope is moderate to steep and main tree species of the area is chil (Pinus roxburghii).     
                            </p>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>
                <h4 class="mb-4 text-dark">HISTORY OF MANAGEMENT</h4>
                <MDBRow class="mb-2">
                    <MDBCol class="mb-2 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                <!-- <span class="para-img ml-20" style="text-align : right">
                                    <img src="/assets/images/rescue-banner" class="img-round-corner" style="max-height : 375px; width : auto; max-width : 100%;">
                                    <br>
                                    <small><i>- Souvenir Shop at HNP Kufri</i></small>
                                </span> -->
                                Prior to setting up of rescue and rehabilitation home in 1992 it was head quarter of Wild Life Range with Tutikandi Zoo under its control. It housed many Wild animals for display such as Himalayan Black Bear, Himalayan Brown bear, Palm civet, Flying squirrel, leopard cat, Yak, Ladakhi goat, Himalayan ibex etc and accordingly there were enclosures, night sheds, buildings and other infrastructure.     
                            </p>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>
                <h4 class="mb-4 text-dark">Role of RRC, Shimla in Conservation</h4>
                <MDBRow class="mb-2">
                    <MDBCol class="mb-2 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                <!-- <span class="para-img ml-20" style="text-align : right">
                                    <img src="/assets/images/rescue-banner" class="img-round-corner" style="max-height : 375px; width : auto; max-width : 100%;">
                                    <br>
                                    <small><i>- Souvenir Shop at HNP Kufri</i></small>
                                </span> -->
                                In today’s age, there is large scale fragmentation of wildlife habitat and increased interface between humans and wildlife which leads to threat of injury or conflict among wildlife. The area around Shimla city is home to key species of wildlife which are protected under Wildlife Protection Act, 1972. There is thus an urgent need to cater to rescue, veterinary treatment, rehabilitation and release of such species which are injured, sick, orphaned or in conflict. The RRC, Shimla fits in this context and makes a crucial contribution in the conservation of wildlife.    
                            </p>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>
                <h4 class="mb-4 text-dark">HOUSING OF INJURED ANIMALS</h4>
                <MDBRow class="mb-2">
                    <MDBCol class="mb-2 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                <!-- <span class="para-img ml-20" style="text-align : right">
                                    <img src="/assets/images/rescue-banner" class="img-round-corner" style="max-height : 375px; width : auto; max-width : 100%;">
                                    <br>
                                    <small><i>- Souvenir Shop at HNP Kufri</i></small>
                                </span> -->
                                Presently, there are 11 enclosures in the centre which is used to house injured, orphaned animals of which six enclosure houses leopard & three enclosures have been occupied by Himalayan black bears. All enclosures are well protected with provision of water in each. Bear enclosure have additional water pool as part of enrichment. 
                                <br><br>
                                <table>
                                    <tr>
                                        <th>Species</th>
                                        <th>Number of Animals</th>
                                    </tr>
                                    <tr>
                                        <td>Leopard</td>
                                        <td>17</td>
                                    </tr>
                                    <tr>
                                        <td>Himalayan Black Bear</td>
                                        <td>5</td>
                                    </tr>
                                    <tr>
                                        
                                        <td><b>Total</b></td>
                                        <td><b>22</b></td>
                                        
                                    </tr>
                                </table>   
                                <br><br>
                                The RRC is currently being expanded to provide a facility of housing for herbivores and birds so that it can cater better to injured wildlife.    
                            </p>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>
                <h4 class="mb-4 text-dark">Rapid Rescue Team</h4>
                <MDBRow class="">
                    <MDBCol class="mb-2 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText>
                            <p style="text-align : justify">
                                <!-- <span class="para-img ml-20" style="text-align : right">
                                    <img src="/assets/images/rescue-banner" class="img-round-corner" style="max-height : 375px; width : auto; max-width : 100%;">
                                    <br>
                                    <small><i>- Souvenir Shop at HNP Kufri</i></small>
                                </span> -->
                                The Rapid Rescue Team of Shimla Wildlife Division is centred around the RRC. The Team is led by Range Forest Officer Kufri and comprises field staff including Deputy Ranger and Forest Guards. The Veterinary Officer, Veterinary Pharmacists and Zoo Biologist form the technical core of the RRT. Further, RRT also sees invaluable contribution from Multi Purpose workers of HPZCBS in handling situations of animal injury and conflict. 
                                <br><br>
                                The RRT is well equipped with modern instruments to handle conflict/ injury like tranquilizers, darts, smart sticks for protection, cages for capture and transportation, nets for safe restraint, camera traps for real time monitoring, body protection suits, stretchers, headlights etc. The team has also been given a camouflage uniform to further the professional outlook. 
                                <br>
                            </p>                           
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol md="6" class="mx-auto mb-5">
                        <img src="/assets/images/rrc_training.png" class="img-round-corner" style="width : auto; max-width : 100%;">
                    </MDBCol>
                </MDBRow>
                <h4 class="mb-4 text-dark">Training/ Workshops for Field Staff</h4>
                <MDBRow class="mb-2">
                    <MDBCol class="mb-2 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="item-align-center mx-auto">
                            <p style="text-align : justify">
                                The Rescue center also provides for workshops for field staff of territorial forest range in order to make them self-reliant in cases of animal injury or conflict. Technical personnel from the field of veterinary sciences and animal rescue are invited for such workshops. The usage of tranquilization gun, cages, camera trap monitoring, safe nets are imparted to the attendees of such workshops. Periodical refresher courses of the same are conducted regularly to ensure that skill of conflict management and wildlife rescue remain up to date for the staff attending the workshop.
                            </p>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>
                <MDBRow>    
                    <MDBCol class="mb-4" md="6" xs="6">
                        <MDBCard>
                            <a v-mdb-ripple="{ color: 'dark' }">
                                <MDBCardImg src="/assets/images/rrc_1.jpg" top style="width:auto; max-width:100%" />
                            </a>
                        </MDBCard>
                    </MDBCol> 
                    <MDBCol class="mb-4" md="6" xs="6">
                        <MDBCard>
                            <a v-mdb-ripple="{ color: 'dark' }">
                                <MDBCardImg src="/assets/images/rrc_2.jpg" top style="width:auto; max-width:100%" />
                            </a>
                        </MDBCard>
                    </MDBCol>           
                </MDBRow>
                <MDBRow>    
                    <MDBCol class="mb-4" md="6" xs="6">
                        <MDBCard>
                            <a v-mdb-ripple="{ color: 'dark' }">
                                <MDBCardImg src="/assets/images/rrc_3.jpg" top style="width:auto; max-width:100%" />
                            </a>
                        </MDBCard>
                    </MDBCol> 
                    <MDBCol class="mb-4" md="6" xs="6">
                        <MDBCard>
                            <a v-mdb-ripple="{ color: 'dark' }">
                                <MDBCardImg src="/assets/images/rrc_5.jpg" top style="width:auto; max-width:100%" />
                            </a>
                        </MDBCard>
                    </MDBCol>           
                </MDBRow>
            </MDBContainer>
        </section>        
        
        
    </div>
</template>
<script>
  import { ref } from "vue";
  import { 
    MDBCarousel,
    MDBCol, MDBRow, MDBContainer,
    MDBCardText,
    MDBCard, MDBCardHeader, MDBCardBody, MDBCardTitle, MDBCardImg, mdbRipple
  } from "mdb-vue-ui-kit";
  export default {
    name : "Daranghati",
    components: {
      MDBCarousel,
      MDBCol, MDBRow, MDBContainer,
      MDBCardText,
      MDBCard, MDBCardHeader, MDBCardBody, MDBCardTitle, MDBCardImg
    },
    directives: {
      mdbRipple
    },
    setup() {
      const items1 = [
        {
          src: "/assets/images/rrc_banner.jpg",
          alt: "RESCUE AND REHABILITATION CENTRE, SHIMLA",
        }
      ];
      const carousel1 = ref(0);
      return {
        items1,
        carousel1,
      };
    }
  };
</script>
<style scoped>

    table, th, td {
        border: 1px solid black;
        border-collapse: collapse;
    }

    th, td {
        padding: 4px;
    }

    /* GRADIENTS */
    .winter-neva-gradient {
        background-image: linear-gradient(120deg,#a1c4fd,#c2e9fb);
    }

    .deep-blue-gradient {
        background-image: linear-gradient(120deg,#e0c3fc,#8ec5fc);
    }
    /* GRADIENTS END */

    .gradient-text {
        line-height: 200px; 
        font-size : 24px; 
        letter-spacing : 2px
    }

    .z-depth-1-half {
        -webkit-box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)!important;
        box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)!important;
    }

    .bg-dark-green {
        background-color: rgb(77,169,80);
    }

    .para-img {
        float: right;
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
    }

    .bg-contact {
        background: linear-gradient(rgba(44, 95, 45, 0.5), rgba(44, 95, 45,0.5)), url('/assets/img/contact-bg.svg');
    }

    .float-left {
        float: left !important;
    }

    .mr-20 {
        margin-right : 20px
    }

    .ml-20 {
        margin-left : 20px
    }

    .img-round-corner {
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
    }

    h4 {
        margin: 0;  
    }
    .color-block-5 {
        height: 200px;
        width: 200px;
    }
    .img-gradient{
        position:relative;
        display:inline-block;
    }
    .img-gradient:after {
        content:'';
        position:absolute;
        left:0; top:0;
        width:100%; height:100%;
        display:inline-block;
        background: -moz-linear-gradient(top, rgba(0,47,75,0.5) 0%, rgba(220, 66, 37, 0.5) 100%); /* FF3.6+ */
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(220, 66, 37, 0.5)), color-stop(100%,rgba(0,47,75,0.5))); /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* Opera 11.10+ */
        background: -ms-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* IE10+ */
        background: linear-gradient(to bottom, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002f4b', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
    }
    .img-gradient img{
        display:block;
    }

</style>