<template>
  <div>
    <MDBNavbar expand="lg" light bg="light" container>
      <!-- Toggle button -->
      <MDBNavbarToggler
        target="#navbarRightAlignExample"
        @click="collapse5 = !collapse5"
      ></MDBNavbarToggler>
      <!-- Collapsible wrapper -->
      <MDBNavbarBrand href="/">
        <img
          src="/assets/logo.png"
          height="40"
          alt=""
          loading="lazy"
        />
        SHIMLA WILDLIFE DIVISION
      </MDBNavbarBrand>
      <MDBCollapse v-model="collapse5" id="navbarRightAlignExample">
        <MDBNavbarNav right class="mb-2 mb-lg-0">
          <MDBNavbarItem to="/" exact>
            <i class="fas fa-home"></i> Home
          </MDBNavbarItem>
          <MDBNavbarItem to="/about-division" exact>
            <i class="fas fa-info-circle"></i> About Us
          </MDBNavbarItem>
          <!-- <MDBDropdown class="nav-item" v-model="dropdown1">
            <MDBDropdownToggle
              tag="a"
              class="nav-link"
              @click="dropdown1 = !dropdown1"
              ><i class="fas fa-info-circle"></i> About Us</MDBDropdownToggle
            >
            <MDBDropdownMenu>
              <MDBDropdownItem to="/about-division" exact>Brief overview of division</MDBDropdownItem>
              <MDBDropdownItem to="/whos-who" exact>Who's Who</MDBDropdownItem>              
            </MDBDropdownMenu>
          </MDBDropdown> -->
          <MDBDropdown class="nav-item" v-model="dropdown2">
            <MDBDropdownToggle
              tag="a"
              class="nav-link"
              @click="dropdown2 = !dropdown2"
              ><i class="fas fa-feather"></i> Our Zoos/Pheasantries</MDBDropdownToggle
            >
            <MDBDropdownMenu @click="dropdown2 = !dropdown2">
              <MDBDropdownItem to="/himalyan-nature-park">Himalayan Nature Park</MDBDropdownItem>
              <MDBDropdownItem to="/renuka-ji-zoo">Renuka Ji Mini Zoo</MDBDropdownItem>
              <MDBDropdownItem to="/himalayan-bird-park">Himalayan Bird Park Shimla</MDBDropdownItem>
              <MDBDropdownItem to="/chail-conservation">Chail Conservation Breeding Centre</MDBDropdownItem>
              <MDBDropdownItem to="/rescue-centre">Rescue and Rehabilitation Centre Shimla</MDBDropdownItem>
              <MDBDropdownItem to="/monkey-rescue">Monkey Sterilization Centre</MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
          <MDBDropdown class="nav-item" v-model="dropdown3">
            <MDBDropdownToggle
              tag="a"
              class="nav-link"
              @click="dropdown3 = !dropdown3"
              ><i class="fas fa-feather"></i> Our Protected Areas</MDBDropdownToggle
            >
            <MDBDropdownMenu @click="dropdown3 = !dropdown3">
              <MDBDropdownItem to="/simbalbara-np">Simbalbara National Park</MDBDropdownItem>
              <MDBDropdownItem to="/majathal-wls">Majathal Wildlife Sanctuary</MDBDropdownItem>
              <MDBDropdownItem to="/renuka-ji-wls">Renuka Ji Wildlife Sanctuary</MDBDropdownItem>
              <MDBDropdownItem to="/churdhar-wls">Churdhar Wildlife Sanctuary</MDBDropdownItem>
              <MDBDropdownItem to="/shimla-water-catchment">Shimla Water Catchment Wildlife Sanctuary</MDBDropdownItem>
              <MDBDropdownItem to="/chail-wls">Chail Wildlife Sanctuary</MDBDropdownItem>
              <MDBDropdownItem to="/talra-wls">Talra Wildlife Sanctuary</MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
          <MDBDropdown class="nav-item" v-model="dropdown4">
            <MDBDropdownToggle
              tag="a"
              class="nav-link"
              @click="dropdown4 = !dropdown4"
              ><i class="fas fa-feather"></i> Our Resthouses</MDBDropdownToggle
            >
            <MDBDropdownMenu @click="dropdown4 = !dropdown4">
              <MDBDropdownItem to="/kufri-frh">Kufri FRH</MDBDropdownItem>
              <MDBDropdownItem to="/renuka-ji-frh">Renujka Ji FRH</MDBDropdownItem>
              <MDBDropdownItem to="/chail-frh">Chail FRH</MDBDropdownItem>
              <MDBDropdownItem to="/simbalbara-frh">Simbalbara FRH</MDBDropdownItem>
              <MDBDropdownItem to="/choras-hut">Choras Inspection Hut</MDBDropdownItem>
              <MDBDropdownItem to="/amargarh-hut">Amargarh Inspection Hut</MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
          <MDBDropdown class="nav-item" v-model="dropdown5">
            <MDBDropdownToggle
              tag="a"
              class="nav-link"
              @click="dropdown5 = !dropdown5"
              ><i class="fas fa-bell"></i> Others</MDBDropdownToggle
            >
            <MDBDropdownMenu @click="dropdown5 = !dropdown5">
              <MDBDropdownItem to="/recent-activities">Recent Activities</MDBDropdownItem>
              <MDBDropdownItem to="/publications">Publications</MDBDropdownItem>
              <MDBDropdownItem to="/tenders">Tenders</MDBDropdownItem>
              <MDBDropdownItem to="/gallery">Gallery</MDBDropdownItem>
              <MDBDropdownItem to="/feedback-zoo">Feedback for Zoos</MDBDropdownItem>
              <MDBDropdownItem to="/feedback-resthouse">Feedback for Resthouse</MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
          <MDBNavbarItem to="/contact-us" exact>
            <i class="fas fa-envelope"></i> Contact Us
          </MDBNavbarItem>
          
        </MDBNavbarNav>
      </MDBCollapse>
      <!-- Collapsible wrapper -->
    </MDBNavbar>
  </div>
    
</template>

<script>
// @ is an alias to /src
import { 
    MDBNavbar,
    MDBNavbarToggler,
    MDBNavbarBrand,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBCollapse,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem
  } from 'mdb-vue-ui-kit';

  import { ref } from 'vue';


export default {
  name: "Home",
  components: {
    MDBNavbar,
    MDBNavbarToggler,
    MDBNavbarBrand,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBCollapse,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem
  },
  setup() {
    const collapse5 = ref(false);
    const dropdown1 = ref(false);
    const dropdown2 = ref(false);
    const dropdown3 = ref(false);
    const dropdown4 = ref(false);
    const dropdown5 = ref(false);
    return {
      collapse5,
      dropdown1,
      dropdown2,
      dropdown3,
      dropdown4,
      dropdown5
    }
  },
  methods : {
    getURL(){
      console.log(this.$route.path);
    }
  },
  compute(){
    this.getURL();
  }
};
</script>
<style>
  .navbar-light .navbar-nav .nav-link.active {
    color: rgb(77,169,80);
    background-color: rgb(231, 240, 233);
    border-bottom: 5px solid rgb(77,169,80);
  }

  .active { 
    color: rgb(77,169,80) !important;
    background-color: rgb(231, 240, 233);
    border-bottom: 5px solid rgb(77,169,80);
  }

  .active-font {
    color: rgb(77,169,80) !important;

  }
</style>