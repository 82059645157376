<template>
    <div>
        <MDBCarousel
            v-model="carousel1"
            :items="items1"
            fade
            :controls="false"
            :indicators="false"
        />
        <section >
            <MDBContainer class="p-5">
                <h4 class="mb-4 text-dark">About Talra Wildlife Sanctuary</h4>
                <MDBRow class="mb-3">
                    <MDBCol class="mb-3 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                <span class="para-img ml-20" style="text-align : right">
                                    <img src="/assets/images/talra-intro.jpg" class="img-round-corner" style="max-height : 375px; width : auto; max-width : 100%;">
                                    <br>
                                    <!-- <small><i>- PC : @Rampal, Senior Forest Guard</i></small> -->
                                </span>
                                Located in the upper reaches of Shimla, nestled between forests of Chopal and Rohru lies the Talra Wildlife Sanctuary having parts of Shashan, Chhajpur, Kashtha and Tharoch forests included in it. The sanctuary is much away from human settlements and thus offers a unique safe haven to wildlife far away from all sorts of anthropogenic and biotic pressures.
                                <br><br>
                                <b>History</b>
                                <br>
                                The Talra Wildlife Sanctuary was initially notified in 1999 as a protected area under Wildlife Protection Act, 1972 for its ecological, faunal, floral, geo-morphological, natural and zoological significance. Later in the year 2013, 6.48 sq km area was added to the sanctuary making it one of the largest sanctuaries in the state at 46.48 sq km.
                                <br>                              
                            </p>
                            
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>              
                <h4 class="mb-4 text-dark">Location and Access</h4>
                <MDBRow class="">
                    <MDBCol class="mb-4 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                <span class="para-img float-left mr-20" style="text-align : right">
                                    <img src="/assets/images/talra-map.png" class="img-round-corner" style="max-height : 275px; width : auto; max-width : 100%;">
                                    <br>
                                    <!-- <small><i>- Administrative map of Shimla Water Catchment Wildlife Sanctuary Wildlife Sanctuary</i></small> -->
                                </span>
                                The Sanctuary can be approached from its northern side where it is linked with a motorable road running along its boundary for about 7kms from Chhajpur forest Compartment No 8 to Compartment No 16 at Panju. There is regular bus services upto Chhajpur from Rohru and Shimla. From its eastern side Sanctuary can be approached via Tharoch which is linked to Gurar by a Bridle path of 12 kms up to Tharoch. There are regular bus services from Shimla to Tharoch.    
                            </p>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>  
                <h4 class="mb-4 text-dark">Flora of Talra</h4>
                <MDBRow class="">
                    <MDBCol class="mb-4 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <!-- <MDBContainer fluid>
                                <MDBRow >
                                    <MDBCol md="12" xs="12" class="mx-auto text-center">
                                        <vue-horizontal responsive>
                                            <section v-for="item in flora" :key="item.title">
                                                <MDBCard style="margin-right: 20px; max-width : 350px;">
                                                    <MDBCardBody>
                                                        <img :src="item.img" style="width : 100%"  class="img-cut-edge"/>                                                    
                                                    </MDBCardBody>
                                                </MDBCard>
                                            </section>
                                        </vue-horizontal>
                                    </MDBCol>
                                </MDBRow>
                            </MDBContainer> -->
                            <p>
                                Talra is renowned for Temperate Himalayan Moist Forests and alpine scrub vegetation. The temperate forests have great arboreal diversity with Indian Horse Chestnut, Alder trees, Walnuts, Oak species, Yews being unique. The conifer diversity is also notable with Deodar, Spruce, Fir vegetation in the upper areas. The alpine scrub area has patches of rhododendrons associated with Salix species.     
                            </p>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>
                <h4 class="mb-4 text-dark">Mammals of Talra Wildlife Sanctuary</h4>
                <MDBRow class="">
                    <MDBCol class="mb-4 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                <span class="para-img float-right ml-20" style="text-align : right">
                                    <img src="/assets/images/talra-mammal.png" class="img-round-corner" style="max-height : 275px; width : auto; max-width : 100%;">
                                    <br>
                                    <!-- <small><i>- Administrative map of Shimla Water Catchment Wildlife Sanctuary Wildlife Sanctuary</i></small> -->
                                </span>
                                The sanctuary has good population of the Himalayan Black Bear as it is an undisturbed habitat giving variety of forage options for the large mammal. The Common Leopard is the apex predator species that prey on the Himalayan Goral, Barking deer that constitute the herbivore biomass of sanctuary. Smaller mammals like the Yellow Throated Marten, Himalayan Red Fox, Leopard Cat, Himalayan Palm Civet, Pika are also found in Talra.    
                            </p>
                            <!-- <MDBContainer fluid>
                                <MDBRow >
                                    <MDBCol md="12" xs="12" class="mx-auto text-center">
                                        <vue-horizontal responsive>
                                            <section v-for="item in mammals" :key="item.title">
                                                <MDBCard style="margin-right: 20px; max-width : 350px;">
                                                    <MDBCardBody>
                                                        <img :src="item.img" style="width : 100%"  class="img-cut-edge"/>                                                    
                                                    </MDBCardBody>
                                                </MDBCard>
                                            </section>
                                        </vue-horizontal>
                                    </MDBCol>
                                </MDBRow>
                            </MDBContainer> -->
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>   
                <h4 class="mb-4 text-dark">Avifauna</h4>
                <MDBRow class="">
                    <MDBCol class="mb-4 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                <span class="para-img float-left mr-20" style="text-align : right">
                                    <img src="/assets/images/talra-bird-1.png" class="img-round-corner" style="max-height : 200px; width : auto; max-width : 100%;">
                                    <br>
                                    <small><i>- PC : @Lalit Ditta, Forest Guard</i></small>
                                </span>
                                <span class="para-img float-right ml-20" style="text-align : right">
                                    <img src="/assets/images/talra-bird-2.png" class="img-round-corner" style="max-height : 200px; width : auto; max-width : 100%;">
                                    <br>
                                    <small><i>- PC : @Sumit, Forest Guard</i></small>
                                </span>
                                During trek to the Talra top, one can spot a variety of Parkaeets, thrushes, owls, flycatchers, doves and pheasants. The beautiful Himalayan Monal habitats the sanctuary. The Himalayan Griffon Vulture, Slaty headed parkaeets, Yellow Browed Leafe Warblers, Green backed tit, Long tailed Minivets, Crested Bunting, Brown Wood Owl are among the notable bird species recorded in the sanctuary.

                            </p>
                            <!-- <MDBContainer fluid>
                                <MDBRow >
                                    <MDBCol md="12" xs="12" class="mx-auto text-center">
                                        <vue-horizontal responsive>
                                            <section v-for="item in avifaunas" :key="item.title">
                                                <MDBCard style="margin-right: 20px; max-width : 350px;">
                                                    <MDBCardBody>
                                                        <img :src="item.img" style="width : 100%"  class="img-cut-edge"/>                                                    
                                                    </MDBCardBody>
                                                </MDBCard>
                                            </section>
                                        </vue-horizontal>
                                    </MDBCol>
                                </MDBRow>
                            </MDBContainer> -->
                        </MDBCardText>
                    </MDBCol>
                </MDBRow> 
                <!-- <h4 class="mb-4 text-dark">Snakes of Talra Wildlife Sanctuary</h4>
                <MDBRow class="">
                    <MDBCol class="mb-4 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                
                                The sanctuary is known for diversity of snakes with Himalayan pit viper, Collared black snake, Himalayan Trinket, Checkered Keelback, Common Wolf snake being recorded here. Apart from snakes, several species of lizards, skinks, geckos and frogs are found in the protected area including the Himalayan Agama, Asian Common Toad    
                            </p>
                            <MDBContainer fluid>
                                <MDBRow >
                                    <MDBCol md="12" xs="12" class="mx-auto text-center">
                                        <vue-horizontal responsive>
                                            <section v-for="item in reptiles" :key="item.title">
                                                <MDBCard style="margin-right: 20px; max-width : 350px;">
                                                    <MDBCardBody>
                                                        <img :src="item.img" style="width : 100%"  class="img-cut-edge"/>                                                    
                                                    </MDBCardBody>
                                                </MDBCard>
                                            </section>
                                        </vue-horizontal>
                                    </MDBCol>
                                </MDBRow>
                            </MDBContainer>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>    -->
                <!-- <h4 class="mb-4 text-dark">Eco Tourism Activities in Sanctuary</h4>
                <MDBRow class="">
                    <MDBCol class="mb-4 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                Trekking is the main eco tourism activity in sanctuary where visitors can be educated about nature and wildlife conservation. The operational trekking route in sanctuary is from Khariyoun to Kali Ka Tibba. The additional trekking routes from Ranital and Chorghatti to Snow View and from Chabta to Khariyoun Pheasantry shall soon be operationalized.    
                            </p>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>    -->
            </MDBContainer>
        </section>
        <!-- <section class="bg-contact p-5">
            <MDBContainer>
                <MDBRow>
                    <MDBCol md="8" class="mx-auto mb-5">
                        <MDBCard text="center">
                            <MDBCardHeader class="bg-dark-green text-light"><h4>Vegetation of Shimla Water Catchment Wildlife Sanctuary Wildlife Sanctuary</h4></MDBCardHeader>
                            <MDBCardBody>
                                <MDBCardText style="text-align:justify">
                                    The National Park has great floral diversity with as many as 184 species of flora recorded from it including 49 families of plants. The National Park is dominated by Sal Forests whereas the National park also has rich vegetation of variety of species including Sain, Jamun, Rohini, Chamror, Amaltas, Ber, Bamboo species etc. Apart from trees, the national park has several herbs, shrubs, climbers and grasses that enhance the habitat potential and provide rich forage to biodiversity.

                                </MDBCardText>                                
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol md="8" class="mx-auto mb-5">
                        <MDBCard text="center">
                            <MDBCardHeader class="bg-dark-green text-light"><h4>Mammals of Shimla Water Catchment Wildlife Sanctuary Wildlife Sanctuary</h4></MDBCardHeader>
                            <MDBCardBody>
                                <MDBCardText style="text-align:justify">                                    
                                    The National Park is migratory home to Asiatic Elephant that crosses over the Yamuna from reserved forests of Uttarakhand into the protected Area. There have been several recorded sightings of herds of elephants in the national park making it the only protected area in Himachal Pradesh that has recorded presence of elephants.
                                    <br><br>
                                    The Common Leopard is the apex carnivore in this national park which has diverse herbivore diversity in form of Sambar, Barking Deer, Himalayan Goral, Spotted Deer etc. It is one of the lowest extent for Himalayan Goral habitat. Apart from these species, smaller animals like Jackals, Himalayan Palm Civet, Wild Boar and Yellow throated marten are also found here.
                                </MDBCardText>                                
                            </MDBCardBody>
                            <MDBCardFooter>
                                <MDBContainer fluid>
                                    <MDBRow >
                                        <MDBCol md="12" xs="12" class="mx-auto text-center">
                                            <vue-horizontal responsive>
                                                <section v-for="item in mammals" :key="item.title">
                                                    <MDBCard style="margin-right: 20px; max-width : 350px;">
                                                        <MDBCardBody>
                                                            <img :src="item.img" style="width : 100%"  class="img-cut-edge"/>                                                    
                                                        </MDBCardBody>
                                                    </MDBCard>
                                                </section>
                                            </vue-horizontal>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBContainer>
                            </MDBCardFooter>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol md="8" class="mx-auto mb-5">
                        <MDBCard text="center">
                            <MDBCardHeader class="bg-dark-green text-light"><h4>Avifauna, Reptiles and Amphibians</h4></MDBCardHeader>
                            <MDBCardBody>
                                <MDBCardText style="text-align:justify">                                    
                                    210 species of birds have been recorded from this national park making it an area vastly rich in avian fauna. These include Oriental Pied Hornbill, Grey Hornbills, Red Jungle Fowl, Peacock, Brown Hawk Owl, Crested Kingfisher, Lesser Flameback Woodpecker, Black Hooded Oriole, Grey Night Jar, Black Stork etc. The national park having a mosaic habitat complex of grassland, woodland, riverine habitats offer great avian fauna diversity.
                                    <br><br>
                                    The National park owing to large tracts of riverine habitat is home to a variety of reptilian and amphibian fauna. 
                                </MDBCardText>                                
                            </MDBCardBody>
                            <MDBCardFooter>
                                <MDBContainer fluid>
                                    <MDBRow >
                                        <MDBCol md="12" xs="12" class="mx-auto text-center">
                                            <vue-horizontal responsive>
                                                <section v-for="item in avifaunas" :key="item.title">
                                                    <MDBCard style="margin-right: 20px; max-width : 350px;">
                                                        <MDBCardBody>
                                                            <img :src="item.img" style="width : 100%"  class="img-cut-edge"/>                                                    
                                                        </MDBCardBody>
                                                    </MDBCard>
                                                </section>
                                            </vue-horizontal>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBContainer>
                            </MDBCardFooter>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </section> -->
        <!-- <section class="bg-dark">
            <MDBContainer class="p-5">
                <h4 class="mb-4 text-light text-center">Landscape of Colonel Sher Jung National Park</h4>
                <MDBRow class="">
                    <MDBCol class="mb-4 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p class="text-light">
                                <span class="para-img float-left mr-20" style="text-align : right">
                                    <img src="/assets/images/simbalbara-np-1.png" class="img-round-corner" style="max-height : 375px; width : auto; max-width : 100%; margin-right : 20px">
                                    <br>
                                    <small><i>- PC : @Kunal Angrish</i></small>
                                </span>
                                Located in the picturesque shivalik foothills of Himalayas, the protected area has diverse landscape. It has a natural boundary comprising of the main Shivalik ridge to its north and sub-ridges of the Shivalik hills to the south east and south west. The area bounded by the ridges, forms a shallow bowl-shaped valley which slopes gently from north to south, and comprises the main National Park. It is bifurcated by a number of seasonal streams known locally as <i>khala</i> or <i>rau</i>. Where the ground is nearly level in valleys between the hills the streams are broad and shallow with stony beds formed of rounded stones found all over Shivaliks.                             
                            </p>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>                
            </MDBContainer>
        </section>         -->
        
    </div>
</template>
<script>
  import { ref } from "vue";
  import VueHorizontal from "vue-horizontal";
  import { 
    MDBCarousel,
    MDBCol, MDBRow, MDBContainer,
    MDBCardText, MDBBtn,
    MDBCard, MDBCardHeader, MDBCardBody, MDBCardTitle, MDBCardFooter
  } from "mdb-vue-ui-kit";
  export default {
    name : "Daranghati",
    components: {
      MDBCarousel,
      MDBCol, MDBRow, MDBContainer,
      MDBCardText,VueHorizontal, MDBBtn,
      MDBCard, MDBCardHeader, MDBCardBody, MDBCardTitle, MDBCardFooter
    },
    directives: {
    },
    setup() {
      const items1 = [
        {
          src: "/assets/images/talra-banner.jpg",
          alt: "Talra Wildlife Sanctuary",
          caption : ""
        }
      ];
      const carousel1 = ref(0);
      return {
        items1,
        carousel1,
        mammals: [
          {
            img : '/assets/images/chail-mammals-1.png'
          },
          {
            img : '/assets/images/chail-mammals-2.png'
          },
          {
            img : '/assets/images/chail-mammals-3.png'
          },
          {
            img : '/assets/images/chail-mammals-4.png'
          }
          
        ],
        avifaunas: [
          {
            img : '/assets/images/chail-birds-1.png'
          },
          {
            img : '/assets/images/chail-birds-2.png'
          }
          
        ],
        reptiles: [
          {
            img : '/assets/images/chail-reptiles-1.png'
          },
          {
            img : '/assets/images/chail-reptiles-2.png'
          }
          
        ],
        flora: [
          {
            img : '/assets/images/chail-flora-1.jpg'
          },
          {
            img : '/assets/images/chail-flora-2.jpg'
          },
          {
            img : '/assets/images/chail-flora-3.jpg'
          },          
          {
            img : '/assets/images/chail-flora-4.jpg'
          },
          
        ]
      };
    }
  };
</script>
<style scoped>

    table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
    }

    th, td {
        padding: 4px;
    }

    /* GRADIENTS */
    .winter-neva-gradient {
        background-image: linear-gradient(120deg,#a1c4fd,#c2e9fb);
    }

    .deep-blue-gradient {
        background-image: linear-gradient(120deg,#e0c3fc,#8ec5fc);
    }
    /* GRADIENTS END */

    .gradient-text {
        line-height: 200px; 
        font-size : 24px; 
        letter-spacing : 2px
    }

    .z-depth-1-half {
        -webkit-box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)!important;
        box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)!important;
    }

    .bg-dark-green {
        background-color: rgb(77,169,80);
    }

    .para-img {
        float: right;
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
    }

    .bg-contact {
        background: linear-gradient(rgba(44, 95, 45, 0.5), rgba(44, 95, 45,0.5)), url('/assets/img/contact-bg.svg');
    }

    .float-left {
        float: left !important;
    }

    .mr-20 {
        margin-right : 20px
    }

    .ml-20 {
        margin-left : 20px
    }

    .img-round-corner {
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
    }

    h4 {
        margin: 0;  
    }
    .color-block-5 {
        height: 200px;
        width: 200px;
    }
    .img-gradient{
        position:relative;
        display:inline-block;
    }
    .img-gradient:after {
        content:'';
        position:absolute;
        left:0; top:0;
        width:100%; height:100%;
        display:inline-block;
        background: -moz-linear-gradient(top, rgba(0,47,75,0.5) 0%, rgba(220, 66, 37, 0.5) 100%); /* FF3.6+ */
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(220, 66, 37, 0.5)), color-stop(100%,rgba(0,47,75,0.5))); /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* Opera 11.10+ */
        background: -ms-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* IE10+ */
        background: linear-gradient(to bottom, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002f4b', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
    }
    .img-gradient img{
        display:block;
    }

</style>