<template>
    <div>
        <MDBCarousel
            v-model="carousel1"
            :items="items1"
            fade
            :controls="false"
            :indicators="false"
        />
        <section >
            <MDBContainer class="p-5">
                <h4 class="mb-4 text-dark">History</h4>
                <MDBRow>
                    <MDBCol class="mb-2 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                <span class="para-img ml-20" style="text-align : right">
                                    <img src="/assets/images/breeding-1.png" class="img-round-corner" style="max-height : 375px; width : auto; max-width : 100%;">
                                    <br>
                                </span>
                                The Cheer Pheasant breeding centre at Khadiyun near Chail is located inside the Chail wildlife sanctuary which is also the natural habitat of the Cheer Pheasant (Catreus wallichii). The centre currently has 65 birds being raised in 15 enclosures. Earlier two pheasantries were present in the Chail Wildlife Sanctuary each in Blossom and Khadiyun beat. These Pheasantries were established long back in 1987-88.
                                <br><br>
                                The breeding centre at Khadiyun has been operational since around 1990 but it was previously used for breeding the Red Jungle Fowl and the Kalij Pheasant. The centre was recognised as a conservation breeding centre for Cheer Pheasant by the Central Zoo Authority in 2008. By the year 2010, the centre decided to start dedicated conservation breeding of Cheer Pheasant.   
                            </p>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>
                <h4 class="mb-4 text-dark">Mission</h4>
                <MDBRow>
                    <MDBCol class="mb-2 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                <span class="para-img ml-20" style="text-align : right">
                                    <img src="/assets/images/breeding-2.png" class="img-round-corner" style="max-height : 375px; width : auto; max-width : 100%;">
                                    <br>
                                </span>
                                Cheer Pheasant is a vulnerable species with a declining population globally. The aim of the breeding centre was to successfully bred and house a viable population of Cheer Pheasants in captivity which later can be utilised to support future reintroduction programs in the state. Acc. To IUCN, "The vision of reintroduction program is to re-establish populations of Cheer Pheasant in areas where they previously occurred but have now gone extinct due to various reasons including human disturbances.” 
                            </p>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>
                <h4 class="mb-4 text-dark">Overview of the program</h4>
                <MDBRow>
                    <MDBCol class="mb-2 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                The entire program of Cheer Pheasant Breeding and Reintroduction is followed in multiple steps:
                                <br>
                                <ol>
                                    <li><b>Wild capture-</b> The program and breeding centre was started by capturing the wild Cheer Pheasants which could serve as founders for the next generation. At the beginning, there were a total of 13 wild Cheer Pheasants (Founders). Out of those, we currently house 2 founders at the centre. Now, these founders and their living descendants support the reintroduction plan.</li>
                                    <li><b>Pairing-</b> Based on the stud-book and genetic analysis, the birds are selected for pairing and allowed to bred during the breeding season in separate enclosures.</li>
                                    <li><b>Breeding-</b> The selected pairs are allowed to bred during the natural breeding season i.e., April-June. Intensive management protocol is followed during this period to ensure maximum success of the breeding of the selected lot including the use of CCTV monitoring to remotely monitor activities of the birds</li>
                                    <li><b>Medical screening-</b> A strict veterinary health screening protocol is also followed up with regular sample testing to diagnose any kind of infections. Morbid samples of the birds are also collected by veterinarians and sent to IVRI to check any potential diseases.</li>
                                    <li><b>Transport-</b> Selected birds for the release are transported to the reintroduction site in light, well-padded & well-ventilated wooden boxes. The boxes are marked and labelled before the onset of journey and transported in vehicles through shortest and smooth routes. Also, the birds are monitored throughout the transportation.</li>
                                    <li><b>Soft Release-</b> The birds are shifted to the soft release site in which the species to be released is first confined to a temporary enclosure situated at the release site. The aim of soft release is to acclimatize the birds in the new area and get familiar with the predators. Birds are also ringed with colour-coded rings for each family and radio-tagged for future monitoring. Pre-release training and supplementary food is also provided in the soft release facility</li>
                                    <li><b>Release in the wild-</b> After keeping the birds in the soft release facility for about a month; they are released into the wild</li>
                                    <li><b>Post-release monitoring-</b> Released birds are monitored via radio tracking, sign survey and camera trapping to estimate their survival, movement, breeding etc.</li>
                                </ol>                             
                            </p>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>
                <h4 class="mb-4 text-dark">Achievements of the program</h4>
                <MDBRow>
                    <MDBCol class=" item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                               <ul>
                                    <li>The centre has managed to bred more than 70 Cheer Pheasants so far</li>
                                    <li>9 out of 18 individuals were tagged during the 2019 release program and out of that 22.22% of birds survived till the last signal received</li>
                                    <li>8 out of 11 birds were tagged during the 2020 release program and out of that 25% of birds survived up to the last signal</li>
                                    <li>Pairing of a released male Cheer Pheasant with a wild female has also been observed</li>
                                    <li>Following the reintroduction of cheer pheasants at Seri, the population of cheer pheasant has come up well in the area</li>
                               </ul>
                            </p>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </section>        
        <!-- <section class="bg-contact p-5">
            <MDBContainer>
                
                <MDBRow>
                    <MDBCol md="6" class="mx-auto mb-5">
                        <MDBCard text="center">
                            <MDBCardHeader class="bg-dark-green text-light"><h3>ACCOMODATION</h3></MDBCardHeader>
                            <MDBCardBody>
                                <MDBCardText>
                                    Forest Rest House at Kufri
                                </MDBCardText>                                
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol md="6" class="mx-auto">
                        <MDBCard text="center">
                            <MDBCardHeader class="bg-dark-green text-light"><h3>CONTACT OFFICE</h3></MDBCardHeader>
                            <MDBCardBody>
                                <MDBCardText>
                                    Range Forest Officer , 
                                    <br>
                                    ----
                                    <br>
                                    Telephone: xxxx xxxx.
                                </MDBCardText>                                
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </section> -->
        
    </div>
</template>
<script>
  import { ref } from "vue";
  import VueHorizontal from "vue-horizontal";
  import { 
    MDBCarousel,
    MDBCol, MDBRow, MDBContainer,
    MDBCardText,
    MDBCard, MDBCardHeader, MDBCardBody, MDBCardTitle, MDBCardImg, mdbRipple
  } from "mdb-vue-ui-kit";
  export default {
    name : "Daranghati",
    components: {
      MDBCarousel,
      MDBCol, MDBRow, MDBContainer,
      MDBCardText, VueHorizontal,
      MDBCard, MDBCardHeader, MDBCardBody, MDBCardTitle, MDBCardImg
    },
    directives: {
      mdbRipple
    },
    setup() {
      const items1 = [
        {
          src: "/assets/images/breeding-banner.jpg",
          alt: "Chail Conservation Breeding Centre",
          caption : ""
        }
      ];
      const carousel1 = ref(0);
      return {
        items1,
        carousel1,
        photos: [
          {
            img : '/assets/images/bird_park_1.jpg'
          },
          {
            img : '/assets/images/bird_park_2.jpg'
          },
          {
            img : '/assets/images/bird_park_3.jpg'
          }
        ]
      };
    }
  };
</script>
<style scoped>

    /* GRADIENTS */
    .winter-neva-gradient {
        background-image: linear-gradient(120deg,#a1c4fd,#c2e9fb);
    }

    .deep-blue-gradient {
        background-image: linear-gradient(120deg,#e0c3fc,#8ec5fc);
    }
    /* GRADIENTS END */

    .gradient-text {
        line-height: 200px; 
        font-size : 24px; 
        letter-spacing : 2px
    }

    .z-depth-1-half {
        -webkit-box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)!important;
        box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)!important;
    }

    .bg-dark-green {
        background-color: rgb(77,169,80);
    }

    .para-img {
        float: right;
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
    }

    .bg-contact {
        background: linear-gradient(rgba(44, 95, 45, 0.5), rgba(44, 95, 45,0.5)), url('/assets/img/contact-bg.svg');
    }

    .float-left {
        float: left !important;
    }

    .mr-20 {
        margin-right : 20px
    }

    .ml-20 {
        margin-left : 20px
    }

    .img-round-corner {
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
    }

    h4 {
        margin: 0;  
    }
    .color-block-5 {
        height: 200px;
        width: 200px;
    }
    .img-gradient{
        position:relative;
        display:inline-block;
    }
    .img-gradient:after {
        content:'';
        position:absolute;
        left:0; top:0;
        width:100%; height:100%;
        display:inline-block;
        background: -moz-linear-gradient(top, rgba(0,47,75,0.5) 0%, rgba(220, 66, 37, 0.5) 100%); /* FF3.6+ */
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(220, 66, 37, 0.5)), color-stop(100%,rgba(0,47,75,0.5))); /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* Opera 11.10+ */
        background: -ms-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* IE10+ */
        background: linear-gradient(to bottom, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002f4b', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
    }
    .img-gradient img{
        display:block;
    }

</style>