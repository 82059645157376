<template>
    <MDBFooter :text="['center', 'lg-start', 'muted']" style="background-color: #142b17" bg="none">
        <!-- Section: Social media -->
        <section class="
            d-flex
            justify-content-center justify-content-lg-between
            p-4
            border-bottom
            text-light
        ">
            <!-- Left -->
            <div class="me-5 d-none d-lg-block">
                <span>Get connected with us on social networks:</span>
            </div>
            <!-- Left -->
            <!-- Right -->
            <div>
                <a href="" class="me-4 text-reset">
                <MDBIcon iconStyle="fab" icon="facebook-f"></MDBIcon>
                </a>
                <a href="" class="me-4 text-reset">
                <MDBIcon iconStyle="fab" icon="twitter"></MDBIcon>
                </a>
                <a href="" class="me-4 text-reset">
                <MDBIcon iconStyle="fab" icon="instagram"></MDBIcon>
                </a>
            </div>
            <!-- Right -->
        </section>
        <!-- Section: Social media -->
        <!-- Section: Links  -->
        <section class="text-light">
            <MDBContainer class="text-center text-md-start mt-5">
                <!-- Grid row -->
                <MDBRow class="mt-3">
                <!-- Grid column -->
                <MDBCol md="3" lg="4" xl="3" class="mx-auto mb-4">
                    <!-- Content -->
                    <h6 class="text-uppercase  mb-4">
                        SHIMLA WILDLIFE DIVISION
                    </h6>
                    <p>
                        Himachal Pradesh Forest Department
                    </p>
                </MDBCol>
                <!-- Grid column -->
                <!-- Grid column -->
                <MDBCol md="3" lg="2" xl="2" class="mx-auto mb-4">
                    <!-- Links -->
                    <h6 class="text-uppercase  mb-4">
                        Useful links
                    </h6>
                    <p>
                        <a href="/feedback-zoo" class="text-reset">Feedback for Zoos</a>
                    </p>
                    <p>
                        <a href="/feedback-resthouse" class="text-reset">Feedback for Resthouse</a>
                    </p>
                    <p>
                        <a href="/contact-us" class="text-reset">Contact Us</a>
                    </p>
                    <p>
                        <a href="/gallery" class="text-reset">Gallery</a>
                    </p>
                </MDBCol>
                <!-- Grid column -->
                <!-- Grid column -->
                <MDBCol md="4" lg="3" xl="3" class="mx-auto mb-md-0 mb-4">
                    <!-- Links -->
                    <h6 class="text-uppercase  mb-4">Contact</h6>
                    <p>
                    <i class="fas fa-home me-3"></i> Dy. Conservator Of Forests, Wildlife Division Shimla, Mist Chamber Khalini Shimla Pin, No. - 171002
                    </p>
                    <p>
                    <i class="fas fa-envelope me-3"></i>
                    dfowlshi-hp@nic.in
                    </p>
                    <p>
                    <MDBIcon icon="phone" class="me-3" /> +91 177 262 3993
                    </p>
                </MDBCol>
                <!-- Grid column -->
                </MDBRow>
                <!-- Grid row -->
            </MDBContainer>
        </section>
        <!-- Section: Links  -->
        <!-- Copyright -->
        <div class="container text-center text-md-start mt-5">
            <div class="row mt-3">
                <div class="col-md-6 col-lg-6 col-xl-6 mx-auto mb-4">
                    © 2022 Copyright | HP Forest Deparment
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 mx-auto mb-4 text-muted" style="text-align : right">
                    Developed by :
                    <small><a class="text-reset " href="https://strofes.com/">Strofes Technologies Pvt. Ltd.</a></small>
                </div>
            </div>
        </div>
        <!-- Copyright -->
    </MDBFooter>
</template>
<script>
  import {
    MDBFooter,
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBIcon
  } from 'mdb-vue-ui-kit';
  export default {
    components: {
      MDBFooter,
      MDBContainer,
      MDBRow,
      MDBCol,
      MDBIcon
    },
  };
</script>
<style scoped>
    .bg-dark-green {
        background: rgb(20,43,23);
    }
</style>