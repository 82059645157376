<template>
    <div>
        <MDBCarousel
            v-model="carousel1"
            :items="items1"
            fade
            :controls="false"
            :indicators="false"
        />
        <section >
            <MDBContainer class="p-5">
                <h4 class="mb-4 text-dark">About Majathal Wildlife Sanctuary</h4>
                <MDBRow class="mb-3">
                    <MDBCol class="mb-3 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                <span class="para-img ml-20" style="text-align : right">
                                    <img src="/assets/images/majathal-map.png" class="img-round-corner" style="max-height : 375px; width : auto; max-width : 100%;">
                                    <br>
                                    <small><i>- Administrative map of Majathal Wildlife Sanctuary</i></small>
                                </span>
                                Spread over an area of 37.71 sq km, the Majathal Wildlife Sanctuary is located in Solan and Shimla district of Himachal Pradesh. The sanctuary has a unique landscape bound by Sutlej river on one side, enamored by beautiful grassland patches and majestic peaks all across it.  It is home to several flagship species of conservation namely the Himalayan Black Bear, Himalayan Goral, Himalayan Griffon and the Cheer Pheasant.
                                <br><br><br>
                                <b>History of Sanctuary</b>
                                <br>
                                The Sanctuary was established in the year 1962, later re-notified as a wildlife sanctuary in 1974. The area was transferred to wildlife wing during the year 1987-88, and a separate wildlife range was created. During June 2013, eleven villages comprising an area of 8.53 sq km were excluded from the sanctuary for the sake of undisturbed propagation of wildlife and its environment thus reorganizing the sanctuary into a protected area of 37.71 sq km.    
                            </p>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>
                <h4 class="mb-4 text-dark">Location and Access</h4>
                <MDBRow class="">
                    <MDBCol class="mb-4 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                <span class="para-img float-left mr-20" style="text-align : right">
                                    <img src="/assets/images/majathal-intro.png" class="img-round-corner" style="max-height : 375px; width : auto; max-width : 100%; margin-right : 20px">
                                    <br>
                                    <!-- <small><i>- PC : @Kunal Angrish</i></small> -->
                                </span>
                                The sanctuary is accessible from its headquarter Chandi, a small village on western boundary of sanctuary in Solan district. Though the HQ is well connected by road and is about 12 Kms from Kararaghat (a small place nearly 40 Km from Shimla on Shimla-Bilaspur State Highway) there exists a network of trekking paths to access the sanctuary. The distance of sanctuary from various prominent cities are as given below:
                                <br><br>
                                <table>
                                    <tr>
                                        <th>Town/City</th>
                                        <th>Distance from Majathal Wildlife Sanctuary(km)</th>
                                    </tr>
                                    <tr>
                                        <td>Shimla</td>
                                        <td>50 km</td>
                                    </tr>
                                    <tr>
                                        <td>Chandigarh</td>
                                        <td>100 km</td>
                                    </tr>
                                    <tr>
                                        <td>Delhi</td>
                                        <td>400 km</td>
                                    </tr>
                                    <tr>
                                        <td>Kalka</td>
                                        <td>90 km</td>
                                    </tr>
                                    <tr>
                                        <td>Bilaspur</td>
                                        <td>55 km</td>
                                    </tr>
                                    <tr>
                                        <td>Darlaghat</td>
                                        <td>18 km</td>
                                    </tr>
                                </table>                                
                            </p>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>
                <h4 class="mb-4 text-dark">Floristic Diversity of Majathal Sanctuary</h4>
                <MDBRow class="">
                    <MDBCol class="mb-4 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                <span class="para-img float-right ml-20" style="text-align : right;">
                                    <img src="/assets/images/majathal-2.png" class="img-round-corner" style="max-height : 200px; width : auto; max-width : 100%; margin-right : 20px">
                                    <br>
                                    <!-- <small><i>- PC : @Kunal Angrish</i></small> -->
                                </span>
                                The sanctuary has diverse flora with altitude varying from 575m to 1975m. The lower elevations have presence of several broad leaved tree species namely Kainth, Kachnar, Daru, Shisham, Jamun, Amaltas whereas the middle elevations are dominated by Chir Pine and at some places by Ban Oak. The state tree Deodar is found in highest of elevations of sanctuary at Harsang Dhar and Majathal. The grass land complex along with scattered trees especially Chir Pine is characteristic habitat of sanctuary that is crucial to several herbivores and pheasants.
                                <br><br>                        
        
                            </p>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>
                <h4 class="mb-4 text-dark">Mammalian Diversity</h4>
                <MDBRow class="">
                    <MDBCol class="mb-4 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                The sanctuary is home to wide variety of mammals especially due to good amount of grassland patches, broad leaf and oak vegetation. The Himalayan Black bear enjoys the fruits and acorns of various species including Ban oak, Kasmale, Chikal etc. The Common Leopard is the apex predator species and thrives on diverse herbivore biomass including Sambar, Himalayan Goral, Barking Deer. Apart from these, several small mammals including the Red Giant Flying Squirrel, Northern Palm Squirrel, Grey Mongoose, Yellow Throated Marten etc. are also found here.
                                <br><br>
                                
                            </p>
                            <MDBContainer fluid>
                                <MDBRow >
                                    <MDBCol md="12" xs="12" class="mx-auto text-center">
                                        <vue-horizontal responsive>
                                            <section v-for="item in mammals" :key="item.title">
                                                <MDBCard style="margin-right: 20px; max-width : 350px;">
                                                    <MDBCardBody>
                                                        <img :src="item.img" style="width : 100%"  class="img-cut-edge"/>                                                    
                                                    </MDBCardBody>
                                                </MDBCard>
                                            </section>
                                        </vue-horizontal>
                                    </MDBCol>
                                </MDBRow>
                            </MDBContainer>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>      
                <h4 class="mb-4 text-dark">Birds of Majathal</h4>
                <MDBRow class="">
                    <MDBCol class="mb-4 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                The sanctuary owing to its diverse habitat that is mix of woodland, grass land, scrub lands, riverine vegetation is home to more than 100 bird species. The notable species among the birds are Cheer Pheasant, Koklass pheasant, Himalayan Griffon vulture, Egyptian Vulture, Black Francolin, Chukor, Eurasian Wigeon, Northern Shoveler. Recently, the sanctuary made history being the only place in Himachal to have recorded the Grey Crowned Prinia which is an exceptionally rare bird that is facing population decline. The sanctuary has variety of flycatchers, pigeons, pheasants, kingfishers, Bushchats, Tits, Barbet, Minivets, Eagles, Kites and Vultures making it an important bird area.
                                <br><br>
                                
                            </p>
                            <MDBContainer fluid>
                                <MDBRow >
                                    <MDBCol md="12" xs="12" class="mx-auto text-center">
                                        <vue-horizontal responsive>
                                            <section v-for="item in avifaunas" :key="item.title">
                                                <MDBCard style="margin-right: 20px; max-width : 350px;">
                                                    <MDBCardBody>
                                                        <img :src="item.img" style="width : 100%"  class="img-cut-edge"/>                                                    
                                                    </MDBCardBody>
                                                </MDBCard>
                                            </section>
                                        </vue-horizontal>
                                    </MDBCol>
                                </MDBRow>
                            </MDBContainer>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>            
                <h4 class="mb-4 text-dark">Reptiles and Amphibians of Majathal Wildlife Sanctuary</h4>
                <MDBRow class="">
                    <MDBCol class="mb-4 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                The sanctuary within its limits has several water bodies and riverine habitat making it a mosaic habitat suitable for reptilian and amphibian fauna. This includes the Indian Skittering frog, Himalayan Ground Skink, Garden Lizard, Bengal Monitor, Burrowing Frog apart from snakes that home the sanctuary.
                                <br><br>
                                
                            </p>
                            <MDBContainer fluid>
                                <MDBRow >
                                    <MDBCol md="12" xs="12" class="mx-auto text-center">
                                        <vue-horizontal responsive>
                                            <section v-for="item in reptiles" :key="item.title">
                                                <MDBCard style="margin-right: 20px; max-width : 350px;">
                                                    <MDBCardBody>
                                                        <img :src="item.img" style="width : 100%"  class="img-cut-edge"/>                                                    
                                                    </MDBCardBody>
                                                </MDBCard>
                                            </section>
                                        </vue-horizontal>
                                    </MDBCol>
                                </MDBRow>
                            </MDBContainer>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>     
                <h4 class="mb-4 text-dark">Religious Places within Sanctuary</h4>
                <MDBRow class="">
                    <MDBCol class="mb-4 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                The peaks in Majathal, referred to as ‘Dhar’s are holy sites and two famous temples exist within the sanctuary. The Harsang Bhajji temple atop Harsang Dhar, Badu Bada Temple in Kangri and Sidh Baba Temple at Katpol are all situated at vantage points accessible from road and offer scenic views of the magical majathal landscape.
                                <br><br>
                                
                            </p>
                            <MDBContainer fluid>
                                <MDBRow >
                                    <MDBCol md="12" xs="12" class="mx-auto text-center">
                                        <vue-horizontal responsive>
                                            <section v-for="item in religious" :key="item.title">
                                                <MDBCard style="margin-right: 20px; max-width : 350px;">
                                                    <MDBCardBody>
                                                        <img :src="item.img" style="width : 100%"  class="img-cut-edge"/>                                                    
                                                    </MDBCardBody>
                                                </MDBCard>
                                            </section>
                                        </vue-horizontal>
                                    </MDBCol>
                                </MDBRow>
                            </MDBContainer>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>         
            </MDBContainer>
        </section>
        <!-- <section class="bg-contact p-5">
            <MDBContainer>
                <MDBRow>
                    <MDBCol md="8" class="mx-auto mb-5">
                        <MDBCard text="center">
                            <MDBCardHeader class="bg-dark-green text-light"><h4>Vegetation of Simbalbara National Park</h4></MDBCardHeader>
                            <MDBCardBody>
                                <MDBCardText style="text-align:justify">
                                    The National Park has great floral diversity with as many as 184 species of flora recorded from it including 49 families of plants. The National Park is dominated by Sal Forests whereas the National park also has rich vegetation of variety of species including Sain, Jamun, Rohini, Chamror, Amaltas, Ber, Bamboo species etc. Apart from trees, the national park has several herbs, shrubs, climbers and grasses that enhance the habitat potential and provide rich forage to biodiversity.

                                </MDBCardText>                                
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol md="8" class="mx-auto mb-5">
                        <MDBCard text="center">
                            <MDBCardHeader class="bg-dark-green text-light"><h4>Mammals of Simbalbara National Park</h4></MDBCardHeader>
                            <MDBCardBody>
                                <MDBCardText style="text-align:justify">                                    
                                    The National Park is migratory home to Asiatic Elephant that crosses over the Yamuna from reserved forests of Uttarakhand into the protected Area. There have been several recorded sightings of herds of elephants in the national park making it the only protected area in Himachal Pradesh that has recorded presence of elephants.
                                    <br><br>
                                    The Common Leopard is the apex carnivore in this national park which has diverse herbivore diversity in form of Sambar, Barking Deer, Himalayan Goral, Spotted Deer etc. It is one of the lowest extent for Himalayan Goral habitat. Apart from these species, smaller animals like Jackals, Himalayan Palm Civet, Wild Boar and Yellow throated marten are also found here.
                                </MDBCardText>                                
                            </MDBCardBody>
                            <MDBCardFooter>
                                <MDBContainer fluid>
                                    <MDBRow >
                                        <MDBCol md="12" xs="12" class="mx-auto text-center">
                                            <vue-horizontal responsive>
                                                <section v-for="item in mammals" :key="item.title">
                                                    <MDBCard style="margin-right: 20px; max-width : 350px;">
                                                        <MDBCardBody>
                                                            <img :src="item.img" style="width : 100%"  class="img-cut-edge"/>                                                    
                                                        </MDBCardBody>
                                                    </MDBCard>
                                                </section>
                                            </vue-horizontal>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBContainer>
                            </MDBCardFooter>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol md="8" class="mx-auto mb-5">
                        <MDBCard text="center">
                            <MDBCardHeader class="bg-dark-green text-light"><h4>Avifauna, Reptiles and Amphibians</h4></MDBCardHeader>
                            <MDBCardBody>
                                <MDBCardText style="text-align:justify">                                    
                                    210 species of birds have been recorded from this national park making it an area vastly rich in avian fauna. These include Oriental Pied Hornbill, Grey Hornbills, Red Jungle Fowl, Peacock, Brown Hawk Owl, Crested Kingfisher, Lesser Flameback Woodpecker, Black Hooded Oriole, Grey Night Jar, Black Stork etc. The national park having a mosaic habitat complex of grassland, woodland, riverine habitats offer great avian fauna diversity.
                                    <br><br>
                                    The National park owing to large tracts of riverine habitat is home to a variety of reptilian and amphibian fauna. 
                                </MDBCardText>                                
                            </MDBCardBody>
                            <MDBCardFooter>
                                <MDBContainer fluid>
                                    <MDBRow >
                                        <MDBCol md="12" xs="12" class="mx-auto text-center">
                                            <vue-horizontal responsive>
                                                <section v-for="item in avifaunas" :key="item.title">
                                                    <MDBCard style="margin-right: 20px; max-width : 350px;">
                                                        <MDBCardBody>
                                                            <img :src="item.img" style="width : 100%"  class="img-cut-edge"/>                                                    
                                                        </MDBCardBody>
                                                    </MDBCard>
                                                </section>
                                            </vue-horizontal>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBContainer>
                            </MDBCardFooter>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </section> -->
        <!-- <section class="bg-dark">
            <MDBContainer class="p-5">
                <h4 class="mb-4 text-light text-center">Landscape of Colonel Sher Jung National Park</h4>
                <MDBRow class="">
                    <MDBCol class="mb-4 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p class="text-light">
                                <span class="para-img float-left mr-20" style="text-align : right">
                                    <img src="/assets/images/simbalbara-np-1.png" class="img-round-corner" style="max-height : 375px; width : auto; max-width : 100%; margin-right : 20px">
                                    <br>
                                    <small><i>- PC : @Kunal Angrish</i></small>
                                </span>
                                Located in the picturesque shivalik foothills of Himalayas, the protected area has diverse landscape. It has a natural boundary comprising of the main Shivalik ridge to its north and sub-ridges of the Shivalik hills to the south east and south west. The area bounded by the ridges, forms a shallow bowl-shaped valley which slopes gently from north to south, and comprises the main National Park. It is bifurcated by a number of seasonal streams known locally as <i>khala</i> or <i>rau</i>. Where the ground is nearly level in valleys between the hills the streams are broad and shallow with stony beds formed of rounded stones found all over Shivaliks.                             
                            </p>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>                
            </MDBContainer>
        </section>         -->
        
    </div>
</template>
<script>
  import { ref } from "vue";
  import VueHorizontal from "vue-horizontal";
  import { 
    MDBCarousel,
    MDBCol, MDBRow, MDBContainer,
    MDBCardText, MDBBtn,
    MDBCard, MDBCardHeader, MDBCardBody, MDBCardTitle, MDBCardFooter
  } from "mdb-vue-ui-kit";
  export default {
    name : "Daranghati",
    components: {
      MDBCarousel,
      MDBCol, MDBRow, MDBContainer,
      MDBCardText,VueHorizontal, MDBBtn,
      MDBCard, MDBCardHeader, MDBCardBody, MDBCardTitle, MDBCardFooter
    },
    directives: {
    },
    setup() {
      const items1 = [
        {
          src: "/assets/images/majathal-cover.jpg",
          alt: "Majathal Wildlife Sanctuary",
          caption : ""
        }
      ];
      const carousel1 = ref(0);
      return {
        items1,
        carousel1,
        mammals: [
          {
            img : '/assets/images/majathal-mammals-1.png'
          },
          {
            img : '/assets/images/majathal-mammals-2.png'
          },
          {
            img : '/assets/images/majathal-mammals-3.png'
          },
        ],
        avifaunas: [
          {
            img : '/assets/images/majathal-birds-1.png'
          },
          {
            img : '/assets/images/majathal-birds-2.png'
          },
          {
            img : '/assets/images/majathal-birds-3.png'
          },
        ],
        reptiles: [
          {
            img : '/assets/images/majathal-reptiles-1.png'
          },
          {
            img : '/assets/images/majathal-reptiles-2.png'
          }
        ],
        religious: [
          {
            img : '/assets/images/majathal-religious-1.png'
          },
          {
            img : '/assets/images/majathal-religious-2.png'
          }
        ]
      };
    }
  };
</script>
<style scoped>

    table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
    }

    th, td {
        padding: 4px;
    }

    /* GRADIENTS */
    .winter-neva-gradient {
        background-image: linear-gradient(120deg,#a1c4fd,#c2e9fb);
    }

    .deep-blue-gradient {
        background-image: linear-gradient(120deg,#e0c3fc,#8ec5fc);
    }
    /* GRADIENTS END */

    .gradient-text {
        line-height: 200px; 
        font-size : 24px; 
        letter-spacing : 2px
    }

    .z-depth-1-half {
        -webkit-box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)!important;
        box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)!important;
    }

    .bg-dark-green {
        background-color: rgb(77,169,80);
    }

    .para-img {
        float: right;
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
    }

    .bg-contact {
        background: linear-gradient(rgba(44, 95, 45, 0.5), rgba(44, 95, 45,0.5)), url('/assets/img/contact-bg.svg');
    }

    .float-left {
        float: left !important;
    }

    .mr-20 {
        margin-right : 20px
    }

    .ml-20 {
        margin-left : 20px
    }

    .img-round-corner {
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
    }

    h4 {
        margin: 0;  
    }
    .color-block-5 {
        height: 200px;
        width: 200px;
    }
    .img-gradient{
        position:relative;
        display:inline-block;
    }
    .img-gradient:after {
        content:'';
        position:absolute;
        left:0; top:0;
        width:100%; height:100%;
        display:inline-block;
        background: -moz-linear-gradient(top, rgba(0,47,75,0.5) 0%, rgba(220, 66, 37, 0.5) 100%); /* FF3.6+ */
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(220, 66, 37, 0.5)), color-stop(100%,rgba(0,47,75,0.5))); /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* Opera 11.10+ */
        background: -ms-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* IE10+ */
        background: linear-gradient(to bottom, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002f4b', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
    }
    .img-gradient img{
        display:block;
    }

</style>