<template>
    <div>
        <MDBCarousel
            v-model="carousel1"
            :items="items1"
            fade
            :controls="false"
            :indicators="false"
        />
        <section >
            <MDBContainer class="p-5">
                <h4 class="mb-4 text-dark">About Simbalbara National Park</h4>
                <MDBRow class="mb-3">
                    <MDBCol class="mb-3 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                <span class="para-img ml-20" style="text-align : right">
                                    <img src="/assets/images/simbalbara-np-map.png" class="img-round-corner" style="max-height : 375px; width : auto; max-width : 100%;">
                                    <br>
                                    <small><i>- Administrative map of Simbalbara National Park</i></small>
                                </span>
                                Colonel Sher Jung National Park is located in Paonta Valley of Sirmour District of state of Himachal Pradesh. It is unique for being the only protected area in the state that harbors the Asiatic Elephant a heritage animal of the country. It is named after renowned conservationist and freedom fighter Colonel Sher Jung.
                                <br><br><br>
                                <b>History of National Park</b>
                                <br>
                                The National Park was maintained as a hunting ground for the Maharaja of Sirmour in the erstwhile Sirmour State and was therefore well protected with restricted felling of Sal and Sain. Post Independence, it was declared as a Wildlife Reserve in the year 1958 and later on notified as a Wildlife Sanctuary spread across 19.03 sq km area in year 1984. The National Park was finally notified in the year 2014 by the state government adding new areas and currently the area of national park extends to 27.88 sq km.                           
                            </p>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>
                <h4 class="mb-4 text-dark">Location and Access</h4>
                <MDBRow class="">
                    <MDBCol class="mb-4 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                <span class="para-img float-left mr-20" style="text-align : right">
                                    <img src="/assets/images/simbalbara-gate.jpg" class="img-round-corner" style="max-height : 375px; width : auto; max-width : 100%; margin-right : 20px">
                                    <br>
                                    <!-- <small><i>- PC : @Kunal Angrish</i></small> -->
                                </span>
                                The National Park is accessible from the Nahan-Paonta Highway at a distance of 1km from Puruwala village in Paonta District. The protected area is headquartered at Amargarh. The following table details distance of national park from notable cities.
                                <br><br>
                                <table>
                                    <thead>
                                        <th>Town/City</th>
                                        <th>Distance from Simbalbara</th>
                                    </thead>
                                    <tr>
                                        <td>Poanta Sahib</td>
                                        <td>22 km</td>
                                    </tr>
                                    <tr>
                                        <td>Nahan</td>
                                        <td>50 km</td>
                                    </tr>
                                    <tr>
                                        <td>Dehradun</td>
                                        <td>70 km</td>
                                    </tr>
                                    <tr>
                                        <td>Yamuna Nagar</td>
                                        <td>75 km</td>
                                    </tr>
                                    <tr>
                                        <td>Chandigarh</td>
                                        <td>120 km</td>
                                    </tr>
                                    <tr>
                                        <td>Delhi</td>
                                        <td>300 km</td>
                                    </tr>
                                </table>                                
                            </p>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>
                <h4 class="mb-4 text-dark">Vegetation of Simbalbara National Park</h4>
                <MDBRow class="">
                    <MDBCol class="mb-4 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                <!-- <span class="para-img float-left mr-20" style="text-align : right">
                                    <img src="/assets/images/simbalbara-np-birds-1.jpg" class="img-round-corner" style="max-height : 375px; width : auto; max-width : 100%; margin-right : 20px">
                                    <br>
                                    <small><i>- PC : @Kunal Angrish</i></small>
                                </span> -->
                                The National Park has great floral diversity with as many as 184 species of flora recorded from it including 49 families of plants. The National Park is dominated by Sal Forests whereas the National park also has rich vegetation of variety of species including Sain, Jamun, Rohini, Chamror, Amaltas, Ber, Bamboo species etc. Apart from trees, the national park has several herbs, shrubs, climbers and grasses that enhance the habitat potential and provide rich forage to biodiversity.                              
                            </p>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>
                <h4 class="mb-4 text-dark">Mammals of Simbalbara National Park</h4>
                <MDBRow class="">
                    <MDBCol class="mb-4 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                <span class="para-img float-left mr-20" style="text-align : right">
                                    <img src="/assets/images/simbalbara-np-mammals-7.png" class="img-round-corner" style="max-height : 375px; width : auto; max-width : 100%; margin-right : 20px">
                                    <br>
                                    <!-- <small><i>- PC : @Kunal Angrish</i></small> -->
                                </span>
                                The National Park is migratory home to Asiatic Elephant that crosses over the Yamuna from reserved forests of Uttarakhand into the protected Area. There have been several recorded sightings of herds of elephants in the national park making it the only protected area in Himachal Pradesh that has recorded presence of elephants.
                                <br><br>
                                The Common Leopard is the apex carnivore in this national park which has diverse herbivore diversity in form of Sambar, Barking Deer, Himalayan Goral, Spotted Deer etc. It is one of the lowest extent for Himalayan Goral habitat. Apart from these species, smaller animals like Jackals, Himalayan Palm Civet, Wild Boar and Yellow throated marten are also found here.                               
                            </p>
                            <MDBContainer fluid>
                                <MDBRow >
                                    <MDBCol md="12" xs="12" class="mx-auto text-center">
                                        <vue-horizontal responsive>
                                            <section v-for="item in mammals" :key="item.title">
                                                <MDBCard style="margin-right: 20px; max-width : 350px;">
                                                    <MDBCardBody>
                                                        <img :src="item.img" style="width : 100%"  class="img-cut-edge"/>                                                    
                                                    </MDBCardBody>
                                                </MDBCard>
                                            </section>
                                        </vue-horizontal>
                                    </MDBCol>
                                </MDBRow>
                            </MDBContainer>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>
                <h4 class="mb-4 text-dark">Avifauna, Reptiles and Amphibians</h4>
                <MDBRow class="">
                    <MDBCol class="mb-4 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                <span class="para-img float-left mr-20" style="text-align : right;">
                                    <img src="/assets/images/simbalbara-np-birds-1.jpg" class="img-round-corner" style="max-height : 200px; width : auto; max-width : 100%; margin-right : 20px">
                                    <br>
                                    <!-- <small><i>- PC : @Kunal Angrish</i></small> -->
                                </span>
                                <span class="para-img float-right ml-20" style="text-align : right;">
                                    <img src="/assets/images/simbalbara-np-birds-2.jpg" class="img-round-corner" style="max-height : 200px; width : auto; max-width : 100%; margin-right : 20px">
                                    <br>
                                    <!-- <small><i>- PC : @Kunal Angrish</i></small> -->
                                </span>
                                210 species of birds have been recorded from this national park making it an area vastly rich in avian fauna. These include Oriental Pied Hornbill, Grey Hornbills, Red Jungle Fowl, Peacock, Brown Hawk Owl, Crested Kingfisher, Lesser Flameback Woodpecker, Black Hooded Oriole, Grey Night Jar, Black Stork etc. The national park having a mosaic habitat complex of grassland, woodland, riverine habitats offer great avian fauna diversity.
                                <br><br>
                                The National park owing to large tracts of riverine habitat is home to a variety of reptilian and amphibian fauna. 
                                
                                                              
                            </p>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>
                <h4 class="mb-4 text-dark">Landscape of Colonel Sher Jung National Park</h4>
                <MDBRow class="">
                    <MDBCol class="mb-4 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p class="">
                                <span class="para-img float-right ml-20" style="text-align : right">
                                    <img src="/assets/images/simbalbara-np-1.png" class="img-round-corner" style="max-height : 375px; width : auto; max-width : 100%; margin-right : 20px">
                                    <br>
                                    <!-- <small><i>- PC : @Kunal Angrish</i></small> -->
                                </span>
                                Located in the picturesque shivalik foothills of Himalayas, the protected area has diverse landscape. It has a natural boundary comprising of the main Shivalik ridge to its north and sub-ridges of the Shivalik hills to the south east and south west. The area bounded by the ridges, forms a shallow bowl-shaped valley which slopes gently from north to south, and comprises the main National Park. It is bifurcated by a number of seasonal streams known locally as <i>khala</i> or <i>rau</i>. Where the ground is nearly level in valleys between the hills the streams are broad and shallow with stony beds formed of rounded stones found all over Shivaliks.                             
                            </p>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </section>        
    </div>
</template>
<script>
  import { ref } from "vue";
  import VueHorizontal from "vue-horizontal";
  import { 
    MDBCarousel,
    MDBCol, MDBRow, MDBContainer,
    MDBCardText, MDBBtn,
    MDBCard, MDBCardHeader, MDBCardBody, MDBCardTitle, MDBCardFooter
  } from "mdb-vue-ui-kit";
  export default {
    name : "Daranghati",
    components: {
      MDBCarousel,
      MDBCol, MDBRow, MDBContainer,
      MDBCardText, VueHorizontal, MDBBtn,
      MDBCard, MDBCardHeader, MDBCardBody, MDBCardTitle, MDBCardFooter
    },
    directives: {
    },
    setup() {
      const items1 = [
        {
          src: "/assets/images/simbalbara-np-banner.jpg",
          alt: "Simbalbara National Park",
          caption : ""
        }
      ];
      const carousel1 = ref(0);
      return {
        items1,
        carousel1,
        mammals: [
          {
            img : '/assets/images/simbalbara-np-mammals-1.jpg'
          },
          {
            img : '/assets/images/simbalbara-np-mammals-2.jpg'
          },
          {
            img : '/assets/images/simbalbara-np-mammals-3.jpg'
          },
          {
            img : '/assets/images/simbalbara-np-mammals-4.jpg'
          },
          {
            img : '/assets/images/simbalbara-np-mammals-5.jpg'
          },
          {
            img : '/assets/images/simbalbara-np-mammals-6.jpg'
          },
          {
            img : '/assets/images/simbalbara-np-mammals-7.png'
          },
          {
            img : '/assets/images/simbalbara-np-mammals-8.png'
          },
        ],
        avifaunas: [
          {
            img : '/assets/images/simbalbara-np-birds-1.jpg'
          },
          {
            img : '/assets/images/simbalbara-np-birds-2.jpg'
          },
        ]
      };
    }
  };
</script>
<style scoped>

    table {
        border-collapse:separate;
        border:solid black 1px;
        border-radius:6px;
        -moz-border-radius:6px;
    }

    th {
        border-top: none;
        background-color: #4ea950;
        color: white;
    }

    th, td {
        padding: 4px;
        border-left:solid black 1px;
        border-top:solid black 1px;
    }

    td:first-child, th:first-child {
     border-left: none;
    }

    tr:hover {background-color: coral;}

    /* GRADIENTS */
    .winter-neva-gradient {
        background-image: linear-gradient(120deg,#a1c4fd,#c2e9fb);
    }

    .deep-blue-gradient {
        background-image: linear-gradient(120deg,#e0c3fc,#8ec5fc);
    }
    /* GRADIENTS END */

    .gradient-text {
        line-height: 200px; 
        font-size : 24px; 
        letter-spacing : 2px
    }

    .z-depth-1-half {
        -webkit-box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)!important;
        box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)!important;
    }

    .bg-dark-green {
        background-color: rgb(77,169,80);
    }

    .para-img {
        float: right;
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
    }

    .bg-contact {
        background: linear-gradient(rgba(44, 95, 45, 0.5), rgba(44, 95, 45,0.5)), url('/assets/img/contact-bg.svg');
    }

    .float-left {
        float: left !important;
    }

    .mr-20 {
        margin-right : 20px
    }

    .ml-20 {
        margin-left : 20px
    }

    .img-round-corner {
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
    }

    h4 {
        margin: 0;  
    }
    .color-block-5 {
        height: 200px;
        width: 200px;
    }
    .img-gradient{
        position:relative;
        display:inline-block;
    }
    .img-gradient:after {
        content:'';
        position:absolute;
        left:0; top:0;
        width:100%; height:100%;
        display:inline-block;
        background: -moz-linear-gradient(top, rgba(0,47,75,0.5) 0%, rgba(220, 66, 37, 0.5) 100%); /* FF3.6+ */
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(220, 66, 37, 0.5)), color-stop(100%,rgba(0,47,75,0.5))); /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* Opera 11.10+ */
        background: -ms-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* IE10+ */
        background: linear-gradient(to bottom, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002f4b', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
    }
    .img-gradient img{
        display:block;
    }

</style>