<template>
    <div>
        <section class="bg-forest">
            <MDBContainer class="p-5 bg-form">
                <MDBRow class="mb-3">
                    <MDBCol md="12" class="mx-auto text-light item-align-center">
                        <MDBCardText style="text-align : justify;" class="">
                            <h2 class="mb-4 text-light text-center">Eco Development: A New Beginning of Participatory Conservation at Shimla Water Catchment and Chail Wildlife Sanctuary</h2>
                            <p>
                                <span class="para-img ml-20" style="text-align : right">
                                    <img src="/assets/images/updates/2023/october/001/2.jpeg" class="img-round-corner" style="max-height : 375px; width : auto; max-width : 100%;">
                                    <br>
                                    <!-- <small><i>- Administrative map of Shimla Water Catchment Wildlife Sanctuary</i></small> -->
                                </span>
                                The tool of eco development for conservation is a robust supplement to the mandate of protection and conservation. Eco development as a philosophy and conservation tool is not alien to the Indian landscape. It is aimed at the simple objective of providing alternate livelihood and income generation opportunities to forest dependent communities residing inside or in the ESZ areas of the protected areas to offset anthropogenic disturbance and galvanize goodwill between forest department and the local community
                                <br><br>                              
                            </p>
                            <br><br> <br>
                            <p>
                                <span class="para-img float-left mr-20" style="text-align : right">
                                    <img src="/assets/images/updates/2023/october/001/3.jpeg" class="img-round-corner" style="max-height : 345px; width : auto; max-width : 100%;">
                                    <br>
                                    <!-- <small><i>- Administrative map of Shimla Water Catchment Wildlife Sanctuary</i></small> -->
                                </span>
                                The state of Himachal Pradesh has previously made little use of this robust conservation tool given the challenges of terrain and accessibility and the unique socio economic character of the state. The Shimla Wildlife Division took an initiative to correct this anomaly and initiate a program of eco development throughout the various protected areas within its jurisdiction.
                                <br><br>    
                                A humble beginning in pursuit towards eco development in Chail Wildlife Sanctuary was witnessed with the Pine Needle and Food Processing Training held for women of villages in Eco Sensitive Zone of the sanctuary. The month long training program commenced on 14th of September and it is expected that the development of pine needle handicraft skill among the local community would empower these women’s groups by providing them another channel of income and enhancing their dignity and social standing. The training program witnessed enthusiastic participation of over 150 women from the villages adjacent to the sanctuary many of them expressing their deep gratitude to the department and the wildlife wing for such an initiative
                            </p>  
                            <br><br> <br><br><br>  
                            <p>
                                <span class="para-img float-left mr-20" style="text-align : right">
                                    <img src="/assets/images/updates/2023/october/001/6.jpeg" class="img-round-corner" style="max-height : 345px; width : auto; max-width : 100%;">
                                    <br>
                                    <!-- <small><i>- Administrative map of Shimla Water Catchment Wildlife Sanctuary</i></small> -->
                                </span>
                                <span class="para-img float-right ml-20" style="text-align : right">
                                    <img src="/assets/images/updates/2023/october/001/7.jpeg" class="img-round-corner" style="max-height : 260px; width : auto; max-width : 100%;">
                                    <br>
                                    <!-- <small><i>- Administrative map of Shimla Water Catchment Wildlife Sanctuary</i></small> -->
                                </span>
                                A similar initiative was launched in Shimla Water Catchment Wildlife Sanctuary with a training program organized for women of villages in Chamyana panchayat falling in ESZ area of the sanctuary related to soap manufacture and food processing. The program commenced on 19th of September and enabled more than 40 women being trained on the techniques of soap manufacture and food processing.
                                <br><br>                              
                            </p>

                        </MDBCardText>
                    </MDBCol>
                </MDBRow>                     
            </MDBContainer>
        </section>        
    </div>
</template>
<script>
  import { ref } from "vue";
  import { 
    MDBCol, MDBRow, MDBContainer,
    MDBInput,
    MDBCheckbox,
    MDBBtn,
    MDBTextarea,
    MDBModal,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBCarousel,
    MDBCardText,
    MDBCardImg, mdbRipple

  } from "mdb-vue-ui-kit";
  export default {
    name : "Updates_October_001",
    components: {
        MDBCol, MDBRow, MDBContainer,
        MDBInput,
        MDBCheckbox,
        MDBBtn,
        MDBTextarea,
        MDBModal,
        MDBModalHeader,
        MDBModalTitle,
        MDBModalBody,
        MDBModalFooter,
        MDBCarousel,
        MDBCardText,
        MDBCardImg
    },
    directives: {
      mdbRipple
    },
    setup() {
      const items1 = [
        {
          src: "/assets/images/contact-us.jpg",
          alt: "Shimla Wildlife Division",
        }
      ];
      const exampleModal = ref(false);
      const carousel1 = ref(0);
      return {
        exampleModal,
        items1,
        carousel1,
        form : {
            name : '',
            email : '',
            phone : '',
            query : '',
            city : '',
            newsletter : true
        },
        modal : {
            show : true,
            title : 'SUCCESS!!',
            body : 'Your query has been posted successfully! We will get back to you soon'
        }
      };
    },
    methods : {
        submitForm(){

            this.exampleModal = true;
        }
    }
  };
</script>
<style scoped>

    .bg-dark-green {
        background-color: rgb(77,169,80);
    }

    .para-img {
        float: right;
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
    }

    .bg-contact {
        background: linear-gradient(rgba(44, 95, 45, 0.5), rgba(44, 95, 45,0.5)), url('/assets/img/contact-bg.svg');
    }

    .float-left {
        float: left !important;
    }

    .mr-20 {
        margin-right : 20px
    }

    .ml-20 {
        margin-left : 20px
    }

    .bg-trago {
        background: linear-gradient(rgba(44, 95, 45, 0.9), rgba(44, 95, 45,0.9)), url('/assets/img/re_6.jpeg') center ;
    }

    .bg-forest {
        background: url('/assets/img/Untitled design-15.png') ;
        background-size: cover;
    }

    .img-round-corner {
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
    }

    .bg-form {
        background-color : rgb(66,136,121);
    }

    .form-title {

        border-left: 5px solid rgb(138,250,160);
        padding-left: 10px;
    }

    .form-color {
        color : #4F4F4F !important;
        border-color: transparent !important;
        background-color: white !important;
    }
    .update-container {
        overflow: hidden;        
    }
    .responsive-iframe {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

</style>