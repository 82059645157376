<template>
    <div>
        <MDBCarousel
            v-model="carousel1"
            :items="items1"
            fade
            :controls="false"
            :indicators="false"
        />
        <section >
            <MDBContainer class="p-5">
                <h4 class="mb-4 text-dark">About Renuka Ji Wildlife Sanctuary</h4>
                <MDBRow class="mb-3">
                    <MDBCol class="mb-3 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                <span class="para-img ml-20" style="text-align : right">
                                    <img src="/assets/images/renuka-ji-map.png" class="img-round-corner" style="max-height : 375px; width : auto; max-width : 100%;">
                                    <br>
                                    <small><i>- Administrative map of Renuka Ji Wildlife Sanctuary</i></small>
                                </span>
                                Located in Sirmour District of Himachal Pradesh, Sri Renuka Ji Wildlife Sanctuary is a small sanctuary spread across 3.87 sq km. The sanctuary is unique for housing Ramsar Site of Renuka Ji Wetland which is also religiously revered as Maa Renuka Ji, mother of Lord Parashuram. The wetland supports variety of reptiles, amphibians, migratory birds and fishes including a notable population of Golden Mahseer.
                                <br><br><br>
                                <b>History of Renuka Ji Wildlife Sanctuary</b>
                                <br>
                                Renuka forest was declared protected area by the district board, constituted by Raja Shamsher Prakash of Sirmour, in the last decade of the nineteenth century. The area was declared sanctuary in 1964, under the administrative control of territorial forest division, first with Rajgarh forest division then with Renuka forest division. The area came under the control of wildlife division, Shimla in 1986 and wildlife range was established at Renuka for its management. The Renuka Ji Wildlife Sanctuary was finally notified in year 2021 after reorganization for Renuka Ji Dam and Renuka Ji Mela leading to an area of 3.87 sq km finally being demarcated for the sanctuary.    
                            </p>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>
                <h4 class="mb-4 text-dark">Location and Access</h4>
                <MDBRow class="">
                    <MDBCol class="mb-4 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                <span class="para-img float-left mr-20" style="text-align : right">
                                    <img src="/assets/images/renuka-ji-banner-bg.png" class="img-round-corner" style="max-height : 375px; width : auto; max-width : 100%; margin-right : 20px">
                                    <br>
                                    <!-- <small><i>- PC : @Kunal Angrish</i></small> -->
                                </span>
                                The Renuka Ji Wildlife Sanctuary is easily accessible from the Kumarhatti-Nahan road through a diversion 10 kms before Nahan. The sanctuary is headquartered at Renuka Ji adjacent to the Renuka Ji lake.
                                <br><br>        
                                <b>Sri Renuka Ji Wetland: A Ramsar Site</b>
                                <br>
                                The Renuka Ji Wetland was designated as a Ramsar Site as a ‘Wetland of International Importance’ on 8th November 2005 given its unique biodiversity, ecological character, cultural and aesthetic importance. The Wetland is a geological marvel with 5 springs feeding the lake and almost all of them are result of fracture stress/ depressions. The wetland supports immense biodiversity in the form of fishes, amphibians, reptiles and avifauna including migratory birds during winters.
                                <br><br>        
                                <b>Cultural and Religious Significance of Renuka Ji</b>
                                <br>
                                The Renuka Ji lake is revered and worshipped in form of Sri Renuka Ji Mata, mother of Lord Parashuram. The legend has it that Renuka Ji took Jal Samadhi in the lake and vowed to permanently live in lake except on the occasion of Devprabhodini Ekdashi once every year to meet her son. In order to commemorate this occasion, Renuka Ji Mela is celebrated every year. During the Mela on Dashmi, the palanquin of Lord Parshuram is brought to Renuka Ji from the ancient temple in Jamu Koti village in traditional procession known a ‘Shobha Yatra’, attended by lakhs of devotees.
                            </p>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>
                <h4 class="mb-4 text-dark">Floristic Diversity of Renuka Ji Wildlife Sanctuary</h4>
                <MDBRow class="">
                    <MDBCol class="mb-4 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                <span class="para-img float-left mr-20" style="text-align : right">
                                    <img src="/assets/images/renuka-ji-lake.jpg" class="img-round-corner" style="max-height : 175px; width : auto; max-width : 100%; margin-right : 20px">
                                    <br>
                                    <!-- <small><i>- PC : @Kunal Angrish</i></small> -->
                                </span>
                                The sanctuary forming the catchment area of the Renuka Ji lake has rich broad leaf vegetation supporting diverse trees like Kachnar, Jamun, Shisham, Sal, Khajur, Peepal etc. Apart from the forests, the sanctuary also supports diverse plant species in wetland complex including mosses, azolla species, Lotus, the common reed etc. They play a crucial role in removal of heavy metals & pollutants and also serve as breeding and nesting sites for avifauna and aquatic fauna.                            
                            </p>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>
                <h4 class="mb-4 text-dark">Mammalian Diversity</h4>
                <MDBRow class="">
                    <MDBCol class="mb-4 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                The sanctuary although small in size has population of diverse mammals. The sight of a Sambar making its way to the lake or the alarm call of a barking deer is common in this scenic space. Similarly, spotted deers are also present in the sanctuary. The common leopard is the apex predator feeding on this herbivore biomass. 
                                <br>                                
                            </p>
                            <MDBContainer fluid>
                                <MDBRow >
                                    <MDBCol md="12" xs="12" class="mx-auto text-center">
                                        <vue-horizontal responsive>
                                            <section v-for="item in mammals" :key="item.title">
                                                <MDBCard style="margin-right: 20px; max-width : 350px;">
                                                    <MDBCardBody>
                                                        <img :src="item.img" style="width : 100%"  class="img-cut-edge"/>                                                    
                                                    </MDBCardBody>
                                                </MDBCard>
                                            </section>
                                        </vue-horizontal>
                                    </MDBCol>
                                </MDBRow>
                            </MDBContainer>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>   
                <h4 class="mb-4 text-dark">AviFauna of Renuka Ji</h4>
                <MDBRow class="">
                    <MDBCol class="mb-4 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                The sanctuary is home to close to 200 birds making it an exceptionally diverse bird area. While the lake catchment forms an important wooded, dry srub bird landscape, the lake itself offers rich bird diversity especially in the winter months. The birds of Renuka Ji includes but is not limited to Moorhen, Mallard, Ruddy Shelduck, Grey Heron, Common Kingfisher, Crested Kingfisher, Oriental White Eye, Bee Eaters, Common Hoopoe, Oriental Scoops Owl, Grey Hornbill, Plum Headed Parakeet, Yellow Footed Pigeon etc.
                                <br>
                                
                            </p>
                            <MDBContainer fluid>
                                <MDBRow >
                                    <MDBCol md="12" xs="12" class="mx-auto text-center">
                                        <vue-horizontal responsive>
                                            <section v-for="item in avifaunas" :key="item.title">
                                                <MDBCard style="margin-right: 20px; max-width : 350px;">
                                                    <MDBCardBody>
                                                        <img :src="item.img" style="width : 100%"  class="img-cut-edge"/>                                                    
                                                    </MDBCardBody>
                                                </MDBCard>
                                            </section>
                                        </vue-horizontal>
                                    </MDBCol>
                                </MDBRow>
                            </MDBContainer>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow> 
                <h4 class="mb-4 text-dark">Reptiles and Amphibians of Renuka Ji Wildlife Sanctuary</h4>
                <MDBRow class="">
                    <MDBCol class="mb-4 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                The Renuka Ji Sanctuary and lake hosts a wide variety of reptilian fauna notable among them being turtles and snakes. The Indian Soft shell turtle, Indian Flapshell turtle are notable turtles found in lake whereas the snakes include the spectacled cobra, white lipped pit viper, wolf snake, rat snakes etc. The lake supports a good population of Golden Mahseer among other fishes. 
                                <br>                                
                            </p>
                            <MDBContainer fluid>
                                <MDBRow >
                                    <MDBCol md="12" xs="12" class="mx-auto text-center">
                                        <vue-horizontal responsive>
                                            <section v-for="item in reptiles" :key="item.title">
                                                <MDBCard style="margin-right: 20px; max-width : 350px;">
                                                    <MDBCardBody>
                                                        <img :src="item.img" style="width : 100%"  class="img-cut-edge"/>                                                    
                                                    </MDBCardBody>
                                                </MDBCard>
                                            </section>
                                        </vue-horizontal>
                                    </MDBCol>
                                </MDBRow>
                            </MDBContainer>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>   
            </MDBContainer>
        </section>
        <section class="bg-trago">
            <MDBContainer>
                <MDBRow >
                <MDBCol md="12" xs="12" class="mx-auto text-center mt-5">
                    <h3 class="text-center text-light">Important Links</h3>      
                    <br>      
                </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol class="mb-9 mx-auto" md="4" xs="6">
                        <MDBCard>
                        <a v-mdb-ripple="{ color: 'light' }">
                            <MDBCardImg src="/assets/images/updates/2024/january/nature_resilliance.png" top alt="" />
                        </a>
                        <MDBCardBody>
                            <MDBCardTitle>Nature finds its way!! Nature Resilience</MDBCardTitle>
                            <MDBBtn tag="a" href="/renuka-ji-wls-001" color="primary">Read More...</MDBBtn>
                        </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol class="mb-9 mx-auto" md="4" xs="6">
                        <MDBCard>
                        <a v-mdb-ripple="{ color: 'light' }">
                            <MDBCardImg src="/assets/images/updates/2024/january/ficus_membrane.png" top alt="" />
                        </a>
                        <MDBCardBody>
                            <MDBCardTitle>A loving hug or a killer embrace?</MDBCardTitle>
                            <MDBBtn tag="a" href="/renuka-ji-wls-002" color="primary">Read More...</MDBBtn>
                        </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol class="mb-9 mx-auto" md="4" xs="6">
                        <MDBCard>
                        <a v-mdb-ripple="{ color: 'light' }">
                            <MDBCardImg src="/assets/images/updates/2024/january/cavity_nesting.png" top alt="" />
                        </a>
                        <MDBCardBody>
                            <MDBCardTitle>Whose home is this?</MDBCardTitle>
                            <MDBBtn tag="a" href="/renuka-ji-wls-003" color="primary">Read More...</MDBBtn>
                        </MDBCardBody>
                        </MDBCard>
                    </MDBCol>               
                </MDBRow>
                <MDBRow>
                    <MDBCol class="mb-9 mx-auto" md="4" xs="6">
                        <MDBCard>
                        <a v-mdb-ripple="{ color: 'light' }">
                            <MDBCardImg src="/assets/images/updates/2024/january/migratory_bird_poiting.png" top alt="" />
                        </a>
                        <MDBCardBody>
                            <MDBCardTitle>Migratory birds of Renuka</MDBCardTitle>
                            <MDBBtn tag="a" href="/renuka-ji-wls-004" color="primary">Read More...</MDBBtn>
                        </MDBCardBody>
                        </MDBCard>
                    </MDBCol>                     
                    <MDBCol class="mb-9 mx-auto" md="4" xs="6">
                        <MDBCard>
                        <a v-mdb-ripple="{ color: 'light' }">
                            <MDBCardImg src="/assets/images/updates/2024/january/who_made_cave.png" top alt="" />
                        </a>
                        <MDBCardBody>
                            <MDBCardTitle>Who made this cave?</MDBCardTitle>
                            <MDBBtn tag="a" href="/renuka-ji-wls-005" color="primary">Read More...</MDBBtn>
                        </MDBCardBody>
                        </MDBCard>
                    </MDBCol>      
                    <MDBCol class="mb-9 mx-auto" md="4" xs="6">
                        <MDBCard>
                        <a v-mdb-ripple="{ color: 'light' }">
                            <MDBCardImg src="/assets/images/updates/2024/january/friends_of_wetland.png" top alt="" />
                        </a>
                        <MDBCardBody>
                            <MDBCardTitle>Who made this cave?</MDBCardTitle>
                            <MDBBtn tag="a" href="/renuka-ji-wls-006" color="primary">Read More...</MDBBtn>
                        </MDBCardBody>
                        </MDBCard>
                    </MDBCol>             
                </MDBRow>
            </MDBContainer>
            </section>
        <!-- <section class="bg-contact p-5">
            <MDBContainer>
                <MDBRow>
                    <MDBCol md="8" class="mx-auto mb-5">
                        <MDBCard text="center">
                            <MDBCardHeader class="bg-dark-green text-light"><h4>Vegetation of Renuka Ji Wildlife Sanctuary</h4></MDBCardHeader>
                            <MDBCardBody>
                                <MDBCardText style="text-align:justify">
                                    The National Park has great floral diversity with as many as 184 species of flora recorded from it including 49 families of plants. The National Park is dominated by Sal Forests whereas the National park also has rich vegetation of variety of species including Sain, Jamun, Rohini, Chamror, Amaltas, Ber, Bamboo species etc. Apart from trees, the national park has several herbs, shrubs, climbers and grasses that enhance the habitat potential and provide rich forage to biodiversity.

                                </MDBCardText>                                
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol md="8" class="mx-auto mb-5">
                        <MDBCard text="center">
                            <MDBCardHeader class="bg-dark-green text-light"><h4>Mammals of Renuka Ji Wildlife Sanctuary</h4></MDBCardHeader>
                            <MDBCardBody>
                                <MDBCardText style="text-align:justify">                                    
                                    The National Park is migratory home to Asiatic Elephant that crosses over the Yamuna from reserved forests of Uttarakhand into the protected Area. There have been several recorded sightings of herds of elephants in the national park making it the only protected area in Himachal Pradesh that has recorded presence of elephants.
                                    <br><br>
                                    The Common Leopard is the apex carnivore in this national park which has diverse herbivore diversity in form of Sambar, Barking Deer, Himalayan Goral, Spotted Deer etc. It is one of the lowest extent for Himalayan Goral habitat. Apart from these species, smaller animals like Jackals, Himalayan Palm Civet, Wild Boar and Yellow throated marten are also found here.
                                </MDBCardText>                                
                            </MDBCardBody>
                            <MDBCardFooter>
                                <MDBContainer fluid>
                                    <MDBRow >
                                        <MDBCol md="12" xs="12" class="mx-auto text-center">
                                            <vue-horizontal responsive>
                                                <section v-for="item in mammals" :key="item.title">
                                                    <MDBCard style="margin-right: 20px; max-width : 350px;">
                                                        <MDBCardBody>
                                                            <img :src="item.img" style="width : 100%"  class="img-cut-edge"/>                                                    
                                                        </MDBCardBody>
                                                    </MDBCard>
                                                </section>
                                            </vue-horizontal>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBContainer>
                            </MDBCardFooter>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol md="8" class="mx-auto mb-5">
                        <MDBCard text="center">
                            <MDBCardHeader class="bg-dark-green text-light"><h4>Avifauna, Reptiles and Amphibians</h4></MDBCardHeader>
                            <MDBCardBody>
                                <MDBCardText style="text-align:justify">                                    
                                    210 species of birds have been recorded from this national park making it an area vastly rich in avian fauna. These include Oriental Pied Hornbill, Grey Hornbills, Red Jungle Fowl, Peacock, Brown Hawk Owl, Crested Kingfisher, Lesser Flameback Woodpecker, Black Hooded Oriole, Grey Night Jar, Black Stork etc. The national park having a mosaic habitat complex of grassland, woodland, riverine habitats offer great avian fauna diversity.
                                    <br><br>
                                    The National park owing to large tracts of riverine habitat is home to a variety of reptilian and amphibian fauna. 
                                </MDBCardText>                                
                            </MDBCardBody>
                            <MDBCardFooter>
                                <MDBContainer fluid>
                                    <MDBRow >
                                        <MDBCol md="12" xs="12" class="mx-auto text-center">
                                            <vue-horizontal responsive>
                                                <section v-for="item in avifaunas" :key="item.title">
                                                    <MDBCard style="margin-right: 20px; max-width : 350px;">
                                                        <MDBCardBody>
                                                            <img :src="item.img" style="width : 100%"  class="img-cut-edge"/>                                                    
                                                        </MDBCardBody>
                                                    </MDBCard>
                                                </section>
                                            </vue-horizontal>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBContainer>
                            </MDBCardFooter>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </section> -->
        <!-- <section class="bg-dark">
            <MDBContainer class="p-5">
                <h4 class="mb-4 text-light text-center">Landscape of Colonel Sher Jung National Park</h4>
                <MDBRow class="">
                    <MDBCol class="mb-4 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p class="text-light">
                                <span class="para-img float-left mr-20" style="text-align : right">
                                    <img src="/assets/images/simbalbara-np-1.png" class="img-round-corner" style="max-height : 375px; width : auto; max-width : 100%; margin-right : 20px">
                                    <br>
                                    <small><i>- PC : @Kunal Angrish</i></small>
                                </span>
                                Located in the picturesque shivalik foothills of Himalayas, the protected area has diverse landscape. It has a natural boundary comprising of the main Shivalik ridge to its north and sub-ridges of the Shivalik hills to the south east and south west. The area bounded by the ridges, forms a shallow bowl-shaped valley which slopes gently from north to south, and comprises the main National Park. It is bifurcated by a number of seasonal streams known locally as <i>khala</i> or <i>rau</i>. Where the ground is nearly level in valleys between the hills the streams are broad and shallow with stony beds formed of rounded stones found all over Shivaliks.                             
                            </p>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>                
            </MDBContainer>
        </section>         -->
        
    </div>
</template>
<script>
  import { ref } from "vue";
  import VueHorizontal from "vue-horizontal";
  import { 
    MDBCarousel,
    MDBCol, MDBRow, MDBContainer,
    MDBCardText, MDBBtn, mdbRipple,
    MDBCard, MDBCardHeader, MDBCardBody, MDBCardTitle, MDBCardFooter, MDBCardImg
  } from "mdb-vue-ui-kit";
  export default {
    name : "Daranghati",
    components: {
      MDBCarousel,
      MDBCol, MDBRow, MDBContainer,
      MDBCardText,VueHorizontal, MDBBtn,mdbRipple,
      MDBCard, MDBCardHeader, MDBCardBody, MDBCardTitle, MDBCardFooter, MDBCardImg
    },
    directives: {
        mdbRipple
    },
    setup() {
      const items1 = [
        {
          src: "/assets/images/renuka-ji-banner.png",
          alt: "Renuka Ji Wildlife Sanctuary",
          caption : ""
        }
      ];
      const carousel1 = ref(0);
      return {
        items1,
        carousel1,
        mammals: [
          {
            img : '/assets/images/renuka-ji-mammals-1.jpg'
          },
          {
            img : '/assets/images/renuka-ji-mammals-2.jpg'
          }
        ],
        avifaunas: [
          {
            img : '/assets/images/renuka-ji-avifauna-1.jpg'
          },
          {
            img : '/assets/images/renuka-ji-avifauna-2.jpg'
          },
          {
            img : '/assets/images/renuka-ji-avifauna-3.jpg'
          }
        ],
        reptiles: [
          {
            img : '/assets/images/renuka-ji-reptile.jpg'
          }
        ],
        wetland: [
          {
            img : '/assets/images/renuka-ji-wetland.jpg'
          }
        ]
      };
    }
  };
</script>
<style scoped>

    table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
    }

    th, td {
        padding: 4px;
    }

    /* GRADIENTS */
    .winter-neva-gradient {
        background-image: linear-gradient(120deg,#a1c4fd,#c2e9fb);
    }

    .deep-blue-gradient {
        background-image: linear-gradient(120deg,#e0c3fc,#8ec5fc);
    }
    /* GRADIENTS END */

    .gradient-text {
        line-height: 200px; 
        font-size : 24px; 
        letter-spacing : 2px
    }

    .z-depth-1-half {
        -webkit-box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)!important;
        box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)!important;
    }

    .bg-dark-green {
        background-color: rgb(77,169,80);
    }

    .para-img {
        float: right;
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
    }

    .bg-contact {
        background: linear-gradient(rgba(44, 95, 45, 0.5), rgba(44, 95, 45,0.5)), url('/assets/img/contact-bg.svg');
    }

    .float-left {
        float: left !important;
    }

    .mr-20 {
        margin-right : 20px
    }

    .ml-20 {
        margin-left : 20px
    }

    .img-round-corner {
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
    }

    h4 {
        margin: 0;  
    }
    .color-block-5 {
        height: 200px;
        width: 200px;
    }
    .img-gradient{
        position:relative;
        display:inline-block;
    }
    .img-gradient:after {
        content:'';
        position:absolute;
        left:0; top:0;
        width:100%; height:100%;
        display:inline-block;
        background: -moz-linear-gradient(top, rgba(0,47,75,0.5) 0%, rgba(220, 66, 37, 0.5) 100%); /* FF3.6+ */
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(220, 66, 37, 0.5)), color-stop(100%,rgba(0,47,75,0.5))); /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* Opera 11.10+ */
        background: -ms-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* IE10+ */
        background: linear-gradient(to bottom, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002f4b', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
    }
    .img-gradient img{
        display:block;
    }

</style>