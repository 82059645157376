<template>
    <div>
        <MDBCarousel
            v-model="carousel1"
            :items="items1"
            fade
            :controls="false"
            :indicators="false"
        />
        <section >
            <MDBContainer class="p-5">
                <h4 class="mb-4 text-dark">About Shimla Water Catchment Wildlife Sanctuary</h4>
                <MDBRow class="mb-3">
                    <MDBCol class="mb-3 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                <span class="para-img ml-20" style="text-align : right">
                                    <img src="/assets/images/catchement-map.png" class="img-round-corner" style="max-height : 375px; width : auto; max-width : 100%;">
                                    <br>
                                    <!-- <small><i>- Administrative map of Shimla Water Catchment Wildlife Sanctuary</i></small> -->
                                </span>
                                The Shimla Water Catchment Sanctuary spread across 10.20 sq km is unique in being one of the first protected areas in the country being managed for the purpose of water harvesting and conservation. This sanctuary located in the heart of Shimla town is a green paradise providing reserve to threatened fauna, holding incredible floristic diversity and providing various ecosystem services in form of water provisioning, carbon sequestration, biodiversity support, recreational and aesthetic services making it a heritage protected area from British times.
                                <br><br>
                                <b>History of Sanctuary & Water Conservation in Area</b>
                                <br>
                                The sanctuary area was property of Rana of Koti Estate and was obtained in the form of lease on behalf of the Shimla Municipal Committee in 1878. During the British times, these forests were well protected and conserved for purpose of water harvesting. The several tanks, reservoirs, pipes and pumping stations constructed by the British for the purpose are till today visible and well maintained as heritage structures in the sanctuary. The sanctuary has within its limits 25 streams which make the catchment and this area alone provided for water requirements of the then Shimla city. The sanctuary is hence rightly named as Shimla Water Catchment Sanctuary. 
                                <br>                              
                            </p>
                            <p>
                                <span class="para-img float-left mr-20" style="text-align : right">
                                    <img src="/assets/images/cathcment-landscape-1.jpg" class="img-round-corner" style="max-height : 275px; width : auto; max-width : 100%;">
                                    <br>
                                    <!-- <small><i>- Administrative map of Shimla Water Catchment Wildlife Sanctuary</i></small> -->
                                </span>
                                The sanctuary was initially managed by MC Shimla and later handed over to Urban division and finally Wildlife division. It was finally notified as a protected area of 10.00 sq km on 7th June 2013.
                                <br><br>
                                <b>Location and Access</b>
                                <br>
                                The sanctuary is accessible from Dhalli from NH-05. The entrance gate of sanctuary is around 500 metres from Dhalli Chowk. As the NH-05 cuts through the sanctuary, large parts of the sanctuary are accessible through this road. 

                            </p>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>                
                <h4 class="mb-4 text-dark">Floristic Diversity of Sanctuary</h4>
                <MDBRow class="">
                    <MDBCol class="mb-4 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                <span class="para-img float-left mr-20" style="text-align : right">
                                    <img src="/assets/images/catchment-flora-1.png" class="img-round-corner" style="max-height : 175px; width : auto; max-width : 100%; margin-right : 20px">
                                    <br>
                                    <!-- <small><i>- PC : @Kunal Angrish</i></small> -->
                                </span>
                                The sanctuary has a rich variety of flora highlighted by the state tree deodar that is found in pure patches in several parts. The area has Ban Oak, Mohru oak, Kharsu Oak, Khanor, Himalayan Blue Pine, Chil Pine and several other trees showcasing altitudinal zonation and tree diversity. The sanctuary is famous for 21 orchid species that are found in various parts of sanctuary and the blooming of these and other flowering species is a sight to behold. Apart from this, the area has diverse shrubs, herbs, grasses and medicinal plants that have great ecological value.    
                            </p>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>
                <h4 class="mb-4 text-dark">Mammalian Fauna in Catchment Area</h4>
                <MDBRow class="">
                    <MDBCol class="mb-4 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                The sanctuary has a good population of the Himalayan Goral, a near threatened species of Himalayas that is fond of the green rocky slopes of the sanctuary. The Common Leopard is the apex predator in the sanctuary. The mammals of this sanctuary include the Barking Deer, Yellow throated marten, Red Fox, Giant Flying Squirrel, Grey Langur, Sambar etc.
                                <br>                                
                            </p>
                            <MDBContainer fluid>
                                <MDBRow >
                                    <MDBCol md="12" xs="12" class="mx-auto text-center">
                                        <vue-horizontal responsive>
                                            <section v-for="item in mammals" :key="item.title">
                                                <MDBCard style="margin-right: 20px; max-width : 350px;">
                                                    <MDBCardBody>
                                                        <img :src="item.img" style="width : 100%"  class="img-cut-edge"/>                                                    
                                                    </MDBCardBody>
                                                </MDBCard>
                                            </section>
                                        </vue-horizontal>
                                    </MDBCol>
                                </MDBRow>
                            </MDBContainer>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>   
                <h4 class="mb-4 text-dark">AviFauna of Shimla Water Catchment Sanctuary</h4>
                <MDBRow class="">
                    <MDBCol class="mb-4 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                The sanctuary is rich in bird life with 146 species recorded here. The sanctuary has birds that are typical of the Himalayan region including Cheer Pheasant, Himalayan Woodpecker, Yellow-billed Magpie, Green backed tit, Ultramarine flycatchers, Eurasian Jay, Rufous Sibia, White Throated Laughing Thrush, Grey Wing Black Bird, Slaty Headed Parakeet. 
                                <br>
                                The is renowned for Koklass pheasant, the flagship species of conservation in this area. A census carried out in 2022 gave an estimate of 24-26 pairs in the protected area which is one of the highest reported density under natural conditions for this bird.
                                
                            </p>
                            <MDBContainer fluid>
                                <MDBRow >
                                    <MDBCol md="12" xs="12" class="mx-auto text-center">
                                        <vue-horizontal responsive>
                                            <section v-for="item in avifaunas" :key="item.title">
                                                <MDBCard style="margin-right: 20px; max-width : 350px;">
                                                    <MDBCardBody>
                                                        <img :src="item.img" style="width : 100%"  class="img-cut-edge"/>                                                    
                                                    </MDBCardBody>
                                                </MDBCard>
                                            </section>
                                        </vue-horizontal>
                                    </MDBCol>
                                </MDBRow>
                            </MDBContainer>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow> 
                <h4 class="mb-4 text-dark">Butterflies of Shimla Water Catchment</h4>
                <MDBRow class="">
                    <MDBCol class="mb-4 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                The sanctuary is known for wide diversity of butterflies that inhabit the open areas of the sanctuary where there is good presence of water and attracting plants. As many as 54 species of butterflies have been recorded from this sanctuary.<br>                                
                            </p>
                            <MDBContainer fluid>
                                <MDBRow >
                                    <MDBCol md="12" xs="12" class="mx-auto text-center">
                                        <vue-horizontal responsive>
                                            <section v-for="item in butterflies" :key="item.title">
                                                <MDBCard style="margin-right: 20px; max-width : 350px;">
                                                    <MDBCardBody>
                                                        <img :src="item.img" style="width : 100%"  class="img-cut-edge"/>                                                    
                                                    </MDBCardBody>
                                                </MDBCard>
                                            </section>
                                        </vue-horizontal>
                                    </MDBCol>
                                </MDBRow>
                            </MDBContainer>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>   
                <h4 class="mb-4 text-dark">Eco Tourism Activities in Sanctuary</h4>
                <MDBRow class="">
                    <MDBCol class="mb-4 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                The eco tourism activities in sanctuary include 7 km guided nature walk to Seog Reservoir, cycling, short stay at Seog Forest Rest House (a heritage British building constructed in 1905). The timing of these activities is from 10 A.M to 4 P.M. These activities are managed through Seog Eco Tourism Soceity a composite group of officials of department and local community.    
                            </p>
                            <MDBContainer fluid>
                                <MDBRow >
                                    <MDBCol md="12" xs="12" class="mx-auto text-center">
                                        <vue-horizontal responsive>
                                            <section v-for="item in eco_activities" :key="item.title">
                                                <MDBCard style="margin-right: 20px; max-width : 350px;">
                                                    <MDBCardBody>
                                                        <img :src="item.img" style="width : 100%"  class="img-cut-edge"/>                                                    
                                                    </MDBCardBody>
                                                </MDBCard>
                                            </section>
                                        </vue-horizontal>
                                    </MDBCol>
                                </MDBRow>
                            </MDBContainer>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>   
            </MDBContainer>
        </section>
        <!-- <section class="bg-contact p-5">
            <MDBContainer>
                <MDBRow>
                    <MDBCol md="8" class="mx-auto mb-5">
                        <MDBCard text="center">
                            <MDBCardHeader class="bg-dark-green text-light"><h4>Vegetation of Shimla Water Catchment Wildlife Sanctuary</h4></MDBCardHeader>
                            <MDBCardBody>
                                <MDBCardText style="text-align:justify">
                                    The National Park has great floral diversity with as many as 184 species of flora recorded from it including 49 families of plants. The National Park is dominated by Sal Forests whereas the National park also has rich vegetation of variety of species including Sain, Jamun, Rohini, Chamror, Amaltas, Ber, Bamboo species etc. Apart from trees, the national park has several herbs, shrubs, climbers and grasses that enhance the habitat potential and provide rich forage to biodiversity.

                                </MDBCardText>                                
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol md="8" class="mx-auto mb-5">
                        <MDBCard text="center">
                            <MDBCardHeader class="bg-dark-green text-light"><h4>Mammals of Shimla Water Catchment Wildlife Sanctuary</h4></MDBCardHeader>
                            <MDBCardBody>
                                <MDBCardText style="text-align:justify">                                    
                                    The National Park is migratory home to Asiatic Elephant that crosses over the Yamuna from reserved forests of Uttarakhand into the protected Area. There have been several recorded sightings of herds of elephants in the national park making it the only protected area in Himachal Pradesh that has recorded presence of elephants.
                                    <br><br>
                                    The Common Leopard is the apex carnivore in this national park which has diverse herbivore diversity in form of Sambar, Barking Deer, Himalayan Goral, Spotted Deer etc. It is one of the lowest extent for Himalayan Goral habitat. Apart from these species, smaller animals like Jackals, Himalayan Palm Civet, Wild Boar and Yellow throated marten are also found here.
                                </MDBCardText>                                
                            </MDBCardBody>
                            <MDBCardFooter>
                                <MDBContainer fluid>
                                    <MDBRow >
                                        <MDBCol md="12" xs="12" class="mx-auto text-center">
                                            <vue-horizontal responsive>
                                                <section v-for="item in mammals" :key="item.title">
                                                    <MDBCard style="margin-right: 20px; max-width : 350px;">
                                                        <MDBCardBody>
                                                            <img :src="item.img" style="width : 100%"  class="img-cut-edge"/>                                                    
                                                        </MDBCardBody>
                                                    </MDBCard>
                                                </section>
                                            </vue-horizontal>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBContainer>
                            </MDBCardFooter>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol md="8" class="mx-auto mb-5">
                        <MDBCard text="center">
                            <MDBCardHeader class="bg-dark-green text-light"><h4>Avifauna, Reptiles and Amphibians</h4></MDBCardHeader>
                            <MDBCardBody>
                                <MDBCardText style="text-align:justify">                                    
                                    210 species of birds have been recorded from this national park making it an area vastly rich in avian fauna. These include Oriental Pied Hornbill, Grey Hornbills, Red Jungle Fowl, Peacock, Brown Hawk Owl, Crested Kingfisher, Lesser Flameback Woodpecker, Black Hooded Oriole, Grey Night Jar, Black Stork etc. The national park having a mosaic habitat complex of grassland, woodland, riverine habitats offer great avian fauna diversity.
                                    <br><br>
                                    The National park owing to large tracts of riverine habitat is home to a variety of reptilian and amphibian fauna. 
                                </MDBCardText>                                
                            </MDBCardBody>
                            <MDBCardFooter>
                                <MDBContainer fluid>
                                    <MDBRow >
                                        <MDBCol md="12" xs="12" class="mx-auto text-center">
                                            <vue-horizontal responsive>
                                                <section v-for="item in avifaunas" :key="item.title">
                                                    <MDBCard style="margin-right: 20px; max-width : 350px;">
                                                        <MDBCardBody>
                                                            <img :src="item.img" style="width : 100%"  class="img-cut-edge"/>                                                    
                                                        </MDBCardBody>
                                                    </MDBCard>
                                                </section>
                                            </vue-horizontal>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBContainer>
                            </MDBCardFooter>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </section> -->
        <!-- <section class="bg-dark">
            <MDBContainer class="p-5">
                <h4 class="mb-4 text-light text-center">Landscape of Colonel Sher Jung National Park</h4>
                <MDBRow class="">
                    <MDBCol class="mb-4 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p class="text-light">
                                <span class="para-img float-left mr-20" style="text-align : right">
                                    <img src="/assets/images/simbalbara-np-1.png" class="img-round-corner" style="max-height : 375px; width : auto; max-width : 100%; margin-right : 20px">
                                    <br>
                                    <small><i>- PC : @Kunal Angrish</i></small>
                                </span>
                                Located in the picturesque shivalik foothills of Himalayas, the protected area has diverse landscape. It has a natural boundary comprising of the main Shivalik ridge to its north and sub-ridges of the Shivalik hills to the south east and south west. The area bounded by the ridges, forms a shallow bowl-shaped valley which slopes gently from north to south, and comprises the main National Park. It is bifurcated by a number of seasonal streams known locally as <i>khala</i> or <i>rau</i>. Where the ground is nearly level in valleys between the hills the streams are broad and shallow with stony beds formed of rounded stones found all over Shivaliks.                             
                            </p>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>                
            </MDBContainer>
        </section>         -->
        
    </div>
</template>
<script>
  import { ref } from "vue";
  import VueHorizontal from "vue-horizontal";
  import { 
    MDBCarousel,
    MDBCol, MDBRow, MDBContainer,
    MDBCardText, MDBBtn,
    MDBCard, MDBCardHeader, MDBCardBody, MDBCardTitle, MDBCardFooter
  } from "mdb-vue-ui-kit";
  export default {
    name : "Daranghati",
    components: {
      MDBCarousel,
      MDBCol, MDBRow, MDBContainer,
      MDBCardText,VueHorizontal, MDBBtn,
      MDBCard, MDBCardHeader, MDBCardBody, MDBCardTitle, MDBCardFooter
    },
    directives: {
    },
    setup() {
      const items1 = [
        {
          src: "/assets/images/catchment-banner.jpg",
          alt: "Shimla Water Catchment Wildlife Sanctuary",
          caption : ""
        }
      ];
      const carousel1 = ref(0);
      return {
        items1,
        carousel1,
        mammals: [
          {
            img : '/assets/images/cathcment-mammals-1.jpg'
          },
          {
            img : '/assets/images/cathcment-mammals-2.jpg'
          },
          {
            img : '/assets/images/cathcment-mammals-3.jpg'
          },
          {
            img : '/assets/images/cathcment-mammals-4.jpg'
          },
          {
            img : '/assets/images/cathcment-mammals-5.jpg'
          },
          
        ],
        avifaunas: [
          {
            img : '/assets/images/cathcment-birds-1.jpg'
          },
          {
            img : '/assets/images/cathcment-birds-2.jpg'
          },
          {
            img : '/assets/images/cathcment-birds-3.jpg'
          },
          {
            img : '/assets/images/cathcment-birds-4.jpg'
          },
          {
            img : '/assets/images/cathcment-birds-5.jpg'
          },
          {
            img : '/assets/images/cathcment-birds-6.jpg'
          },
          
        ],
        butterflies: [
          {
            img : '/assets/images/cathcment-butterflies-1.jpg'
          },
          {
            img : '/assets/images/cathcment-butterflies-2.jpg'
          },
          {
            img : '/assets/images/cathcment-butterflies-3.jpg'
          },
          {
            img : '/assets/images/cathcment-butterflies-4.jpg'
          },
          
        ],
        eco_activities: [
          {
            img : '/assets/images/catchment-eco-1.png'
          },
          {
            img : '/assets/images/catchment-eco-2.png'
          },
          {
            img : '/assets/images/catchment-eco-3.png'
          },
          
        ]
      };
    }
  };
</script>
<style scoped>

    table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
    }

    th, td {
        padding: 4px;
    }

    /* GRADIENTS */
    .winter-neva-gradient {
        background-image: linear-gradient(120deg,#a1c4fd,#c2e9fb);
    }

    .deep-blue-gradient {
        background-image: linear-gradient(120deg,#e0c3fc,#8ec5fc);
    }
    /* GRADIENTS END */

    .gradient-text {
        line-height: 200px; 
        font-size : 24px; 
        letter-spacing : 2px
    }

    .z-depth-1-half {
        -webkit-box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)!important;
        box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)!important;
    }

    .bg-dark-green {
        background-color: rgb(77,169,80);
    }

    .para-img {
        float: right;
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
    }

    .bg-contact {
        background: linear-gradient(rgba(44, 95, 45, 0.5), rgba(44, 95, 45,0.5)), url('/assets/img/contact-bg.svg');
    }

    .float-left {
        float: left !important;
    }

    .mr-20 {
        margin-right : 20px
    }

    .ml-20 {
        margin-left : 20px
    }

    .img-round-corner {
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
    }

    h4 {
        margin: 0;  
    }
    .color-block-5 {
        height: 200px;
        width: 200px;
    }
    .img-gradient{
        position:relative;
        display:inline-block;
    }
    .img-gradient:after {
        content:'';
        position:absolute;
        left:0; top:0;
        width:100%; height:100%;
        display:inline-block;
        background: -moz-linear-gradient(top, rgba(0,47,75,0.5) 0%, rgba(220, 66, 37, 0.5) 100%); /* FF3.6+ */
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(220, 66, 37, 0.5)), color-stop(100%,rgba(0,47,75,0.5))); /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* Opera 11.10+ */
        background: -ms-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* IE10+ */
        background: linear-gradient(to bottom, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002f4b', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
    }
    .img-gradient img{
        display:block;
    }

</style>