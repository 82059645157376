<template>
    <div>
        <section class="bg-forest">
            <MDBContainer class="p-5 bg-form">
                <MDBRow class="mb-3">
                    <MDBCol md="12" class="mx-auto text-light item-align-center">
                        <MDBCardText style="text-align : justify;" class="">
                            <h2 class="mb-4 text-light text-center">CAMERA TRAP BASED MONITORING CHANGING THE CONSERVATION LANDSCAPE OF PROTECTED AREAS: A STORY OF MANY NEW RECORDS FROM PROTECTED AREAS IN SHIMLA WILDLIFE DIVISION</h2>       
                            <p> 
                                A camera trap is a simple wildlife monitoring device widely used in forest areas and protected landscapes across the world to understand wildlife presence and monitor wildlife movement pattern. The device works on motion sensing technology and captures wildlife movement in front of the camera trap.
                                <br><br>
                                The Wildlife Division over the past couple of years took concerted efforts to increase the number of camera traps with the primary aim that every forest guard manning a beat has at least 1 camera trap to monitor the wildlife of that beat. In this way, the 7 protected areas across the districts of Solan, Sirmaur and Shimla had installed within them camera traps monitoring wildlife movement in these protected landscapes.
                                <br><br>
                                The records from camera trap provided great inferences to the Wildlife Wing with many new records from various protected areas. The new records include the camera trap capture of Royal Bengal tiger and Pangolin from Colonel Sher Jung National Park, Simbalbara in Paonta Sahib, Sambar Deer from Talra Wildlife Sanctuary, Wild Boar from Shimla Water Catchment, Koklass Pheasant from Chail Wildlife Sanctuary.
                            </p>  
                            
                            <img src="/assets/images/updates/2024/february/003/001.png" class="img-round-corner" style=" width : 100%; max-width : 100%;">
                            <p style="text-align : center"><small><i> Fig 1: New Records from various protected areas through camera trap monitoring</i></small></p>
                            
                            <p> 
                                These new wildlife records in various protected areas provide great insight into wildlife movement patters. Many of these records illustrate altitudinal shift in species and their habitat in a warming world associated with climate change. The sighting of Wild Boar in Shimla Water Catchment at an elevation of 2700 meters is indicative of one such altitudinal shift in species. Similar is the record of Sambar deer species from Talra Wildlife Sanctuary. 
                                <br><br>
                                The record of Pangolin from Colonel Sher Jung National Park is critical given it is one of the most trafficked species in wildlife crime world. The prospect of this record suggests how Colonel Sher Jung National Park can be a vital secure habitat for this species listed as ‘Vulnerable’ in IUCN Red List. Similarly, the record of Royal Bengal Tiger in Simbalbara National Park suggests a functional inter-state tiger corridor in the Shivalik Landscape connecting Rajaji Tiger Reserve and Kalesar National Park with Col. Sher Jung National Park, Simbalbara. 
                                <br><br>
                                The regular camera trap based monitoring of Protected Areas under Shimla Wildlife Division is giving key insights into wildlife movement and patterns. This will serve as crucial data for wildlife monitoring enabling management of these protected area landscapes for newer species and analyzing the change in habitat character temporally.
                            </p>  

                            <img src="/assets/images/updates/2024/february/003/3.png" class="img-round-corner" style=" width : 100%; max-width : 100%;">
                            <img src="/assets/images/updates/2024/february/003/4.png" class="img-round-corner" style=" width : 100%; max-width : 100%;">
                            <p style="text-align : center"><small><i> Fig 2: Camera Trap Images of Barking Deer (Chail WLS), Sambar Deer and Elephant (Simbalbara NP) and Common Leopard (Churdhar WLS)</i></small></p>
                            
                            <p> 
                                Apart from new records in protected areas, the camera trap based monitoring is also enabling recording of flagship and key stone species of wildlife in forest landscapes. This includes the Common Leopard, Himalayan Black Bear, Elephant, Sambar Deer, Barking Deer, Himalayan Ghoral in various protected areas. The camera trap records and further analysis of data can provide the occupancy , abundance maps of wildlife indicating the habitat correlation of species and giving spatial intelligence to wildlife area management. Regular camera trap monitoring and data collection can thus serve to create a key baseline data which can enable management and monitoring of these protected landscapes    
                            </p>  

                            


                            
                            

                        </MDBCardText>
                    </MDBCol>
                </MDBRow>                     
            </MDBContainer>
        </section>        
    </div>
</template>
<script>
  import { ref } from "vue";
  import { 
    MDBCol, MDBRow, MDBContainer,
    MDBInput,
    MDBCheckbox,
    MDBBtn,
    MDBTextarea,
    MDBModal,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBCarousel,
    MDBCardText,
    MDBCardImg, mdbRipple

  } from "mdb-vue-ui-kit";
  export default {
    name : "Updates_October_001",
    components: {
        MDBCol, MDBRow, MDBContainer,
        MDBInput,
        MDBCheckbox,
        MDBBtn,
        MDBTextarea,
        MDBModal,
        MDBModalHeader,
        MDBModalTitle,
        MDBModalBody,
        MDBModalFooter,
        MDBCarousel,
        MDBCardText,
        MDBCardImg
    },
    directives: {
      mdbRipple
    },
    setup() {
      const items1 = [
        {
          src: "/assets/images/contact-us.jpg",
          alt: "Shimla Wildlife Division",
        }
      ];
      const exampleModal = ref(false);
      const carousel1 = ref(0);
      return {
        exampleModal,
        items1,
        carousel1,
        form : {
            name : '',
            email : '',
            phone : '',
            query : '',
            city : '',
            newsletter : true
        },
        modal : {
            show : true,
            title : 'SUCCESS!!',
            body : 'Your query has been posted successfully! We will get back to you soon'
        }
      };
    },
    methods : {
        submitForm(){

            this.exampleModal = true;
        }
    }
  };
</script>
<style scoped>

    .bg-dark-green {
        background-color: rgb(77,169,80);
    }

    .para-img {
        float: right;
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
    }

    .bg-contact {
        background: linear-gradient(rgba(44, 95, 45, 0.5), rgba(44, 95, 45,0.5)), url('/assets/img/contact-bg.svg');
    }

    .float-left {
        float: left !important;
    }

    .mr-20 {
        margin-right : 20px
    }

    .ml-20 {
        margin-left : 20px
    }

    .bg-trago {
        background: linear-gradient(rgba(44, 95, 45, 0.9), rgba(44, 95, 45,0.9)), url('/assets/img/re_6.jpeg') center ;
    }

    .bg-forest {
        background: url('/assets/img/Untitled design-15.png') ;
        background-size: cover;
    }

    .img-round-corner {
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
    }

    .bg-form {
        background-color : rgb(66,136,121);
    }

    .form-title {

        border-left: 5px solid rgb(138,250,160);
        padding-left: 10px;
    }

    .form-color {
        color : #4F4F4F !important;
        border-color: transparent !important;
        background-color: white !important;
    }
    .update-container {
        overflow: hidden;        
    }
    .responsive-iframe {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

</style>