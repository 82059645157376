<template>
    <section class="bg-forest">
        <MDBContainer>
            <MDBRow class="p-2 pt-5 pb-5">
                <MDBCol md="12" xs="12" class="text-dark mx-auto">
                    <h4>Updates and Notifications</h4>
                    <MDBTable>
                        <thead>
                        <tr>
                            <th scope="col">Date</th>
                            <th scope="col">Type</th>
                            <th scope="col">Title</th>
                            <th scope="col">Link</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr @click="onSelectRow('2023-October-001')">
                            <th scope="row">30 October 2023</th>
                            <td>NEWS</td>
                            <td>Artistic Modification of Range Office Campus at Shimla Water Catchment</td>
                            <td style="color: blue;">Click here to read more</td>
                        </tr>    
                        <tr @click="onSelectRow('2023-October-002')">
                            <th scope="row">30 October 2023</th>
                            <td>NEWS</td>
                            <td>Eco Development: A New Beginning of Participatory Conservation at Shimla Water Catchment and Chail Wildlife Sanctuary</td>
                            <td style="color: blue;">Click here to read more</td>
                        </tr>   
                        <tr @click="onSelectRow('2023-October-003')">
                            <th scope="row">30 October 2023</th>
                            <td>NEWS</td>
                            <td>Recording the First Camera Trap Image of Royal Bengal Tiger in State of Himachal Pradesh</td>
                            <td style="color: blue;">Click here to read more</td>
                        </tr>   
                        <tr @click="onSelectRow('2023-August-001')">
                            <th scope="row">01 August 2023</th>
                            <td>NEWS</td>
                            <td>Operation of Renuka Ji Souvenir Shop by Women-led SHG</td>
                            <td style="color: blue;">Click here to read more</td>
                        </tr>
                        <tr @click="onSelectRow('2023-August-002')">
                            <th scope="row">01 August 2023</th>
                            <td>NEWS</td>
                            <td>Fodder Plot Development</td>
                            <td style="color: blue;">Click here to read more</td>
                        </tr>
                        <tr @click="onSelectRow('proactive-protection-measure-setting-up-fire-hidrant-in-tutikandi')">
                            <th scope="row">23 February 2024</th>
                            <td>NEWS</td>
                            <td>A Proactive Protection Measure: Setting up the Fire Hydrant Safety System in Rescue and Rehabilitation Centre, Tutikandi</td>
                            <td style="color: blue;">Click here to read more</td>
                        </tr>
                        <tr @click="onSelectRow('eco-development-program-at-churdhar-wls')">
                            <th scope="row">23 February 2024</th>
                            <td>NEWS</td>
                            <td>Eco Development Program at Churdhar Wildlife Sanctuary: A New Beginning of Participatory Conservation through Entry Point Activities</td>
                            <td style="color: blue;">Click here to read more</td>
                        </tr>
                        <tr @click="onSelectRow('camera-trap-based-monitoring-changing-the-conservation-landscape')">
                            <th scope="row">23 February 2024</th>
                            <td>NEWS</td>
                            <td>CAMERA TRAP BASED MONITORING CHANGING THE CONSERVATION LANDSCAPE OF PROTECTED AREAS: A STORY OF MANY NEW RECORDS FROM PROTECTED AREAS IN SHIMLA WILDLIFE DIVISION</td>
                            <td style="color: blue;">Click here to read more</td>
                        </tr>
                        <tr @click="onSelectRow('assets/images/updates/2024/february/Nature_Guides_Training.pdf')">
                            <th scope="row">23 February 2024</th>
                            <td>NEWS</td>
                            <td><b>Community Conservators</b> - A story of community involvement in conservation, initiating responsible eco-tourism and creating messengers of conservation from the local community at Renuka Ji
                            </td>
                            <td style="color: blue;">Click here to read more</td>
                        </tr>
                        <tr @click="onSelectRow('animal-exchange-between-hnp-kufri-zoo-shimla-and-pnhzp-darjeeling')">
                            <th scope="row">17 March 2024</th>
                            <td>NEWS</td>
                            <td><b>ANIMAL EXCHANGE BETWEEN HNP KUFRI ZOO, SHIMLA AND PNHZP, DARJEELING</b>
                            </td>
                            <td style="color: blue;">Click here to read more</td>
                        </tr>
                        <tr @click="onSelectRow('himachals-first-eco-bridge')">
                            <th scope="row">17 March 2024</th>
                            <td>NEWS</td>
                            <td><b>Himachal’s First Eco Bridge for Arboreal Animals at Chail Wildlife Sanctuary: An Experiment to overcome fragmented habitat landscapes</b>
                            </td>
                            <td style="color: blue;">Click here to read more</td>
                        </tr>
                        <tr @click="onSelectRow('triumph-in-the-wetlands')">
                            <th scope="row">17 March 2024</th>
                            <td>NEWS</td>
                            <td><b>A Triumph in the Wetlands: Successful Swamp Deer Breeding at Sri Renuka Ji Mini Zoo</b>
                            </td>
                            <td style="color: blue;">Click here to read more</td>
                        </tr>
                        </tbody>
                    </MDBTable>
                    
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    </section>
</template>

<script>

import { 
    MDBTable,
    MDBCol, MDBRow, MDBContainer, 
} from 'mdb-vue-ui-kit';
export default {
    name : 'Notifications',
    components: {
        MDBTable,
        MDBCol, MDBRow, MDBContainer,
    },
    methods : {
        onSelectRow(url){
            window.open(`/${url}`, '');

        },
    }
};
</script>

<style>

</style>