<template>
    <div>
        <MDBCarousel
            v-model="carousel1"
            :items="items1"
            fade
            :controls="false"
            :indicators="false"
        />
        <section >
            <MDBContainer class="p-5">
                <h4 class="mb-4 text-dark">History</h4>
                <MDBRow>
                    <MDBCol class="mb-2 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                Renuka Ji Mini zoo was initially established as a rescue centre for strayed and deserted wild animals in 1957. The first animal to the zoo was a male spotted deer named Moti. During the same time great animal lover Lt. Governor Hon’ble Raja Bhadri visited Renukaji and ordered to arrange a female chital for pairing and that was the real foundation stone to the establishment of Renukaji zoo. With the passage of time wild animals i.e. Sambar, Chital, Barking deer, Black buck and Nilgai were introduced to the zoo. The animals in zoo started breeding and multiplying and gradually the Mini Zoo became in important centre for ex situ conservation of Shivalik Himalayan Fauna.     
                            </p>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>
                <h4 class="mb-4 text-dark">Location & Visitor Details</h4>
                <MDBRow>
                    <MDBCol class="mb-2 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                <span class="para-img float-left mr-20" style="text-align : right; margin-right: 30px;">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3433.9670253852073!2d77.46406471552504!3d30.606693298746308!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390f13820557c4c3%3A0x43bdd48cf30d743a!2sMini%20Zoo!5e0!3m2!1sen!2sin!4v1681263573067!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                    <!-- <img src="/assets/images/hnp-visitor.png" class="img-round-corner" style="max-height : 375px; width : auto; max-width : 100%; margin-right : 20px">
                                    <br>
                                    <small><i>- Visit of School Children at HNP Kufri</i></small> -->
                                </span>
                                The Renuka Ji Mini Zoo being located adjacent to Ramsar Site Renuka Ji Wetland and famous Renuka Ji Mata Temple ,a large number of visitors visit the zoo every year particularly during the Mela. The annual footfall of the Renuka Ji Mini Zoo is around 1 lakh visitors and is seeing a steady increase every year.
                                <br><br>
                                <ol>
                                    <li>
                                        Zoo timings : 
                                        <ul>
                                            <li>Winter - 09:30 Hrs. to 17:30 Hrs.</li>
                                            <li>Summer - 09:00 Hrs. to 18:30 Hrs.</li>
                                            <li><i>Off Day - Monday</i></li>
                                        </ul>
                                    </li>
                                    <li>Entry Charges   : <i>FREE</i></li>
                                    <li>Nearest Town    : <i>Dadahu</i></li>
                                    <li>Best time to visit    : <i>October-April</i></li>
                                </ol>
                                The mini zoo is situated at Renuka Ji which is accessible from the Dehradun-Nahan-Kumarhatti road through a diversion 10 kms before Nahan while travelling from Solan. The nearest airport is Chandigarh which is about 130 kms and nearest rail head is also at Chandigarh at a distance of about 120kms.
                            </p>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>
                <h4 class="mb-4 text-dark">Mission of Zoo</h4>
                <MDBRow class="">
                    <MDBCol class="mb-2 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                <ul>
                                    <li>Develop amongst visitors empathy towards wild animals and motivate them to support the cause of conservation of wildlife</li>
                                    <br><li>Develop amongst visitors awareness on Shivalik Himalayan Flora and Fauna</li>
                                    <br><li>Develop amongst the visitors an understanding about the ecological linkages with the life supporting processes of nature and living in harmony with nature</li>
                                    <br><li>Assisting the national efforts for conservation of the Shivalik Himalayan eco-system by ex-situ conservation, conservation education and research to augment these conservation initiatives</li>
                                </ul>
                            </p>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </section>
        <section >
            <MDBContainer class="p-5">
                <h5 class="text-center ">What you can see here?</h5>
                <br>
                <MDBContainer fluid>
                    <MDBRow >
                        <MDBCol md="12" xs="12" class="mx-auto text-center">
                            <vue-horizontal responsive>
                                <section v-for="item in photos" :key="item.title">
                                    <MDBCard style="margin-right:30px">
                                        <a v-mdb-ripple="{ color: 'light' }">
                                            <MDBCardImg :src="item.img" top :alt="item.name" class="img-cut-edge" style="max-height :245px"/>
                                        </a>
                                        <MDBCardBody>                                            
                                            <MDBCardText style="text-align: center">
                                                {{ item.name }}
                                            </MDBCardText>
                                        </MDBCardBody>
                                    </MDBCard>
                                </section>
                            </vue-horizontal>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>                
            </MDBContainer>
        </section>
        <section >
            <MDBContainer class="">
                <h5 class="text-center ">Visitor Facilities</h5>
                <br>
                <MDBRow >
                    <MDBCol md="6" xs="6" class="mx-auto text-center">
                        <MDBCardImg src="/assets/images/renuka-zoo-5.png" top class="img-cut-edge"/>
                    </MDBCol>  
                    <MDBCol md="6" xs="6" class="mx-auto text-center">
                        <MDBCardImg src="/assets/images/renuka-zoo-6.png" top class="img-cut-edge"/>
                    </MDBCol>                   
                </MDBRow>                
            </MDBContainer>
        </section>
        <section >
            <MDBContainer class="p-5">
                <MDBRow >
                    <MDBCol md="6" xs="6" class="mx-auto text-center">
                        <h6 class="text-center ">Zoo Education and Awareness</h6>
                        <MDBCardImg src="/assets/images/renuka-zoo-7.png" top class="img-cut-edge"/>
                    </MDBCol>  
                    <MDBCol md="6" xs="6" class="mx-auto text-center">
                        <h6 class="text-center ">Competitions held during Wildlife Week 2021</h6>
                        <MDBCardImg src="/assets/images/renuka-zoo-8.png" top class="img-cut-edge"/>
                    </MDBCol>                   
                </MDBRow>                
            </MDBContainer>
        </section>
        <!-- <section class="bg-contact p-5">
            <MDBContainer>
                
                <MDBRow>
                    <MDBCol md="6" class="mx-auto mb-5">
                        <MDBCard text="center">
                            <MDBCardHeader class="bg-dark-green text-light"><h3>ACCOMODATION</h3></MDBCardHeader>
                            <MDBCardBody>
                                <MDBCardText>
                                    Forest Rest House at Kufri
                                </MDBCardText>                                
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol md="6" class="mx-auto">
                        <MDBCard text="center">
                            <MDBCardHeader class="bg-dark-green text-light"><h3>CONTACT OFFICE</h3></MDBCardHeader>
                            <MDBCardBody>
                                <MDBCardText>
                                    Range Forest Officer , 
                                    <br>
                                    ----
                                    <br>
                                    Telephone: xxxx xxxx.
                                </MDBCardText>                                
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </section> -->
        
    </div>
</template>
<script>
  import { ref } from "vue";
  import VueHorizontal from "vue-horizontal";
  import { 
    MDBCarousel,
    MDBCol, MDBRow, MDBContainer,
    MDBCardText,
    MDBCard, MDBCardHeader, MDBCardBody, MDBCardTitle, MDBCardImg, mdbRipple
  } from "mdb-vue-ui-kit";
  export default {
    name : "Daranghati",
    components: {
      MDBCarousel,
      MDBCol, MDBRow, MDBContainer,
      MDBCardText, VueHorizontal,
      MDBCard, MDBCardHeader, MDBCardBody, MDBCardTitle, MDBCardImg
    },
    directives: {
      mdbRipple
    },
    setup() {
      const items1 = [
        {
          src: "/assets/images/renuka-mini-zoo-banner.jpg",
          alt: "Renuka Ji Mini Zoo",
          caption : ""
        }
      ];
      const carousel1 = ref(0);
      return {
        items1,
        carousel1,
        photos: [
          {
            img : '/assets/images/renuka-zoo-1.png',
            name : 'Sambar'
          },
          {
            img : '/assets/images/renuka-zoo-2.png',
            name : 'Leopard'
          },
          {
            img : '/assets/images/renuka-zoo-3.png',
            name : 'Himalayan Black Bear'
          },
          {
            img : '/assets/images/renuka-zoo-4.png',
            name : 'Barking Deer'
          }
        ]
      };
    }
  };
</script>
<style scoped>

    /* GRADIENTS */
    .winter-neva-gradient {
        background-image: linear-gradient(120deg,#a1c4fd,#c2e9fb);
    }

    .deep-blue-gradient {
        background-image: linear-gradient(120deg,#e0c3fc,#8ec5fc);
    }
    /* GRADIENTS END */

    .gradient-text {
        line-height: 200px; 
        font-size : 24px; 
        letter-spacing : 2px
    }

    .z-depth-1-half {
        -webkit-box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)!important;
        box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)!important;
    }

    .bg-dark-green {
        background-color: rgb(77,169,80);
    }

    .para-img {
        float: right;
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
    }

    .bg-contact {
        background: linear-gradient(rgba(44, 95, 45, 0.5), rgba(44, 95, 45,0.5)), url('/assets/img/contact-bg.svg');
    }

    .float-left {
        float: left !important;
    }

    .mr-20 {
        margin-right : 20px
    }

    .ml-20 {
        margin-left : 20px
    }

    .img-round-corner {
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
    }

    h4 {
        margin: 0;  
    }
    .color-block-5 {
        height: 200px;
        width: 200px;
    }
    .img-gradient{
        position:relative;
        display:inline-block;
    }
    .img-gradient:after {
        content:'';
        position:absolute;
        left:0; top:0;
        width:100%; height:100%;
        display:inline-block;
        background: -moz-linear-gradient(top, rgba(0,47,75,0.5) 0%, rgba(220, 66, 37, 0.5) 100%); /* FF3.6+ */
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(220, 66, 37, 0.5)), color-stop(100%,rgba(0,47,75,0.5))); /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* Opera 11.10+ */
        background: -ms-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* IE10+ */
        background: linear-gradient(to bottom, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002f4b', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
    }
    .img-gradient img{
        display:block;
    }

</style>