<template>
    <div>
        <MDBContainer fluid>
            <MDBRow>
                <MDBCol md="12">
                    <img src="/assets/images/coming-soon.jpg" class="para-img" style="width : 100%">
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    </div>
</template>
<script>
  import { ref } from "vue";
  import { 
    MDBCarousel,
    MDBCol, MDBRow, MDBContainer,
    MDBCardText,
    MDBCard, MDBCardBody, MDBCardTitle,MDBCardImg, MDBBtn,
    mdbRipple
  } from "mdb-vue-ui-kit";
  export default {
    components: {
      MDBCarousel,
      MDBCol, MDBRow, MDBContainer,
      MDBCardText,
      MDBCard, MDBCardBody, MDBCardTitle,MDBCardImg, MDBBtn,
    },
    directives: {
        mdbRipple
    },
    setup() {
      const items1 = [
        {
          src: "/assets/images/about-us-banner.jpg",
          alt: "...",
        }
      ];
      const carousel1 = ref(0);
      return {
        items1,
        carousel1,
      };
    }
  };
</script>
<style scoped>

    /* GRADIENTS */
    .winter-neva-gradient {
        background-image: linear-gradient(120deg,#a1c4fd,#c2e9fb);
    }

    .deep-blue-gradient {
        background-image: linear-gradient(120deg,#e0c3fc,#8ec5fc);
    }
    /* GRADIENTS END */

    .gradient-text {
        line-height: 200px; 
        font-size : 24px; 
        letter-spacing : 2px
    }

    .z-depth-1-half {
        -webkit-box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)!important;
        box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)!important;
    }

    .bg-dark-green {
        background-color: rgb(77,169,80);
    }

    .para-img {
        float: right;
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
        margin: 0px 0px 15px 20px;
    }

    h4 {
        margin: 0;  
    }
    .color-block-5 {
        height: 200px;
        width: 200px;
    }
    .img-gradient{
        position:relative;
        display:inline-block;
    }
    .img-gradient:after {
        content:'';
        position:absolute;
        left:0; top:0;
        width:100%; height:100%;
        display:inline-block;
        background: -moz-linear-gradient(top, rgba(0,47,75,0.5) 0%, rgba(220, 66, 37, 0.5) 100%); /* FF3.6+ */
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(220, 66, 37, 0.5)), color-stop(100%,rgba(0,47,75,0.5))); /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* Opera 11.10+ */
        background: -ms-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* IE10+ */
        background: linear-gradient(to bottom, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002f4b', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
    }
    .img-gradient img{
        display:block;
    }

</style>