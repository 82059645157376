<template>
    <div>
        <section class="bg-forest">
            <MDBContainer class="p-5 bg-form">
                <MDBRow class="mb-3">
                    <MDBCol md="12" class="mx-auto text-light item-align-center">
                        <MDBCardText style="text-align : justify;" class="">
                            <h2 class="mb-4 text-light text-center">Operation of Renuka Ji Souvenir Shop by Women-led SHG</h2>
                            <p>
                                <span class="para-img ml-20" style="text-align : right">
                                    <img src="/assets/images/updates/2023/august/001/4.png" class="img-round-corner" style="max-height : 375px; width : auto; max-width : 100%;">
                                    <br>
                                    <!-- <small><i>- Administrative map of Shimla Water Catchment Wildlife Sanctuary</i></small> -->
                                </span>
                                In order to generate livelihood for local women the souvenir shop under Renuka Ji Wildlife Range has been leased to a local women’s Self-Help Group, named Maseeha. This is aimed at financial empowerment of women as the group members earn revenue on the sale of departmental souvenirs pertaining to wildlife. 
                                <br><br>
                                Along with departmental souvenirs they sell their own products appertaining to Himachali culture.  Nature-based products, such as, jute bags, showpieces made up from Khajur leaves, etc., are prioritized to generate wildlife awareness and promote eco-friendly lifestyle. They also sell organic tea, fruit juices and other food products which are made from organically produced crops or from sustainably harvested forest produce.
                                <br><br>                              
                            </p>
                            <br><br> <br>
                            <p>
                                <span class="para-img float-left mr-20" style="text-align : right">
                                    <img src="/assets/images/updates/2023/august/001/5.jpeg" class="img-round-corner" style="max-height : 345px; width : auto; max-width : 100%;">
                                    <br>
                                    <!-- <small><i>- Administrative map of Shimla Water Catchment Wildlife Sanctuary</i></small> -->
                                </span>
                                <span class="para-img float-right ml-20" style="text-align : right">
                                    <img src="/assets/images/updates/2023/august/001/6.jpeg" class="img-round-corner" style="max-height : 260px; width : auto; max-width : 100%;">
                                    <br>
                                    <!-- <small><i>- Administrative map of Shimla Water Catchment Wildlife Sanctuary</i></small> -->
                                </span>
                                Some dried medicinal herbs such as Tenospora cordifolia and Xanthoxylem armatum, which are known to have therapeutic properties, are also being sold. The group is planning to expand their product range by selling ready-to-eat local cuisine. These food items will be prepared by the group members at home and will be sold and served in eco-friendly packaging and plates.
                                <br><br>
                                By providing these women with entrepreneurial opportunity the department envisages to support underprivileged families as most of the members of this group come from socially disadvantaged section of the society. Furthermore, the fact that these women come from rural areas places them in the front line in terms of their knowledge of the local environment and biodiversity and the potential threats faced by them and hence, their engagement in the operation of this souvenir shop can be pivotal in spreading the message of wildlife conservation.
                                <br><br>    
                            </p>                            
                        </MDBCardText>
                        <MDBContainer fluid class="mt-5">
                            <MDBRow class="mb-2">
                                <MDBCol md="3" class="mx-auto text-center">
                                    <a v-mdb-ripple="{ color: 'light' }">
                                        <MDBCardImg src="/assets/images/updates/2023/august/001/1.png" style="max-width :100%; max-height: 350px; width: auto;"/>
                                    </a>
                                </MDBCol>  
                                <MDBCol md="3" class="mx-auto text-center">
                                    <a v-mdb-ripple="{ color: 'light' }">
                                        <MDBCardImg src="/assets/images/updates/2023/august/001/2.png" style="max-width :100%; max-height: 350px; width: auto;"/>
                                    </a>
                                </MDBCol>           
                                <MDBCol md="3" class="mx-auto text-center">
                                    <a v-mdb-ripple="{ color: 'light' }">
                                        <MDBCardImg src="/assets/images/updates/2023/august/001/3.png" style="max-width :100%; max-height: 350px; width: auto;"/>
                                    </a>
                                </MDBCol>   
                            </MDBRow>  
                        </MDBContainer>
                    </MDBCol>
                    <MDBCol md="12">
                        <p style="text-align: right" class="text-light">
                            <i>Content and Picture Credit: Sourabh Pundir, Assitant Zoo Biologist, Renuka Ji Mini Zoo</i>
                        </p>
                    </MDBCol>
                </MDBRow>                     
            </MDBContainer>
        </section>        
    </div>
</template>
<script>
  import { ref } from "vue";
  import { 
    MDBCol, MDBRow, MDBContainer,
    MDBInput,
    MDBCheckbox,
    MDBBtn,
    MDBTextarea,
    MDBModal,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBCarousel,
    MDBCardText,
    MDBCardImg, mdbRipple

  } from "mdb-vue-ui-kit";
  export default {
    name : "Updates_August_001",
    components: {
        MDBCol, MDBRow, MDBContainer,
        MDBInput,
        MDBCheckbox,
        MDBBtn,
        MDBTextarea,
        MDBModal,
        MDBModalHeader,
        MDBModalTitle,
        MDBModalBody,
        MDBModalFooter,
        MDBCarousel,
        MDBCardText,
        MDBCardImg
    },
    directives: {
      mdbRipple
    },
    setup() {
      const items1 = [
        {
          src: "/assets/images/contact-us.jpg",
          alt: "Shimla Wildlife Division",
        }
      ];
      const exampleModal = ref(false);
      const carousel1 = ref(0);
      return {
        exampleModal,
        items1,
        carousel1,
        form : {
            name : '',
            email : '',
            phone : '',
            query : '',
            city : '',
            newsletter : true
        },
        modal : {
            show : true,
            title : 'SUCCESS!!',
            body : 'Your query has been posted successfully! We will get back to you soon'
        }
      };
    },
    methods : {
        submitForm(){

            this.exampleModal = true;
        }
    }
  };
</script>
<style scoped>

    .bg-dark-green {
        background-color: rgb(77,169,80);
    }

    .para-img {
        float: right;
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
    }

    .bg-contact {
        background: linear-gradient(rgba(44, 95, 45, 0.5), rgba(44, 95, 45,0.5)), url('/assets/img/contact-bg.svg');
    }

    .float-left {
        float: left !important;
    }

    .mr-20 {
        margin-right : 20px
    }

    .ml-20 {
        margin-left : 20px
    }

    .bg-trago {
        background: linear-gradient(rgba(44, 95, 45, 0.9), rgba(44, 95, 45,0.9)), url('/assets/img/re_6.jpeg') center ;
    }

    .bg-forest {
        background: url('/assets/img/Untitled design-15.png') ;
        background-size: cover;
    }

    .img-round-corner {
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
    }

    .bg-form {
        background-color : rgb(66,136,121);
    }

    .form-title {

        border-left: 5px solid rgb(138,250,160);
        padding-left: 10px;
    }

    .form-color {
        color : #4F4F4F !important;
        border-color: transparent !important;
        background-color: white !important;
    }
    .update-container {
        position: relative;
        overflow: hidden;
        padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
    }
    .responsive-iframe {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

</style>