<template>
    <div>
        <section class="bg-forest">
            <MDBContainer class="p-5 bg-form">
                <MDBRow class="mb-3">
                    <MDBCol md="12" class="mx-auto text-light item-align-center">
                        <MDBCardText style="text-align : justify;" class="">
                            <h2 class="mb-4 text-light text-center">Recording the First Camera Trap Image of Royal Bengal Tiger in State of Himachal Pradesh</h2>
                            <p>
                                <span class="para-img ml-20" style="text-align : right">
                                    <img src="/assets/images/updates/2023/october/001/8.jpg" class="img-round-corner" style="max-height : 375px; width : auto; max-width : 100%;">
                                    <br>
                                    <!-- <small><i>- Administrative map of Shimla Water Catchment Wildlife Sanctuary</i></small> -->
                                </span>
                                For long, it was just a story, one of hearsay and lacking veracity, which could have been
                                mistaken for the rumor mill. The presence of tiger in the Colonel Sher Jung National Park,
                                Simbalbara was part of the legend penned down by Sher Jung himself in his book where he
                                speaks of the tiger that crossed the Yamuna and left its print in sand at Paonta. It was also part of
                                many stories shared by locals, forest officials and wildlife enthusiasts so much so that it was
                                difficult to decipher the truth from the box of myths and folklores.
                                <br><br>                 
                                Putting an end to this, was the first ever camera trap recorded image of the Tiger from Colonel
                                Sher Jung National Park, Simbalbara on February 19 th 2023. Flashback to 14 th January of 2023:
                                when we received information that a suspected tiger pugmark was spotted in the private fields
                                adjacent to the national park. The field staff was immediately alerted and they rushed to the spot
                                on the same date collecting the pug mark impression on plaster of paris. The measurements of
                                the pug mark were taken by the Range Officer and sub ordinate staff and the length of 6.25” and
                                width 5” indicated and confirmed that it could only be an adult tiger      
                                <br><br>
                                Our next logical target was to make an all out effort to record the camera trap image of tiger in
                                the protected area. In order to achieve this, we needed to gather more and more indirect
                                evidences to understand its patterns of movement, to see if it is still within out habitat range and
                                if it is utilizing our forest patches. This could only be achieved with intensive field patrolling and
                                frequent periodic sign survey which was carried out by entire range level staff of Simbalbara
                                under the leadership of Range officer Sh. Surender Singh with great enthusiasm and motivation.       
                            </p>
                            <br><br>
                            <p>
                                <span class="para-img float-left mr-20" style="text-align : right">
                                    <img src="/assets/images/updates/2023/october/001/9.jpg" class="img-round-corner" style="max-height : 345px; width : auto; max-width : 100%;">
                                    <br>
                                    <!-- <small><i>- Administrative map of Shimla Water Catchment Wildlife Sanctuary</i></small> -->
                                </span>
                                <span class="para-img float-right ml-20" style="text-align : right">
                                    <img src="/assets/images/updates/2023/october/001/4.jpg" class="img-round-corner" style="max-height : 260px; width : auto; max-width : 100%;">
                                    <br>
                                    <!-- <small><i>- Administrative map of Shimla Water Catchment Wildlife Sanctuary</i></small> -->
                                </span>
                                In the days that followed, the range staff gathered numerous indirect evidences including
                                pug marks of the tiger, scat of the tiger on 4 separate occasions. The indirect evidences were
                                mapped by collecting the geo coordinates and the pattern of movement was identified. This gave
                                us an idea of the movement of the tiger and its utilization of the Colonel Sher Jung habitat
                                complex from the west of the national park to the central and northern parts concentrated along
                                the khalas (streams) which run comparatively dry during the winters.
                                <br><br>    
                                We decided to maximize our camera trap resources from the available ones at hand. The
                                camera traps from adjoining ranges were also brought in for this. A consultation was also done
                                with staff from Rajaji tiger reserve on the possible movement pattern of the tiger and where to
                                best place the camera traps. Based on the likelihood of sighting on the basis of previous recorded
                                indirect evidences, availability of water and intersection of paths, 6 camera traps were installed
                                in the national park area which spreads across 27 sq km.
                                <br><br>
                                The first ever camera trap record of the tiger was captured on Feb 19 th 2023 making it the
                                official confirmation of the many here say stories and folklores once told. The recording of the
                                image was a historic feat which could be achieved only due to the passionate and sincere
                                working of the range staff ably led by the Range Forest Officer. It is also significant that despite
                                so many years of hearsay, it was a solely departmental effort that led to the achievement of
                                recording of a tiger without any significant assistance by other technical organization. This is a
                                matter of great pride to HP Wildlife Wing and also indicates the potential that the forest and wildlife staff holds given their understanding of area and the vast experience that they
                                accumulate with time.
                            </p>  
                            <br><br>

                        </MDBCardText>
                    </MDBCol>
                </MDBRow>                     
            </MDBContainer>
        </section>        
    </div>
</template>
<script>
  import { ref } from "vue";
  import { 
    MDBCol, MDBRow, MDBContainer,
    MDBInput,
    MDBCheckbox,
    MDBBtn,
    MDBTextarea,
    MDBModal,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBCarousel,
    MDBCardText,
    MDBCardImg, mdbRipple

  } from "mdb-vue-ui-kit";
  export default {
    name : "Updates_October_001",
    components: {
        MDBCol, MDBRow, MDBContainer,
        MDBInput,
        MDBCheckbox,
        MDBBtn,
        MDBTextarea,
        MDBModal,
        MDBModalHeader,
        MDBModalTitle,
        MDBModalBody,
        MDBModalFooter,
        MDBCarousel,
        MDBCardText,
        MDBCardImg
    },
    directives: {
      mdbRipple
    },
    setup() {
      const items1 = [
        {
          src: "/assets/images/contact-us.jpg",
          alt: "Shimla Wildlife Division",
        }
      ];
      const exampleModal = ref(false);
      const carousel1 = ref(0);
      return {
        exampleModal,
        items1,
        carousel1,
        form : {
            name : '',
            email : '',
            phone : '',
            query : '',
            city : '',
            newsletter : true
        },
        modal : {
            show : true,
            title : 'SUCCESS!!',
            body : 'Your query has been posted successfully! We will get back to you soon'
        }
      };
    },
    methods : {
        submitForm(){

            this.exampleModal = true;
        }
    }
  };
</script>
<style scoped>

    .bg-dark-green {
        background-color: rgb(77,169,80);
    }

    .para-img {
        float: right;
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
    }

    .bg-contact {
        background: linear-gradient(rgba(44, 95, 45, 0.5), rgba(44, 95, 45,0.5)), url('/assets/img/contact-bg.svg');
    }

    .float-left {
        float: left !important;
    }

    .mr-20 {
        margin-right : 20px
    }

    .ml-20 {
        margin-left : 20px
    }

    .bg-trago {
        background: linear-gradient(rgba(44, 95, 45, 0.9), rgba(44, 95, 45,0.9)), url('/assets/img/re_6.jpeg') center ;
    }

    .bg-forest {
        background: url('/assets/img/Untitled design-15.png') ;
        background-size: cover;
    }

    .img-round-corner {
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
    }

    .bg-form {
        background-color : rgb(66,136,121);
    }

    .form-title {

        border-left: 5px solid rgb(138,250,160);
        padding-left: 10px;
    }

    .form-color {
        color : #4F4F4F !important;
        border-color: transparent !important;
        background-color: white !important;
    }
    .update-container {
        overflow: hidden;        
    }
    .responsive-iframe {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

</style>