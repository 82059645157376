<template>
    <div>
        <section class="bg-forest">
            <MDBContainer class="p-5 bg-form">
                <MDBRow class="mb-3">
                    <MDBCol md="12" class="mx-auto text-light item-align-center">
                        <MDBCardText style="text-align : justify;" class="">
                            <h2 class="mb-4 text-light text-center">A Triumph in the Wetlands: Successful Swamp Deer Breeding at Sri Renuka Ji Mini Zoo</h2>       
                            <h4 class="mb-4 text-light">Introduction</h4>
                            <p>                                  
                                Swamp deer (Cervus duvauceli), also known as the barasingha, are a vulnerable species native to the Indian subcontinent. The IUCN Red List of Threatened Species categorizes the swamp deer as Vulnerable. Breeding them successfully in captivity requires meticulous planning, veterinary care, and habitat management. The successful breeding of swamp deer (Rucervus duvaucelii) at our mini zoo stands as a testament to the effectiveness of meticulous planning, veterinary care, habitat improvement, and collaborative efforts.
                                <br><br>
                                The journey, however, wasn't without its challenges. Swamp deer have specific habitat requirements and can be sensitive to stress. To create an optimal breeding environment, we implemented several key measures:
    
                            </p> 
                            <h4 class="mb-4 text-light">Habitat Enhancements</h4>
                            <p>                                  
                                <ul>
                                    <li><b>Replicating their natural wetland ecosystem:</b> Enclosure has been designed with shallow pools, marshy sections, and a variety of plants that mimics their natural habitat. This created lots of room for social interactions, territorial behaviours, and foraging.
                                    </li><li><b>Shelter and protection:</b> Strong shelters have been supplied to provide stress relief from inclement weather. Additionally, all of the enclosed area has been gated off with fencing and limits, and trees have been looped around to prevent predators like leopards from entering. 
                                    </li><li><b>Water Management:</b> Regular maintenance of water sources ensured adequate hydration and supported breeding activities.
                                    </li><li><b>Vegetation Diversity:</b> This encouraged natural browsing behavior.
                                    </li><li><b>Providing enrichment activities:</b> There were items such as branches, logs, and mud mounds to encourage the deer to engage in their natural behaviours and to breed.
                                        </li>

                                </ul>
                            </p> 
                            <h4 class="mb-4 text-light">Breeding Management</h4>
                            <p>
                                <span class="para-img float-right" style="text-align : center; margin-left: 40px !important;">
                                    <img src="/assets/images/updates/2024/march/003/image001.png" class="img-round-corner" style="max-height : 375px; width : auto; max-width : 100%;">
                                    <br>
                                    <!-- <small><i>- Figure illustrating the fragmentation in sanctuary owing to roads</i></small> -->
                                </span>  
                                <ul>
                                    <li><b>Minimizing stress:</b> We minimized disturbances during the breeding season and created a tranquil environment to encourage natural breeding behaviors.
                                    </li><li><b>Close monitoring:</b> The team closely monitored the herd throughout the breeding season, providing immediate assistance if needed.
                                    </li><li><b>Minimizing Human Interaction:</b> It was decided to minimise human influence in the deer enclosure. The deer's natural behaviour is minimised and specific observation areas are present to allow them to reproduce and raise their young without unnecessary stress.
                                    </li>
                                </ul>
                            </p>

                            <h4 class="mb-4 text-light">Veterinary Care</h4>
                            <p>
                                <span class="para-img float-left" style="text-align : center; margin-right: 40px !important;">
                                    <img src="/assets/images/updates/2024/march/003/image002.png" class="img-round-corner" style="max-height : 375px; width : auto; max-width : 100%;">
                                    <br>
                                    <!-- <small><i>- Figure illustrating the fragmentation in sanctuary owing to roads</i></small> -->
                                </span>  
                                <ul>
                                    <li><b>Nutrition:</b> Proper nutrition is vital for successful breeding. We provided a balanced diet rich in essential nutrients. We Provided a healthy, well-balanced diet that meets the unique requirements of swamp deer. In order to guarantee that they obtain all the nutrients required for optimum health and reproduction, this meant providing a mix of roughages and concentrates with tailored minerals and vitamins.
                                    </li><li><b>Parasite control:</b> Proactive parasitic control measures safeguarded the deer's health and prevented them from various diseases.
                                    </li><li><b>Routine Health Checks:</b> The deer were regularly monitored and treated by veterinarian to protect them from possible illnesses. By using preventative measures, the herd's general health was preserved and breeding conditions were improved.
                                    </li><li><b>Stress Management:</b> Minimizing stress is critical. We created a calm environment, avoiding unnecessary disturbances during breeding seasons.
                                    </li>                                    
                                </ul>
                            </p>
                            <h4 class="mb-4 text-light">Precautions Taken</h4>
                            <p>
                                To reduce the possibility of disease transmission, the Mini Zoo maintains strict biosecurity procedures. Proper attention to reproductive monitoring, environmental enrichment, proper nutrition and health management resulted in successful breeding and birth. 
                            </p>
                            <h4 class="mb-4 text-light">Conclusion</h4>
                            <p>
                                The continued scientific management and veterinary care for Swamp deers have enabled Renuka Ji Mini Zoo to enlarge the stock of this vulnerable species from a pair to 5 numbers of Barasinghas. In future, it is hoped that another blood line can be added to the mini zoo to build genetic and stock diversity.
                                <br><br>
                                This achievement serves as a testament to the importance of responsible animal management practices in Zoos. However, our work doesn't end here. We remain committed to providing exceptional care for our deer herd and contributing to broader conservation efforts. Through meticulous planning, veterinary expertise, and habitat management, our mini zoo achieved successful swamp deer breeding. These efforts contribute to the conservation of this magnificent species.
                            </p>
                            


                            
                            <p style="text-align : right">
                                <b> ~ Content Credit: 
                                    <br>Dr. Kartik Chaudhary
                                    <br>Veterinary Officer, Renuka Ji Mini Zoo
                                </b>
                            </p>

                            
                            
                            


                            
                            

                        </MDBCardText>
                    </MDBCol>
                </MDBRow>                     
            </MDBContainer>
        </section>        
    </div>
</template>
<script>
  import { ref } from "vue";
  import { 
    MDBCol, MDBRow, MDBContainer,
    MDBInput,
    MDBCheckbox,
    MDBBtn,
    MDBTextarea,
    MDBModal,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBCarousel,
    MDBCardText,
    MDBCardImg, mdbRipple

  } from "mdb-vue-ui-kit";
  export default {
    name : "Updates_October_001",
    components: {
        MDBCol, MDBRow, MDBContainer,
        MDBInput,
        MDBCheckbox,
        MDBBtn,
        MDBTextarea,
        MDBModal,
        MDBModalHeader,
        MDBModalTitle,
        MDBModalBody,
        MDBModalFooter,
        MDBCarousel,
        MDBCardText,
        MDBCardImg
    },
    directives: {
      mdbRipple
    },
    setup() {
      const items1 = [
        {
          src: "/assets/images/contact-us.jpg",
          alt: "Shimla Wildlife Division",
        }
      ];
      const exampleModal = ref(false);
      const carousel1 = ref(0);
      return {
        exampleModal,
        items1,
        carousel1,
        form : {
            name : '',
            email : '',
            phone : '',
            query : '',
            city : '',
            newsletter : true
        },
        modal : {
            show : true,
            title : 'SUCCESS!!',
            body : 'Your query has been posted successfully! We will get back to you soon'
        }
      };
    },
    methods : {
        submitForm(){

            this.exampleModal = true;
        }
    }
  };
</script>
<style scoped>

    .bg-dark-green {
        background-color: rgb(77,169,80);
    }

    .para-img {
        float: right;
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
    }

    .bg-contact {
        background: linear-gradient(rgba(44, 95, 45, 0.5), rgba(44, 95, 45,0.5)), url('/assets/img/contact-bg.svg');
    }

    .float-left {
        float: left !important;
    }

    .mr-20 {
        margin-right : 20px
    }

    .ml-20 {
        margin-left : 20px
    }

    .bg-trago {
        background: linear-gradient(rgba(44, 95, 45, 0.9), rgba(44, 95, 45,0.9)), url('/assets/img/re_6.jpeg') center ;
    }

    .bg-forest {
        background: url('/assets/img/Untitled design-15.png') ;
        background-size: cover;
    }

    .img-round-corner {
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
    }

    .bg-form {
        background-color : rgb(66,136,121);
    }

    .form-title {

        border-left: 5px solid rgb(138,250,160);
        padding-left: 10px;
    }

    .form-color {
        color : #4F4F4F !important;
        border-color: transparent !important;
        background-color: white !important;
    }
    .update-container {
        overflow: hidden;        
    }
    .responsive-iframe {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

</style>