import { createRouter, createWebHistory } from 'vue-router'
import Header from '../components/Header.vue';
import Footer from '../components/Footer.vue';

//Pages
import Home from '../views/Home.vue'
import AboutDivision from '../views/AboutDivision.vue'
import Notifications from '../views/Notifications.vue'
import Publications from '../views/Publications.vue'
import ContactUs from '../views/Contact.vue'
import hnp_zoo from '../views/zoos/HNP'
import simbalbara_np from '../views/sanctuaries/simbalbara.vue';
import majathal_wls from '../views/sanctuaries/majathal.vue';
import renukaji_wls from '../views/sanctuaries/renuka-ji'
import catchment_wls from '../views/sanctuaries/catchment';
import churdar_wls from '../views/sanctuaries/churdar';
import chail_wls from '../views/sanctuaries/chail';
import talra_wls from '../views/sanctuaries/talra';
import monkey_rescue from '../views/zoos/monkey-rescue';
import rescue_rehab from '../views/zoos/rescue-rehab-centre';
import renuka_mini_zoo from '../views/zoos/renuka-ji-mini-zoo';
import bird_park from '../views/zoos/himalayan-bird-park';
import breeding_center from '../views/zoos/breeding-center';
import coming_soon from '../views/ComingSoon'
import feedback_zoo from '../views/FeedbackZoo'
import feedback_resthouse from '../views/FeedbackResthouse'
import kufri_frh from '../views/frh/kufri-frh';
import renuka_frh from '../views/frh/renukaji-frh';
import simbalbara_frh from '../views/frh/simbalbara-frh';
import chail_frh from '../views/frh/chail-frh'
import amargargh_hut from '../views/frh/amargarh-hut';
import choras_hut from '../views/frh/choras-hut'


import updates_2023_08_01_001 from '../views/updates/20230801_01';
import updates_2023_08_01_002 from '../views/updates/20230801_02';
import updates_2023_10_30_001 from '../views/updates/20231030_01';
import updates_2023_10_30_002 from '../views/updates/20231030_02';
import updates_2023_10_30_003 from '../views/updates/20231030_03';
import updates_2024_02_23_001 from '../views/updates/20240223_01';
import updates_2024_02_23_002 from '../views/updates/20240223_02';
import updates_2024_02_23_003 from '../views/updates/20240223_03';
import updates_2024_03_17_001 from '../views/updates/20240317_01';
import updates_2024_03_17_002 from '../views/updates/20240317_02';
import updates_2024_03_17_003 from '../views/updates/20240317_03';

import renukaji_ficus from '../views/sanctuaries/renukaji-links/ficus-membrane';
import renukaji_cavity from '../views/sanctuaries/renukaji-links/cavity-nesting';
import renukaji_migratory from '../views/sanctuaries/renukaji-links/migratory_bird';
import renukaji_nature from '../views/sanctuaries/renukaji-links/nature_resilliance';
import renukaji_cave from '../views/sanctuaries/renukaji-links/who_made_cave';
import renukaji_friends from '../views/sanctuaries/renukaji-links/friends_of_wetland';


const routes = [
  {
    path: '/',
    name: 'Home',
    components: {
      header: Header,
      default: Home,
      footer: Footer
    },
  },
  {
    path: '/about-division',
    name: 'About Division',
    components: {
      header: Header,
      default: AboutDivision,
      footer: Footer
    },
  },
  
  {
    path: '/himalyan-nature-park',
    name: 'Himalayan Nature Park',
    components: {
      header: Header,
      default: hnp_zoo,
      footer: Footer
    },
  },
  {
    path: '/renuka-ji-zoo',
    name: 'Renuka Ji Mini Zoo',
    components: {
      header: Header,
      default: renuka_mini_zoo,
      footer: Footer
    },
  },
  {
    path: '/himalayan-bird-park',
    name: 'Himalayan Bird Park Shimla',
    components: {
      header: Header,
      default: bird_park,
      footer: Footer
    },
  },
  {
    path: '/chail-conservation',
    name: 'Chail Conservation Breeding Centre',
    components: {
      header: Header,
      default: breeding_center,
      footer: Footer
    },
  },
  {
    path: '/rescue-centre',
    name: 'Rescue and Rehabilitation Centre Shimla',
    components: {
      header: Header,
      default: rescue_rehab,
      footer: Footer
    },
  },
  {
    path: '/monkey-rescue',
    name: 'Monkey Sterilization Centre',
    components: {
      header: Header,
      default: monkey_rescue,
      footer: Footer
    },
  },
  {
    path: '/simbalbara-np',
    name: 'Simbalbara National Park',
    components: {
      header: Header,
      default: simbalbara_np,
      footer: Footer
    },
  },
  {
    path: '/majathal-wls',
    name: 'Majathal Wildlife Sanctuary',
    components: {
      header: Header,
      default: majathal_wls,
      footer: Footer
    },
  },
  {
    path: '/renuka-ji-wls',
    name: 'Renuka Ji Wildlife Sanctuary',
    components: {
      header: Header,
      default: renukaji_wls,
      footer: Footer
    },
  },
  {
    path: '/churdhar-wls',
    name: 'Churdhar Wildlife Sanctuary',
    components: {
      header: Header,
      default: churdar_wls,
      footer: Footer
    },
  },
  {
    path: '/shimla-water-catchment',
    name: 'Shimla Water Catchment Wildlife Sanctuary',
    components: {
      header: Header,
      default: catchment_wls,
      footer: Footer
    },
  },
  {
    path: '/chail-wls',
    name: 'Chail Wildlife Sanctuary',
    components: {
      header: Header,
      default: chail_wls,
      footer: Footer
    },
  },
  {
    path: '/talra-wls',
    name: 'Talra Wildlife Sanctuary',
    components: {
      header: Header,
      default: talra_wls,
      footer: Footer
    },
  },
  {
    path: '/kufri-frh',
    name: 'Kufri FRH',
    components: {
      header: Header,
      default: kufri_frh,
      footer: Footer
    },
  },
  {
    path: '/renuka-ji-frh',
    name: 'Renujka Ji FRH',
    components: {
      header: Header,
      default: renuka_frh,
      footer: Footer
    },
  },
  {
    path: '/chail-frh',
    name: 'Chail FRH',
    components: {
      header: Header,
      default: chail_frh,
      footer: Footer
    },
  },
  {
    path: '/simbalbara-frh',
    name: 'Simbalbara FRH',
    components: {
      header: Header,
      default: simbalbara_frh,
      footer: Footer
    },
  },
  {
    path: '/choras-hut',
    name: 'Choras Inspection Hut',
    components: {
      header: Header,
      default: choras_hut,
      footer: Footer
    },
  },
  {
    path: '/amargarh-hut',
    name: 'Amargarh Inspection Hut',
    components: {
      header: Header,
      default: amargargh_hut,
      footer: Footer
    },
  },
  {
    path: '/contact-us',
    name: 'Contact',
    components: {
      header: Header,
      default: ContactUs,
      footer: Footer
    },
  },
  {
    path: '/feedback-zoo',
    name: 'Feedback of our Zoo',
    components: {
      header: Header,
      default: feedback_zoo,
      footer: Footer
    },
  },

  {
    path: '/feedback-resthouse',
    name: 'Feedback of our Resthouses',
    components: {
      header: Header,
      default: feedback_resthouse,
      footer: Footer
    },
  },
  {
    path: '/recent-activities',
    name: 'Recent Activities',
    components: {
      header: Header,
      default: Notifications,
      footer: Footer
    },
  },
  {
    path: '/publications',
    name: 'Publications',
    components: {
      header: Header,
      default: Publications,
      footer: Footer
    },
  },
  {
    path: '/tenders',
    name: 'Tenders',
    components: {
      header: Header,
      default: coming_soon,
      footer: Footer
    },
  },
  {
    path: '/gallery',
    name: 'Gallery',
    components: {
      header: Header,
      default: coming_soon,
      footer: Footer
    },
  },
  {
    path: '/notifications',
    name: 'Notifications',
    components: {
      header: Header,
      default: Notifications,
      footer: Footer
    },
  },
  {
    path: '/coming-soon',
    name: 'Coming Soon',
    components: {
      header: Header,
      default: coming_soon,
      footer: Footer
    },
  },
  {
    path: '/renuka-ji-wls-001',
    name: 'Renuka Ji Wildlife Sanctuary | Nature Resilience',
    components: {
      header: Header,
      default: renukaji_nature
    },
  },
  {
    path: '/renuka-ji-wls-002',
    name: 'Renuka Ji Wildlife Sanctuary | Ficus Embrace',
    components: {
      header: Header,
      default: renukaji_ficus
    },
  },
  {
    path: '/renuka-ji-wls-003',
    name: 'Renuka Ji Wildlife Sanctuary | Cavity Nesting',
    components: {
      header: Header,
      default: renukaji_cavity
    },
  },
  {
    path: '/renuka-ji-wls-004',
    name: 'Renuka Ji Wildlife Sanctuary | Migratory Birds',
    components: {
      header: Header,
      default: renukaji_migratory
    },
  },
  {
    path: '/renuka-ji-wls-005',
    name: 'Renuka Ji Wildlife Sanctuary | Who made the Cave',
    components: {
      header: Header,
      default: renukaji_cave
    },
  },
  {
    path: '/renuka-ji-wls-006',
    name: 'Renuka Ji Wildlife Sanctuary | Friends of Wetland',
    components: {
      header: Header,
      default: renukaji_friends
    },
  },
  {
    path: '/renuka-ji-wls-007',
    name: 'Renuka Ji Wildlife Sanctuary | 007',
    components: {
      header: Header,
      default: coming_soon
    },
  },  
  {
    path: '/renuka-ji-wls-008',
    name: 'Renuka Ji Wildlife Sanctuary | 008',
    components: {
      header: Header,
      default: coming_soon
    },
  },
  {
    path: '/renuka-ji-wls-009',
    name: 'Renuka Ji Wildlife Sanctuary | 009',
    components: {
      header: Header,
      default: coming_soon
    },
  },
  {
    path: '/renuka-ji-wls-010',
    name: 'Renuka Ji Wildlife Sanctuary | 010',
    components: {
      header: Header,
      default: coming_soon
    },
  },
  {
    path: '/renuka-ji-wls-011',
    name: 'Renuka Ji Wildlife Sanctuary | 011',
    components: {
      header: Header,
      default: coming_soon
    },
  },
  {
    path: '/renuka-ji-wls-012',
    name: 'Renuka Ji Wildlife Sanctuary | 012',
    components: {
      header: Header,
      default: coming_soon
    },
  },

  {
    path: '/2023-August-001',
    name: 'Operation of Renuka Ji Souvenir Shop by Women-led SHG',
    components: {
      header: Header,
      default: updates_2023_08_01_001,
      footer: Footer
    },
  },
  {
    path: '/2023-August-002',
    name: 'Fodder Plot Development',
    components: {
      header: Header,
      default: updates_2023_08_01_002,
      footer: Footer
    },
  },
  {
    path: '/2023-October-001',
    name: 'Artistic Modification of Range Office Campus at Shimla Water Catchment',
    components: {
      header: Header,
      default: updates_2023_10_30_001,
      footer: Footer
    },
  },
  {
    path: '/2023-October-002',
    name: 'Eco Development: A New Beginning of Participatory Conservation at Shimla Water Catchment and Chail Wildlife Sanctuary',
    components: {
      header: Header,
      default: updates_2023_10_30_002,
      footer: Footer
    },
  },
  {
    path: '/2023-October-003',
    name: 'Recording the First Camera Trap Image of Royal Bengal Tiger in State of Himachal Pradesh',
    components: {
      header: Header,
      default: updates_2023_10_30_003,
      footer: Footer
    },
  },
  {
    path: '/proactive-protection-measure-setting-up-fire-hidrant-in-tutikandi',
    name: 'A Proactive Protection Measure: Setting up the Fire Hydrant Safety System in Rescue and Rehabilitation Centre, Tutikandi',
    components: {
      header: Header,
      default: updates_2024_02_23_001,
      footer: Footer
    },
  },
  {
    path: '/eco-development-program-at-churdhar-wls',
    name: 'Eco Development Program at Churdhar Wildlife Sanctuary: A New Beginning of Participatory Conservation through Entry Point Activities',
    components: {
      header: Header,
      default: updates_2024_02_23_002,
      footer: Footer
    },
  },
  {
    path: '/camera-trap-based-monitoring-changing-the-conservation-landscape',
    name: 'CAMERA TRAP BASED MONITORING CHANGING THE CONSERVATION LANDSCAPE OF PROTECTED AREAS: A STORY OF MANY NEW RECORDS FROM PROTECTED AREAS IN SHIMLA WILDLIFE DIVISION',
    components: {
      header: Header,
      default: updates_2024_02_23_003,
      footer: Footer
    },
  },
  {
    path: '/animal-exchange-between-hnp-kufri-zoo-shimla-and-pnhzp-darjeeling',
    name: 'ANIMAL EXCHANGE BETWEEN HNP KUFRI ZOO, SHIMLA AND PNHZP, DARJEELING',
    components: {
      header: Header,
      default: updates_2024_03_17_001,
      footer: Footer
    },
  },
  {
    path: '/himachals-first-eco-bridge',
    name: 'Himachal’s First Eco Bridge for Arboreal Animals at Chail Wildlife Sanctuary: An Experiment to overcome fragmented habitat landscapes',
    components: {
      header: Header,
      default: updates_2024_03_17_002,
      footer: Footer
    },
  },
  {
    path: '/triumph-in-the-wetlands',
    name: 'A Triumph in the Wetlands: Successful Swamp Deer Breeding at Sri Renuka Ji Mini Zoo',
    components: {
      header: Header,
      default: updates_2024_03_17_003,
      footer: Footer
    },
  },
  
]

const router = createRouter({
  linkExactActiveClass: "active",
  history: createWebHistory(),
  routes
})

export default router