<template>
    <div>
        <section class="bg-forest">
            <MDBContainer class="p-5 bg-form">
                <MDBRow class="mb-3">
                    <MDBCol md="12" class="mx-auto text-light item-align-center">
                        <MDBCardText style="text-align : justify;" class="">
                            <h2 class="mb-4 text-light text-center">Fodder Plot Development</h2>
                            <p>
                                <span class="para-img float-left mr-20" style="text-align : right">
                                    <img src="/assets/images/updates/2023/august/002/4.png" class="img-round-corner" style="max-height : 375px; width : auto; max-width : 100%;">
                                    <br>
                                    
                                </span>
                                With the goal of cost-cutting and attaining self–sufficiency in long term in meeting the forage needs for the captive herbivores of Renuka Ji Mini Zoo, a plan for fodder plot development was conceptualized and implemented in the financial year 2022-23.
                                <br><br>
                                Before full-fledged implementation of the plan on a bigger site, it was experimented with on a site of size 700m2 (0.07 Ha) in the zoo premises. A shady site was selected at the foothill, amidst the Eucalyptus plantation to prevent excess evaporation while irrigation of the cropped grasses. The selected site was cleared off the vegetation, except trees and was adequately fenced on all sides to prevent grazing by wild animals<br><br>                              
                                <br><small><i>Fig- The selected site was cleared off the vegetation and fenced on all sides</i></small>
                            </p>
                            <br><br> <br>
                            <p>
                                Three fodder species were sown in the month of April- Euchaleana maxicana, which is locally known as ‘Mak Chari’, Pennisetum glacum (Bajra)- Bajra/Pearl millet, and Medicago sativa (Alfalfa/lucerne). 
                                Though these are water intensive crops but, good pre-monsoon showers throughout April and May favoured their quick germination. The crops were also irrigated from time to time as was needed.
                                Euchaleana maxicana, (Mak Chari) and Pennisetum glacum (Bajra) germinated well and were harvested in June and July. Medicago sativa (Alfalfa/lucerne) didn’t come up well as it grows up better in winters. 
                            </p>  
                            <p>
                                <MDBRow>
                                    <MDBCol md="6">
                                            <img src="/assets/images/updates/2023/august/002/5.png" class="img-round-corner" style="max-height : 275px; width : auto; max-width : 100%;">
                                            <br>
                                            <small><i>Euchaleana maxicana</i>, (Mak Chari) and <i>Pennisetum glacum</i> (Bajra) germinated well</small>
                                       
                                    </MDBCol>
                                    <MDBCol md="6">
                                            <img src="/assets/images/updates/2023/august/002/6.png" class="img-round-corner" style="max-height : 275px; width : auto; max-width : 100%;">
                                            <br>
                                            <small><i>Medicago sativa</i> (Alfalfa/lucerne)</small>
                                        
                                    </MDBCol>
                                </MDBRow>                            
                            </p>  
                            <br>
                            <p>
                                <span class="para-img float-left mr-20" style="text-align : right">
                                    <img src="/assets/images/updates/2023/august/002/3.jpeg" class="img-round-corner" style="max-height : 345px; width : auto; max-width : 100%;">
                                    <br>
                                    <small><i>- The harvested crop was quantified</i></small>
                                </span>
                                The crop was harvested in June and July and since these are multi-cut (Annual) fodder species, these are expected to yield about 4 -5 more harvests throughout the year. The harvested crop was quantified to estimate the amount of fodder needed to be produced from the fodder plot for the zoo to become self-sustaining in meeting the fodder requirements of the its herbivores.
                                <br><br>
                                However, it was realised that space is a constraint when it comes to expanding the cropping area to produce as much amount of fodder as the zoo requires and hence, it has been planned that members of eco-development societies from nearby villages will be involved to supply fodder grasses as well as fodder trees. In these villages cattle-rearing is one of the most important sources of subsistence and hence, by involving them in this task their knowledge and land can be utilized for meeting the fodder needs of the zoo as well as for generating nature-based livelihood.     
                            </p>  

                        </MDBCardText>
                    </MDBCol>
                    <MDBCol md="12">
                        <p style="text-align: right" class="text-light">
                            <i>Content and Picture Credit: Sourabh Pundir, Assitant Zoo Biologist, Renuka Ji Mini Zoo</i>
                        </p>
                    </MDBCol>
                </MDBRow>                     
            </MDBContainer>
        </section>        
    </div>
</template>
<script>
  import { ref } from "vue";
  import { 
    MDBCol, MDBRow, MDBContainer,
    MDBInput,
    MDBCheckbox,
    MDBBtn,
    MDBTextarea,
    MDBModal,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBCarousel,
    MDBCardText,
    MDBCardImg, mdbRipple

  } from "mdb-vue-ui-kit";
  export default {
    name : "Updates_August_002",
    components: {
        MDBCol, MDBRow, MDBContainer,
        MDBInput,
        MDBCheckbox,
        MDBBtn,
        MDBTextarea,
        MDBModal,
        MDBModalHeader,
        MDBModalTitle,
        MDBModalBody,
        MDBModalFooter,
        MDBCarousel,
        MDBCardText,
        MDBCardImg
    },
    directives: {
      mdbRipple
    },
    setup() {
      const items1 = [
        {
          src: "/assets/images/contact-us.jpg",
          alt: "Shimla Wildlife Division",
        }
      ];
      const exampleModal = ref(false);
      const carousel1 = ref(0);
      return {
        exampleModal,
        items1,
        carousel1,
        form : {
            name : '',
            email : '',
            phone : '',
            query : '',
            city : '',
            newsletter : true
        },
        modal : {
            show : true,
            title : 'SUCCESS!!',
            body : 'Your query has been posted successfully! We will get back to you soon'
        }
      };
    },
    methods : {
        submitForm(){

            this.exampleModal = true;
        }
    }
  };
</script>
<style scoped>

    .bg-dark-green {
        background-color: rgb(77,169,80);
    }

    .para-img {
        float: right;
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
    }

    .bg-contact {
        background: linear-gradient(rgba(44, 95, 45, 0.5), rgba(44, 95, 45,0.5)), url('/assets/img/contact-bg.svg');
    }

    .float-left {
        float: left !important;
    }

    .mr-20 {
        margin-right : 20px
    }

    .ml-20 {
        margin-left : 20px
    }

    .bg-trago {
        background: linear-gradient(rgba(44, 95, 45, 0.9), rgba(44, 95, 45,0.9)), url('/assets/img/re_6.jpeg') center ;
    }

    .bg-forest {
        background: url('/assets/img/Untitled design-15.png') ;
        background-size: cover;
    }

    .img-round-corner {
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
    }

    .bg-form {
        background-color : rgb(66,136,121);
    }

    .form-title {

        border-left: 5px solid rgb(138,250,160);
        padding-left: 10px;
    }

    .form-color {
        color : #4F4F4F !important;
        border-color: transparent !important;
        background-color: white !important;
    }
    .update-container {
        overflow: hidden;        
    }
    .responsive-iframe {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

</style>