<template>
    <div>
        <MDBCarousel
            v-model="carousel1"
            :items="items1"
            fade
            :controls="false"
            :indicators="false"
        />
        <section >
            <MDBContainer class="p-5">
                <h4 class="mb-4 text-dark">History</h4>
                <MDBRow>
                    <MDBCol class="mb-2 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                <span class="para-img float-left mr-20" style="text-align : right; margin-right: 30px;">
                                    <img src="/assets/images/bird_park_7.jpg" class="img-round-corner" style="max-height : 375px; width : auto; max-width : 100%; margin-right : 20px">
                                    <!-- <br>
                                    <small><i>- Visit of School Children at HNP Kufri</i></small> -->
                                </span>
                                The foundation stone of Himalayan bird park was laid by Shri S. Chakravati, Honorable Governor of Himachal Pradesh in 1971 then later construction work was completed by Shri B Baddri, Honorable Governor of Himachal Pradesh in 1972. Other than birds, some animals like leopard & deer species were also kept during this period with a free viewership to all visitors.Since park is in very close  proximity of Indian Institute of Advance Studies & Himachal Pradesh State Museum,it started  attracting tourist. 
                                <br><br>
                                During period of 1986-87, aviary was dismantled. Later after five years in 1993-94, Honourable Governor of Himachal Pradesh Shri Gusher Ahmad Sahib showed interest in Bird Park & asked the Himachal Pradesh Forest Department & State Government to re-build this aviary. The aviary was re constructed in 1993-94 with an amount of Rs. 555526 in an area of 726.63m2 of total area of 1136.97m2. The park was inaugurated on 08.08.1994 by then Hon C.M Shri Virbhadra Singh. Since then, park is being run by wildlife wing of Himachal Pradesh Forest department under jurisdiction of DFO Wildlife Shimla.       
                            </p>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>
                <h4 class="mb-4 text-dark">About Himalayan Bird Park</h4>
                <MDBRow>
                    <MDBCol class="mb-2 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                <span class="para-img float-right ml-20" style="text-align : right; margin-right: 30px;">
                                    <img src="/assets/images/bird_park_6.jpg" class="img-round-corner" style="max-height : 375px; width : auto; max-width : 100%; margin-right : 20px">
                                    <!-- <br>
                                    <small><i>- Visit of School Children at HNP Kufri</i></small> -->
                                </span>
                                Bird Park is situated in the heart of Shimla City at the distance of 2km from mall road Shimla at 2205m above sea level. The park is surrounded by Oak & Deodar trees & provides unforgettable opportunity to be amidst birds especially western Himalayan Avi-fauna as the giant aviary provides an immersive walk experience for the visitors.
                                <br><br>
                                As the birds are in captivity i,e limited space is available as compared to wild, all efforts are  continuously done to improvise their living. With this reason park is enriched with their species specific attributes. Multiple perching platforms along with natural vegetation is provided within. Multiple nesting opportunities have also been provided to each species. Artificial pool along with  water fountain is provided within the park. 
                                <br><br>
                                The birds here are fed with fresh & high-quality diet that meets their nutritional requirement. The feed majorly contains fresh vegetables, fruits & grain mixture 
                                <br><br>
                                <b>Objective</b>
                                <ol>
                                    <li>To educate visitors about avi-fauna especially about pheasants of Himalayas</li>
                                    <li>To generate awareness on conservation of avifauna especially pheasants and sparrows</li>
                                    <li>To provide a recreational space for nature lovers and tourists</li>
                                </ol>                             
                            </p>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>
                <h4 class="mb-4 text-dark">Birds of Himalayan Bird Park</h4>
                <MDBRow>
                    <MDBCol class="mb-2 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                The walk in aviary has as many as 9 species of birds with national bird Indian Peacock hogging the limelight of the visitors. It has several species of pheasants including the Himalayan Monal known for its colourful plumage, the Cheer Pheasant, Khalij Pheasant, Red Jungle Fowl, Silver Pheasant. Apart from the captive birds, the aviary also sees several House sparrows and smaller birds which make the park and the various bird houses their home making their way through the giant enclosure                            
                            </p>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>
                <MDBRow >
                        <MDBCol md="12" xs="12" class="mx-auto text-center">
                            <vue-horizontal responsive>
                                <section v-for="item in photos" :key="item.title">
                                    <MDBCard style="margin-right:30px">
                                        <a v-mdb-ripple="{ color: 'light' }">
                                            <MDBCardImg :src="item.img" top :alt="item.name" class="img-cut-edge" style="max-height : 300px"/>
                                        </a>
                                    </MDBCard>
                                </section>
                            </vue-horizontal>
                        </MDBCol>
                    </MDBRow>
                <h4 class="mt-4 mb-4 text-dark">Wildlife Wing Initiatives in the Park</h4>
                <MDBRow>
                    <MDBCol class=" item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                The park is substantially enriched by promoting native vegetation suitable for birds to perch, roost, make their nests and offer breeding and egg laying sites. The enrichment and suitable habitat has enabled good breeding success in the aviary with Red Jungle Fowl and Khalij pheasant numbers increasing substantially in the last year. Further, numerous night sheds are provided in the giant aviary for the birds which is crucial for their safety during winters and to enable breeding.
                                <br><br>
                                The many tree houses in the park are also the breeding home for sparrows and the number of sparrows making their way into aviary and making their home using the twigs is a great sight for the visitor. These educational aspects have also been communicated to the visitors through novel bilingual signage which promote wildlife knowledge and conservation value.
                            </p>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>
                <MDBRow class="mb-2">
                    <MDBCol md="6" xs="6" class="mx-auto text-center">
                        <a v-mdb-ripple="{ color: 'light' }">
                            <MDBCardImg src="/assets/images/bird_park_4.jpg" style="max-width :100%; max-height: 350px; width: auto;"/>
                        </a>
                    </MDBCol>  
                    <MDBCol md="3" xs="3" class="mx-auto text-center">
                        <a v-mdb-ripple="{ color: 'light' }">
                            <MDBCardImg src="/assets/images/bird_park_5.jpg" style="max-width :100%; max-height: 350px; width: auto;"/>
                        </a>
                    </MDBCol>           
                    <MDBCol md="3" xs="3" class="mx-auto text-center">
                        <a v-mdb-ripple="{ color: 'light' }">
                            <MDBCardImg src="/assets/images/bird_park_6.jpg" style="max-width :100%; max-height: 350px; width: auto;"/>
                        </a>
                    </MDBCol>           
                </MDBRow>    
            </MDBContainer>
        </section>     
    </div>
</template>
<script>
  import { ref } from "vue";
  import VueHorizontal from "vue-horizontal";
  import { 
    MDBCarousel,
    MDBCol, MDBRow, MDBContainer,
    MDBCardText,
    MDBCard, MDBCardHeader, MDBCardBody, MDBCardTitle, MDBCardImg, mdbRipple
  } from "mdb-vue-ui-kit";
  export default {
    name : "Daranghati",
    components: {
      MDBCarousel,
      MDBCol, MDBRow, MDBContainer,
      MDBCardText, VueHorizontal,
      MDBCard, MDBCardHeader, MDBCardBody, MDBCardTitle, MDBCardImg
    },
    directives: {
      mdbRipple
    },
    setup() {
      const items1 = [
        {
          src: "/assets/images/bird_park_banner.jpg",
          alt: "Himalayan Bird Park, Shimla",
          caption : ""
        }
      ];
      const carousel1 = ref(0);
      return {
        items1,
        carousel1,
        initiativePhotos : [
            {
                img : '/assets/images/bird_park_1.jpg'
            },
            {
                img : '/assets/images/bird_park_2.jpg'
            },
            {
                img : '/assets/images/bird_park_3.jpg'
            }
        ],
        photos: [
          {
            img : '/assets/images/bird_park_1.jpg'
          },
          {
            img : '/assets/images/bird_park_2.jpg'
          },
          {
            img : '/assets/images/bird_park_3.jpg'
          }
        ]
      };
    }
  };
</script>
<style scoped>

    /* GRADIENTS */
    .winter-neva-gradient {
        background-image: linear-gradient(120deg,#a1c4fd,#c2e9fb);
    }

    .deep-blue-gradient {
        background-image: linear-gradient(120deg,#e0c3fc,#8ec5fc);
    }
    /* GRADIENTS END */

    .gradient-text {
        line-height: 200px; 
        font-size : 24px; 
        letter-spacing : 2px
    }

    .z-depth-1-half {
        -webkit-box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)!important;
        box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)!important;
    }

    .bg-dark-green {
        background-color: rgb(77,169,80);
    }

    .para-img {
        float: right;
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
    }

    .bg-contact {
        background: linear-gradient(rgba(44, 95, 45, 0.5), rgba(44, 95, 45,0.5)), url('/assets/img/contact-bg.svg');
    }

    .float-left {
        float: left !important;
    }

    .mr-20 {
        margin-right : 20px
    }

    .ml-20 {
        margin-left : 20px
    }

    .img-round-corner {
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
    }

    h4 {
        margin: 0;  
    }
    .color-block-5 {
        height: 200px;
        width: 200px;
    }
    .img-gradient{
        position:relative;
        display:inline-block;
    }
    .img-gradient:after {
        content:'';
        position:absolute;
        left:0; top:0;
        width:100%; height:100%;
        display:inline-block;
        background: -moz-linear-gradient(top, rgba(0,47,75,0.5) 0%, rgba(220, 66, 37, 0.5) 100%); /* FF3.6+ */
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(220, 66, 37, 0.5)), color-stop(100%,rgba(0,47,75,0.5))); /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* Opera 11.10+ */
        background: -ms-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* IE10+ */
        background: linear-gradient(to bottom, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002f4b', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
    }
    .img-gradient img{
        display:block;
    }

</style>