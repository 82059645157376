<template>
    <div>
        <section class="bg-forest">
            <MDBContainer class="p-5 bg-form">
                <MDBRow class="mb-3">
                    <MDBCol md="12" class="mx-auto text-light item-align-center">
                        <MDBCardText style="text-align : justify;" class="">
                            <h2 class="mb-4 text-light text-center">ANIMAL EXCHANGE BETWEEN HNP KUFRI ZOO, SHIMLA AND PNHZP, DARJEELING</h2>       
                            <h4 class="mb-4 text-light">Introduction</h4>
                            <p> 
                                Animal exchange program between zoos help in maintaining and increasing genetic diversity within captive population. Exchanging animals/ birds between different zoos, the gene pool is expanded, reducing the risks of inbreeding and genetic problems associated with small populations. Moreover, by bringing in new animals/ bird’s zoos can offer visitors the opportunity to observe and learn about different species, raising awareness about the importance of conservation and biodiversity.
                                <br><br>
                                Animal exchange program as approved by CZA was undertaken between HNP Kufri Zoo and PNHZP Darjeeling by road in the month of November,2024. The exchanged species and number were as under
                                <br><br>
                                <table>
                                    <tr>
                                        <th>
                                            From HNP KUFRI ZOO, SHIMLA to PNHZP DARJEELING
                                        </th>
                                        <th>
                                            FROM PNHZP, DARJEELING to HNP KUFRI ZOO, SHIMLA
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>
                                            Himalayan Gorals (1:2) - Total 3 no.
                                        </td>
                                        <td>
                                            Bharal/ Blue Sheep (2:1) - Total 3 no.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Cheer Pheasant (3:3)    Total 6 no.
                                        </td>
                                        <td>
                                            Golden Pheasant (2:2)                   Total 4 no.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Kalij Pheasant (2:2)        Total 4 no.
                                        </td>
                                        <td>
                                            Silver Pheasant (2:2)                      Total 4 no.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Red Jungle fowl (3:3)     Total 6 no.
                                        </td>
                                        <td>
                                            Lady Amherst Pheasant (2:2)       Total 4no.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            -------------------------
                                        </td>
                                        <td>
                                            Red Jungle Fowl (3:3)                    Total 6 no.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>
                                                Total- 19 no.
                                            </b>
                                        </td>
                                        <td>
                                            <b>
                                                Total- 21 no.
                                            </b>
                                        </td>
                                    </tr>
                                </table>
                            </p>  
                            <br>
                            <h4 class="mb-4 text-light">GENERAL CONSIDERATIONS PRIOR TO TRANSPORT</h4>
                            <p> 
                                Exchange of animals and birds between zoos necessitate their transportation between various locations. Wild animals/ birds undergo high levels of stress during their restraint, capture, confinement, loading, unloading and transportation process as well as when adapting to the new environment they have been shifted to. Safe, humane, ethical and professional approach is required for successful transportation of wild animals and birds under animal exchange program.
                                <br><br>
                                <span class="para-img float-left" style="text-align : center; margin-right: 40px !important;">
                                    <img src="/assets/images/updates/2024/march/001/image001.jpg" class="img-round-corner" style="max-height : 375px; width : auto; max-width : 100%;">
                                    <br>
                                    <small><i>- Sanitization of transportation cages</i></small>
                                </span>   
                                <ol>
                                    <li>
                                        <b>Selection of individuals at HNP Kufri Zoo</b>
                                    </li>
                                    Selection of animals is critical in any planned exchange program. The animals and birds were identified as per animal exchange proposal.  It was ensured that selected animals/ birds were in good health and have clean health record. Only adults and subadults individuals were selected. Pregnant, geriatric, lactating, sick, weak, injured and deformed animals and birds were avoided. The identified individuals were segregated and housed in separate enclosures. Blood and faecal samples from identified animals and birds were collected and sent to DI Lab Shimla for health screening. Tests like complete blood count, hemoprotozoan screening and Endo parasitic load were conducted to determine their fitness to travel.
                                    <br><br>
                                    The animals and birds found fit as per health screening were finally selected for exchange. Animal History Cards and Health Records were prepared for exchange.                                    
                                    
                                    <li>
                                        <b>Animal/ Birds transportation cage considerations</b>
                                    </li>
                                    Animals / birds have to be confined in transport cages during transport for easy handling and to minimize the chances of injury. Transportation cages at HNP Kufri were designed as per transportation cages guidelines issued by CZA. The transportation cages were fabricated as per species requirement. Cages were made of dimensions that allowed the animal to stand and rest in sternal recumbency but do not provide space for turning or somersault. Sufficient ventilation provisions were kept in animal and birds transport cages. Saw dust and grass bedding was provided to reduce injuries. Netlon rubber insulation sheet lining was provided on inner sides of pheasant cages to prevent head injuries during the transit. The dimensions of transportation cages are tabulated below
                                    <br>
                                    <table>
                                        <tr><th>Species</th><th>Length</th><th>Breadth</th><th>Height</th><th>Remarks</th></tr>
                                        <tr>
                                            <td>Gorals</td>
                                            <td>1.17 mtr</td>
                                            <td>0.40 mtr</td>
                                            <td>1.40 mtr</td>
                                            <td>Only one animal transported in a cage. Sufficient saw dust and grass bedding provided.</td>
                                        </tr>
                                        <tr>
                                            <td>Short Tailed Pheasant</td>
                                            <td>0.8 mtr</td>
                                            <td>0.5 mtr</td>
                                            <td>0.5 mtr</td>
                                            <td>Netlon inner lining, wire mesh doors for ventilation.Saw dust and grass bedding provided.One pair of pheasants transported in each box.</td>
                                        </tr>
                                        <tr>
                                            <td>Long Tailed Pheasant</td>
                                            <td>1.2 mtr</td>
                                            <td>0.5 mtr</td>
                                            <td>0.5 mtr</td>
                                            <td>Netlon inner lining, wire mesh doors for ventilation.Saw dust and grass bedding provided.One pair of pheasants transported in each box.</td>
                                        </tr>
                                    </table>
                                    The transportation cages were kept inside the animal and bird enclosures selected for exchange few days before date of departure and feeding was done inside them. This helps in familiarisation of cages/ boxes. The transportation cages and boxed were cleaned/sanitized before shifting animals and pheasants into them.
                                    <img src="/assets/images/updates/2024/march/001/image009.png" class="img-round-corner" style=" width : 100%; max-width : 100%;">
                                    
                                    <li>Constitution of team</li>
                                    As per exchange guidelines team members from both zoos, recipient as well as donor zoo should accompany the animals/ birds during transit, but PNHZP Darjeeling Director expressed their inability to depute officials. So, a team from HNP KUFRI Zoo, comprising of following members was constituted for animal exchange program for both side transport:
                                    <br>Veterinary Officer HNP Kufri Zoo.
                                    <br>Block Officer HNP Kufri Zoo
                                    <br>Forest Guard
                                    <br>Animal attendants- 2 no.

                                    <li>Hiring of vehicles</li>
                                    One double cabin truck with bed and one passenger vehicle with two drivers each was hired for transportation of animals and team members between HNP Kufri and PNHZP Darjeeling (to and fro). It was ensured that vehicles were in good running condition, have new tyres and recently serviced. The truck was requisitioned one day earlier before departure so that cages arrangement could be planned in advance. Moist saw dust bedding about 2 inches was put on truck deck floor to provide cushion and prevent slippage of cages during transit. The truck body top was covered with tarpaulin to prevent direct sunlight and sides were left open for ventilation.
                                    
                                    <li>Checklist of medicines and equipment</li>
                                    Medicines and equipment required during the transportation was packed as per checklist. Tranquilisation equipment was carried along for any exigency during the transit. List of enroute Zoos and rescue centres was also kept.

                                    <li>Weather and climate considerations</li>
                                    A complete record of weather and climatic conditions was maintained prior to initiating transport. Transport was planned during late autumn when temperatures in Indo Gangetic areas are moderate and fog hasn’t started.

                                </ol>
                            </p> 
                            <br><br>
                            <h4 class="mb-4 text-light">Capture and loading of animals and birds at HNP Kufri.</h4>                             
                            <p> 
                                <span class="para-img float-left" style="text-align : center; margin-right: 40px !important;">
                                    <img src="/assets/images/updates/2024/march/001/image004.jpg" class="img-round-corner" style="max-height : 375px; width : auto; max-width : 100%;">
                                    <br>
                                    <small><i>- Loading and arrangement of transportation cages and boxes in the truck</i></small>
                                </span>  
                                On the day of departure, the animals and birds were fed early morning and then shifted to transport cages. The transportation cages were loaded and arranged in truck deck. The Goral transportation cages were kept in front and secured with ropes to prevent their overturing during transit. One feet gap was kept between all the cages for effective ventilation. Pheasants’ transportation boxes were arranged behind Goral enclosures in single layer leaving sufficient space between boxes for ventilation and examination during transportation. Stacking was not done. Live wild animal and bird label was put on all the cages, detail regarding species and number were also put. Transit pass and Fitness certificates file in duplicate were kept in both the vehicles. The feed, fodder, fruits and drinking water required for animals/pheasants during transit were carried along from HNP Kufri. 
                            </p>  
                            <br><br>
                            <h4 class="mb-4 text-light">Transport mode and route:</h4>                             
                            <p>  
                                Land route was selected for the transport. Shortest route through expressways was taken to minimise the duration of transport. The transportation was done without halts. Only short halts were taken for feeding and examination of animals/ birds. To keep Pheasants hydrated fresh juicy fruits like oranges, pomegranate, watermelon and cucumber were provided. Two drivers per vehicle ensured that drivers get adequate rest after driving continuously for 6 hours. Animal transportation started on 19th November,2023 at 1230 hrs from HNP Kufri and reached destination Dow Hill, Darjeeling on 21st November,2023 at 20:00 hrs. It took 55 hours of continuous travel with short halts to reach PNHZP Darjeeling from HNP Kufri covering 1900 kms. Road from Sukna, West Bengal to Dow Hill, Darjeeling was too narrow for our truck to pass through and as a result we had to hire pickup trucks (2no.) and transportation cages were shifted from truck to pickup trucks hired at Sukna.
                                <br><br>
                                Transportation was done at the average speed of about 60 km/hr on expressways and 30 km/hr on hills. It was ensured that vehicles are driven on constant speed without abrupt braking. In Bihar the road conditions were quite bad and lead to stressful journey, especially for pheasants.    
                                <img src="/assets/images/updates/2024/march/001/image005.png" class="img-round-corner" style=" width : 100%; max-width : 100%;">
                                    
                            </p>  

                            <br><br>
                            <h4 class="mb-4 text-light">Unloading and shifting of animals/birds at Dow Hill, Darjeeling.</h4>                             
                            <p>  
                                Upon reaching Dow Hill Darjeeling the cages were unloaded. The animals and birds were shifted to their night enclosures. Pheasants were shifted to their enclosures along with the transport boxes to minimise disturbance and shutters were opened. One pair of Kaleej pheasant died in transit. Their postmortem was conducted at Dow Hill Veterinary Hospital by in charge Veterinarian. 
                            </p> 
                            <br>
                            <h4 class="mb-4 text-light">Identification of animals and pheasants selected for exchange at Dow Hill, Darjeeling</h4>                             
                            <p>  
                                The blue sheep and pheasants to be transported from PNHZP Darjeeling to HNP Kufri were identified and their fitness ascertained by the team. Their feeding and husbandry management practices were observed. Goral transportation boxes were too small for blue sheep (blue sheep has big lateral horns), so they were left at Dow Hill and in exchange PNHZP officials provided cages for blue sheep transportation.  Animal history, feeding and treatment record was provided by officials of PNHZP Darjeeling. Transit pass issued by Director PNHZP for transportation of animals/ birds from PNHZP Darjeeling to HNP Kufri was also taken.
                                <img src="/assets/images/updates/2024/march/001/image010.png" class="img-round-corner" style=" width : 100%; max-width : 100%;"> 
                            </p> 
                            <br>
                            <h4 class="mb-4 text-light">Capture and loading of animals and birds at Dow Hill, Darjeeling</h4>                             
                            <p>  
                                The animals and birds identified for exchange at Dow Hill were captured and shifted to transportation cages on the afternoon of 23rd November,2023. Feed and fodder required during the  journey was provided by PNHZP. The cages were loaded on Pickup trucks and team departed around 5 p.m. from Dow Hill, Darjeeling. It took around 2 hours to reach Sukna where our truck was stationed. On reaching Sukna Forest Rest House, the cages were unloaded from Pick ups and loaded on the truck. The cages were secured and team started its journey around 8:30 p.m. On return journey same route was followed except in Bihar where Chhapra District was by passed due to bad road conditions. Short halts were taken after every 5-6 hours of journey, for feeding and examination of animals/birds. During transit juicy fruits like oranges, pomegranates, watermelon and cucumber were provided to pheasants. This helped in keeping pheasants hydrated as they don’t drink water during short halts. After continuously traversing for more than 60 hours and covering about 1900 kms the team reached HNP Kufri at 5 A.M. on 26th November,2023. The cages were unloaded and animals/birds were shifted to quarantine enclosures. There was no mortality on return journey.    
                                <img src="/assets/images/updates/2024/march/001/image011.png" class="img-round-corner" style=" width : 100%; max-width : 100%;"> 
                            </p> 
                            <br>
                            <h4 class="mb-4 text-light">Challenges and learnings during transit</h4>                             
                            <p>  
                                <ol>
                                    <li>The team followed the route as per Google maps. Google maps provide shortest route and in cities we entered some roads on which heavy vehicles like trucks cannot ply, as a result truck driver was fined by traffic police. Long detours had to be taken to connect to next express ways. It is advised that before entering any city, enquiry be made from local authorities about the route to be taken.</li>
                                    <li>If pheasants are being transported in pairs there is a probability of in fighting, so, it is advisable to carry few spare transportation boxes for pheasants or transport pheasants singly</li>
                                    <li>Only professional drivers who are well versed with the route to be undertaken should be hired for seamless transportation</li>
                                </ol>
                            </p> 

                            <p style="text-align : right">
                                <b> ~ Content Credit<br>Dr. Rohit Sharma<br>Veterinary Officer, Kufri Zoo</b>
                            </p>




                            

                                                   


                            
                            

                        </MDBCardText>
                    </MDBCol>
                </MDBRow>                     
            </MDBContainer>
        </section>        
    </div>
</template>
<script>
  import { ref } from "vue";
  import { 
    MDBCol, MDBRow, MDBContainer,
    MDBInput,
    MDBCheckbox,
    MDBBtn,
    MDBTextarea,
    MDBModal,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBCarousel,
    MDBCardText,
    MDBCardImg, mdbRipple

  } from "mdb-vue-ui-kit";
  export default {
    name : "Updates_October_001",
    components: {
        MDBCol, MDBRow, MDBContainer,
        MDBInput,
        MDBCheckbox,
        MDBBtn,
        MDBTextarea,
        MDBModal,
        MDBModalHeader,
        MDBModalTitle,
        MDBModalBody,
        MDBModalFooter,
        MDBCarousel,
        MDBCardText,
        MDBCardImg
    },
    directives: {
      mdbRipple
    },
    setup() {
      const items1 = [
        {
          src: "/assets/images/contact-us.jpg",
          alt: "Shimla Wildlife Division",
        }
      ];
      const exampleModal = ref(false);
      const carousel1 = ref(0);
      return {
        exampleModal,
        items1,
        carousel1,
        form : {
            name : '',
            email : '',
            phone : '',
            query : '',
            city : '',
            newsletter : true
        },
        modal : {
            show : true,
            title : 'SUCCESS!!',
            body : 'Your query has been posted successfully! We will get back to you soon'
        }
      };
    },
    methods : {
        submitForm(){

            this.exampleModal = true;
        }
    }
  };
</script>
<style scoped>

    table, th, td {
        border: 1px solid white;
        border-collapse: collapse;
    }

    th, td {
        padding: 4px;
    }
    .bg-dark-green {
        background-color: rgb(77,169,80);
    }

    .para-img {
        float: right;
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
    }

    .bg-contact {
        background: linear-gradient(rgba(44, 95, 45, 0.5), rgba(44, 95, 45,0.5)), url('/assets/img/contact-bg.svg');
    }

    .float-left {
        float: left !important;
    }

    .mr-20 {
        margin-right : 20px
    }

    .ml-20 {
        margin-left : 20px
    }

    .bg-trago {
        background: linear-gradient(rgba(44, 95, 45, 0.9), rgba(44, 95, 45,0.9)), url('/assets/img/re_6.jpeg') center ;
    }

    .bg-forest {
        background: url('/assets/img/Untitled design-15.png') ;
        background-size: cover;
    }

    .img-round-corner {
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
    }

    .bg-form {
        background-color : rgb(66,136,121);
    }

    .form-title {

        border-left: 5px solid rgb(138,250,160);
        padding-left: 10px;
    }

    .form-color {
        color : #4F4F4F !important;
        border-color: transparent !important;
        background-color: white !important;
    }
    .update-container {
        overflow: hidden;        
    }
    .responsive-iframe {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

</style>