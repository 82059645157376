<template>
    <div>
        <MDBCarousel
            v-model="carousel1"
            :items="items1"
            fade
            :controls="false"
            :indicators="false"
        />
        <section >
            <MDBContainer class="p-5">
                <MDBRow class="mb-2">
                    <MDBCol class="mb-2 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                <!-- <span class="para-img ml-20" style="text-align : right">
                                    <img src="/assets/images/rescue-banner" class="img-round-corner" style="max-height : 375px; width : auto; max-width : 100%;">
                                    <br>
                                    <small><i>- Souvenir Shop at HNP Kufri</i></small>
                                </span> -->
                                The state of Himachal Pradesh witnessed rising monkey conflict in the start of 21st century owing to increased population of Rhesus Macaques. The conflict was severe in case of agricultural depredation with great losses caused to the farmers of state. Further, attacks on human beings b Rhesus macaques were also increasing. In this context, Himachal Pradesh became the pioneer state in the country to have started the innovative programme of monkey sterilization in the year 2006 to control the constantly increasing population of monkeys and provide relief to farmers and public. 
                                <br><br>
                                The sterilization programme is run through 8 monkey sterilization centers of which Shimla Wildlife Division is managing monkey sterilization centers at Shimla and Paonta. In the state, the programme has seen a great success in reducing the population of monkeys from above 3 lakh in 2004 to 136443 in the latest monkey census conducted in 2020. 
                            </p>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>
                <!-- <h4 class="mb-4 text-dark">Location & Visitor Details</h4>
                <MDBRow class="mb-5">
                    <MDBCol class="mb-4 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                <span class="para-img float-left mr-20" style="text-align : right; margin-right: 30px;">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13665.640154428696!2d77.2629535!3d31.0983336!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390583e8eaaaaaad%3A0xbad8262fd20535fa!2sKufri%20Zoo!5e0!3m2!1sen!2sin!4v1678864267194!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </span>
                                The Kufri Zoo being located at a tourist hotspot town at Kufri, which is an hour drive away from Shimla. The nearest railway station is at Shimla whereas the nearest Airport is at Chandigarh. The park is situated at tourist town of Kufri The annual footfall of the Kufri Zoo is around 60,000-70,000 visitors and is seeing a steady increase every year.
                                <br><br>
                                Entry Charges
                                <ul>
                                    <li>₹50/- for adults </li>
                                    <li>₹25/- for children </li>
                                </ul>
                                Zoo Timmings
                                <ul>
                                    <li>Winter - 09:30 Hrs. to 17:30 Hrs.</li>
                                    <li>Summer - 09:00 Hrs. to 18:30 Hrs.</li>
                                    <li><i>Off Day - Tuesday</i></li>
                                </ul>
                                Best Period to visit: 
                                <ul>
                                    <li>April – June</li>
                                    <li>September – November</li>
                                </ul>
                            </p>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow> -->
                <h4 class="mb-4 text-dark">Sterilization Process</h4>
                <MDBRow class="">
                    <MDBCol class="mb-4 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                <!-- <span class="para-img ml-20" style="text-align : right">
                                    <img src="/assets/images/hnp-visitor.png" class="img-round-corner" style="max-height : 375px; width : auto; max-width : 100%;">
                                    <br>
                                    <small><i>- Visit of School Children at HNP Kufri</i></small>
                                </span> -->
                                <ol>
                                    <li>
                                        <b>Capture</b>
                                        <p>
                                            The process of sterilization starts from the field where the territorial divisions capture monkeys through specially designed cages for safe capture. The idea is to capture the whole group of Rhesus Macaques to avoid group splitting and to sterilize the entire group except the young and pregnant. The captured monkeys are then shifted to transportation cage and transported to Sterilization centers.
                                        </p>
                                    </li>
                                    <li>
                                        <b>Pre-Cage Stage</b>
                                        <p>
                                            The captured monkeys are kept in the pre care ward for a period of around 3 days during which they are kept under observation. Feed diet during the period is prescribed by veterinary officer in charge of the MSC.    
                                        </p>
                                    </li>
                                    <li>
                                        <b>Sterilization</b>
                                        <p>
                                            The monkeys are administered anaesthesia depending on their body weight by the veterinary pharmacist utilizing the squeeze cage in the pre-care ward. After this, the monkeys are shaven and restrained for process of sterilization. In the case of male monkeys, the sterilization is carried out through thermoquartery and in females the process is performed through laproscopy. 
                                        </p>
                                    </li>
                                    <li>
                                        <b>Shifting to Post Care Ward</b>
                                        <p>
                                            Once the sterilization process is performed, the Rhesus Macaques are shifted to post care ward after tattoo to identify the sterilized monkeys. They are kept in observation in post care ward following which they are released back into the open from the same place they were captured.
                                        </p>
                                    </li>                                   
                                </ol>
                            </p>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>
                <MDBRow>    
                    <MDBCol class="mb-4" md="3" xs="3">
                        <MDBCard>
                            <a v-mdb-ripple="{ color: 'dark' }">
                                <MDBCardImg src="/assets/images/rescue_1.jpg" top style="max-height:200px; width: auto" />
                            </a>
                        </MDBCard>
                    </MDBCol>     
                    <MDBCol class="mb-4" md="3" xs="3">
                        <MDBCard>
                            <a v-mdb-ripple="{ color: 'dark' }">
                                <MDBCardImg src="/assets/images/rescue_4.jpg" top style="max-height:200px; width: auto"/>
                            </a>
                        </MDBCard>
                    </MDBCol>   
                    <MDBCol class="mb-4" md="3" xs="3">
                        <MDBCard>
                            <a v-mdb-ripple="{ color: 'dark' }">
                                <MDBCardImg src="/assets/images/rescue_3.jpg" top style="max-height:200px; width: auto"/>
                            </a>
                        </MDBCard>
                    </MDBCol> 
                    <MDBCol class="mb-4" md="3" xs="3">
                        <MDBCard >
                            <a v-mdb-ripple="{ color: 'dark' }">
                                <MDBCardImg src="/assets/images/rescue_2.jpg" top style="max-height:200px; width: auto"/>
                            </a>
                        </MDBCard>
                    </MDBCol>         
                                        
                </MDBRow>
                <h4 class="mb-4 text-dark">Monkey Sterilization Centre at Shimla</h4>
                <MDBRow class="">
                    <MDBCol class="mb-4 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                <!-- <span class="para-img ml-20" style="text-align : right">
                                    <img src="/assets/images/hnp-visitor.png" class="img-round-corner" style="max-height : 375px; width : auto; max-width : 100%;">
                                    <br>
                                    <small><i>- Visit of School Children at HNP Kufri</i></small>
                                </span> -->
                                The Monkey Sterilization Centre at Shimla is based on feeder divisions in districts of Shimla, Solan which provides captured monkeys for sterilization. The center is looked after by Veterinary Doctor in charge who is experienced and trained in the process of sterilization. Currently, the centre has a well equipped Operation Theatre with HD endoscopic camera, monitor for laproscopic surgery, fibre optic light source, telescope, CO2 gas insufflators, electro surgical units etc.
                                <br><br>
                                The Pre and Post Care wards are also in place in the centre. The squeeze cages are constantly updated to ensure they remain functional. Considering the need for bio-security and hygiene in the premises, footbaths are made operational in wards and specialized clothing is provided to the workers handling monkeys. Regular health check up is also conducted for workers handling monkeys.
                            </p>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>
                <h4 class="mb-4 text-dark">Monkey Sterilization Centre at Paonta Sahib</h4>
                <MDBRow class="">
                    <MDBCol class="mb-4 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                <!-- <span class="para-img ml-20" style="text-align : right">
                                    <img src="/assets/images/hnp-visitor.png" class="img-round-corner" style="max-height : 375px; width : auto; max-width : 100%;">
                                    <br>
                                    <small><i>- Visit of School Children at HNP Kufri</i></small>
                                </span> -->
                                This MSC is in the midst of Paonta city in Sirmour District of Himachal Pradesh. It caters to population control of Rhesus macaques in Sirmour District. Veterinary Officer under HP Zoo Conservation and Breeding society is in charge of this centre. The pre and post care ward infrastructure is in functional condition and cleanliness of these facilities are ensured. The Operation Theatre facility where the sterilization procedure is performed is also well equipped.
                            </p>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </section>        
        
        
    </div>
</template>
<script>
  import { ref } from "vue";
  import { 
    MDBCarousel,
    MDBCol, MDBRow, MDBContainer,
    MDBCardText,
    MDBCard, MDBCardHeader, MDBCardBody, MDBCardTitle, MDBCardImg, mdbRipple
  } from "mdb-vue-ui-kit";
  export default {
    name : "Daranghati",
    components: {
      MDBCarousel,
      MDBCol, MDBRow, MDBContainer,
      MDBCardText,
      MDBCard, MDBCardHeader, MDBCardBody, MDBCardTitle, MDBCardImg
    },
    directives: {
      mdbRipple
    },
    setup() {
      const items1 = [
        {
          src: "/assets/images/rescue_banner.jpg",
          alt: "Monkey Sterilization Centre",
        }
      ];
      const carousel1 = ref(0);
      return {
        items1,
        carousel1,
      };
    }
  };
</script>
<style scoped>

    /* GRADIENTS */
    .winter-neva-gradient {
        background-image: linear-gradient(120deg,#a1c4fd,#c2e9fb);
    }

    .deep-blue-gradient {
        background-image: linear-gradient(120deg,#e0c3fc,#8ec5fc);
    }
    /* GRADIENTS END */

    .gradient-text {
        line-height: 200px; 
        font-size : 24px; 
        letter-spacing : 2px
    }

    .z-depth-1-half {
        -webkit-box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)!important;
        box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)!important;
    }

    .bg-dark-green {
        background-color: rgb(77,169,80);
    }

    .para-img {
        float: right;
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
    }

    .bg-contact {
        background: linear-gradient(rgba(44, 95, 45, 0.5), rgba(44, 95, 45,0.5)), url('/assets/img/contact-bg.svg');
    }

    .float-left {
        float: left !important;
    }

    .mr-20 {
        margin-right : 20px
    }

    .ml-20 {
        margin-left : 20px
    }

    .img-round-corner {
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
    }

    h4 {
        margin: 0;  
    }
    .color-block-5 {
        height: 200px;
        width: 200px;
    }
    .img-gradient{
        position:relative;
        display:inline-block;
    }
    .img-gradient:after {
        content:'';
        position:absolute;
        left:0; top:0;
        width:100%; height:100%;
        display:inline-block;
        background: -moz-linear-gradient(top, rgba(0,47,75,0.5) 0%, rgba(220, 66, 37, 0.5) 100%); /* FF3.6+ */
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(220, 66, 37, 0.5)), color-stop(100%,rgba(0,47,75,0.5))); /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* Opera 11.10+ */
        background: -ms-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* IE10+ */
        background: linear-gradient(to bottom, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002f4b', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
    }
    .img-gradient img{
        display:block;
    }

</style>