<template>
    <div>
        <MDBCarousel
            v-model="carousel1"
            :items="items1"
            fade
            :controls="false"
            :indicators="false"
        />
        <section class="bg-forest">
            <MDBContainer>
                <MDBRow class="p-5">
                    <MDBCol md="6" xs="12" class="text-dark">
                        <h4>Contact Information</h4>
                        <p>
                            <b>NOTE:</b>
                            <br>
                            If you have any queries regarding visit to the park, making bookings for treks, stay etc or for sending any feedback to us, please feel free to fill the form below.
                        </p>
                        <b><u>OFFICE ADDRESS</u></b>
                        <br>
                        <div style="margin-left : 30px">
                            Dy. Conservator Of Forests,<br>
                            Wildlife Division Shimla,<br>
                            Mist Chamber Khalini <br>
                            Shimla Pin, No. - 171002
                        </div>
                        <br><br>
                        <b><u>Contact Details</u></b>
                        <br>
                        <div style="margin-left : 30px">
                            Dy. Conservator Of Forests, Shimla WL
                            <br><br>
                            Tel: +91-1234-xxxxxx (Office – DFO Shimla)<br>
                            Fax: +91-1234-xxxxxx<br>
                            E-mail: dfowlshi-hp@nic.in<br>
                            <br>
                            Facilitator<br>
                            Mobile no- (+91) – 1234-xxxxx
                        </div>
                        <br><br>
                    </MDBCol>
                    <MDBCol md="6" xs="12" class="">
                        <img src="/assets/img/contact_us.svg" style="max-width : 100%" class="mb-5">
                        <br>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1460.6177838307215!2d77.17200089926514!3d31.088271139522746!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390579307c48f9b5%3A0xc02fdd5d108f30d4!2sHP%20Forest%20Complex!5e1!3m2!1sen!2sin!4v1678866409280!5m2!1sen!2sin" height="300" style="border:0; width : 100%" allowfullscreen="" loading="lazy"></iframe>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </section>

        <MDBModal
            id="exampleModal"
            tabindex="-1"
            labelledby="exampleModalLabel"
            v-model="exampleModal"
        >
            <MDBModalHeader>
                <MDBModalTitle id="exampleModalLabel"> {{modal.title}} </MDBModalTitle>
            </MDBModalHeader>
                <MDBModalBody>{{modal.body}}</MDBModalBody>
            <MDBModalFooter>
                <MDBBtn color="default" @click="exampleModal = false">Close</MDBBtn>
            </MDBModalFooter>
        </MDBModal>
        
    </div>
</template>
<script>
  import { ref } from "vue";
  import { 
    MDBCol, MDBRow, MDBContainer,
    MDBInput,
    MDBCheckbox,
    MDBBtn,
    MDBTextarea,
    MDBModal,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
      MDBCarousel,

  } from "mdb-vue-ui-kit";
  export default {
    name : "Pheasantry",
    components: {
        MDBCol, MDBRow, MDBContainer,
        MDBInput,
        MDBCheckbox,
        MDBBtn,
        MDBTextarea,
        MDBModal,
        MDBModalHeader,
        MDBModalTitle,
        MDBModalBody,
        MDBModalFooter,
      MDBCarousel,
    },
    directives: {
    },
    setup() {
      const items1 = [
        {
          src: "/assets/images/contact-us.jpg",
          alt: "Shimla Wildlife Division",
        }
      ];
      const exampleModal = ref(false);
      const carousel1 = ref(0);
      return {
        exampleModal,
        items1,
        carousel1,
        form : {
            name : '',
            email : '',
            phone : '',
            query : '',
            city : '',
            newsletter : true
        },
        modal : {
            show : true,
            title : 'SUCCESS!!',
            body : 'Your query has been posted successfully! We will get back to you soon'
        }
      };
    },
    methods : {
        submitForm(){

            this.exampleModal = true;
        }
    }
  };
</script>
<style >

    .bg-dark-green {
        background-color: rgb(77,169,80);
    }

    .para-img {
        float: right;
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
    }

    .bg-contact {
        background: linear-gradient(rgba(44, 95, 45, 0.5), rgba(44, 95, 45,0.5)), url('/assets/img/contact-bg.svg');
    }

    .float-left {
        float: left !important;
    }

    .mr-20 {
        margin-right : 20px
    }

    .ml-20 {
        margin-left : 20px
    }

    .bg-trago {
        background: linear-gradient(rgba(44, 95, 45, 0.9), rgba(44, 95, 45,0.9)), url('/assets/img/re_6.jpeg') center ;
    }

    .bg-forest {
        background: url('/assets/img/Untitled design-15.png') ;
        background-size: cover;
    }

    .img-round-corner {
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
    }

    .bg-form {
        background-color : rgb(66,136,121);
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
    }

    .form-title {

        border-left: 5px solid rgb(138,250,160);
        padding-left: 10px;
    }

    .form-color {
        color : #4F4F4F !important;
        border-color: transparent !important;
        background-color: white !important;
    }
    .contact-container {
        position: relative;
        overflow: hidden;
        height: 1350px;
        padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
    }
    .responsive-iframe {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

</style>