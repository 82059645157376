<template>
    <div>                         
        <section>
            <img src="/assets/images/updates/2024/january/cavity_nesting.png" class="img-round-corner" style="width : auto; width : 100%;">
        </section>        
    </div>
</template>
<script>
  import { ref } from "vue";
  import { 
    MDBCol, MDBRow, MDBContainer,
    MDBInput,
    MDBCheckbox,
    MDBBtn,
    MDBTextarea,
    MDBModal,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBCarousel,
    MDBCardText,
    MDBCardImg, mdbRipple

  } from "mdb-vue-ui-kit";
  export default {
    name : "Renukaji-Ficus-Membrane",
    components: {
        MDBCol, MDBRow, MDBContainer,
        MDBInput,
        MDBCheckbox,
        MDBBtn,
        MDBTextarea,
        MDBModal,
        MDBModalHeader,
        MDBModalTitle,
        MDBModalBody,
        MDBModalFooter,
        MDBCarousel,
        MDBCardText,
        MDBCardImg
    },
    directives: {
      mdbRipple
    },
    setup() {
      const items1 = [
        {
          src: "/assets/images/contact-us.jpg",
          alt: "Shimla Wildlife Division",
        }
      ];
      const exampleModal = ref(false);
      const carousel1 = ref(0);
      return {
        exampleModal,
        items1,
        carousel1,
        form : {
            name : '',
            email : '',
            phone : '',
            query : '',
            city : '',
            newsletter : true
        },
        modal : {
            show : true,
            title : 'SUCCESS!!',
            body : 'Your query has been posted successfully! We will get back to you soon'
        }
      };
    },
    methods : {
        submitForm(){

            this.exampleModal = true;
        }
    }
  };
</script>
<style scoped>

    .bg-dark-green {
        background-color: rgb(77,169,80);
    }

    .para-img {
        float: right;
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
    }

    .bg-contact {
        background: linear-gradient(rgba(44, 95, 45, 0.5), rgba(44, 95, 45,0.5)), url('/assets/img/contact-bg.svg');
    }

    .float-left {
        float: left !important;
    }

    .mr-20 {
        margin-right : 20px
    }

    .ml-20 {
        margin-left : 20px
    }

    .bg-trago {
        background: linear-gradient(rgba(44, 95, 45, 0.9), rgba(44, 95, 45,0.9)), url('/assets/img/re_6.jpeg') center ;
    }

    .bg-forest {
        background: url('/assets/img/Untitled design-15.png') ;
        background-size: cover;
    }

    .img-round-corner {
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
    }

    .bg-form {
        background-color : rgb(66,136,121);
    }

    .form-title {

        border-left: 5px solid rgb(138,250,160);
        padding-left: 10px;
    }

    .form-color {
        color : #4F4F4F !important;
        border-color: transparent !important;
        background-color: white !important;
    }
    .update-container {
        overflow: hidden;        
    }
    .responsive-iframe {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

</style>