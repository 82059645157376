<template>
    <div>
        <MDBCarousel
            v-model="carousel1"
            :items="items1"
            fade
            :controls="false"
            :indicators="false"
        />
        <section >
            <MDBContainer class="p-5">
                <h4 class="mb-4 text-dark">About Himalayan Nature Park</h4>
                <MDBRow class="mb-5">
                    <MDBCol class="mb-4 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                <span class="para-img ml-20" style="text-align : right">
                                    <img src="/assets/images/hnp-shop.png" class="img-round-corner" style="max-height : 375px; width : auto; max-width : 100%;">
                                    <br>
                                    <small><i>- Souvenir Shop at HNP Kufri</i></small>
                                </span>
                                Himalayan Nature Park Kufri, popularly known as ‘Kufri Zoo’ spread across an area of 13.73 Ha was established in 1992. Prior to setting up of the Nature Park, part of the present area of the park used to be the Musk Deer Farm since 1965. 
                                <br><br>
                                In the year 1996, it was recognized as <b>‘Himalayan Nature Park Kufri’</b> in the category of Small Zoo by the Central Zoo Authority of India. The Kufri Zoo is unique for being the Highest Altitude Zoo in India at a height of 2700 m above mean sea level. The Zoo is managed by Himachal Pradesh Wildlife Wing through the Shimla Wildlife Division.
                            
                            </p>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>
                <h4 class="mb-4 text-dark">Location & Visitor Details</h4>
                <MDBRow class="mb-5">
                    <MDBCol class="mb-4 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                <span class="para-img float-left mr-20" style="text-align : right; margin-right: 30px;">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13665.640154428696!2d77.2629535!3d31.0983336!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390583e8eaaaaaad%3A0xbad8262fd20535fa!2sKufri%20Zoo!5e0!3m2!1sen!2sin!4v1678864267194!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                    <!-- <img src="/assets/images/hnp-visitor.png" class="img-round-corner" style="max-height : 375px; width : auto; max-width : 100%; margin-right : 20px">
                                    <br>
                                    <small><i>- Visit of School Children at HNP Kufri</i></small> -->
                                </span>
                                The Kufri Zoo being located at a tourist hotspot town at Kufri, which is an hour drive away from Shimla. The nearest railway station is at Shimla whereas the nearest Airport is at Chandigarh. The park is situated at tourist town of Kufri The annual footfall of the Kufri Zoo is around 60,000-70,000 visitors and is seeing a steady increase every year.
                                <br><br>
                                Entry Charges
                                <ul>
                                    <li>₹50/- for adults </li>
                                    <li>₹25/- for children </li>
                                </ul>
                                Zoo Timmings
                                <ul>
                                    <li>Winter - 09:30 Hrs. to 17:30 Hrs.</li>
                                    <li>Summer - 09:00 Hrs. to 18:30 Hrs.</li>
                                    <li><i>Off Day - Tuesday</i></li>
                                </ul>
                                Best Period to visit: 
                                <ul>
                                    <li>April – June</li>
                                    <li>September – November</li>
                                </ul>
                            </p>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>
                <h4 class="mb-4 text-dark">Mission of Zoo</h4>
                <MDBRow class="">
                    <MDBCol class="mb-4 item-align-center mx-auto" md="12" xs="12">
                        <MDBCardText style="text-align : justify;" class="">
                            <p>
                                <span class="para-img ml-20" style="text-align : right">
                                    <img src="/assets/images/hnp-visitor.png" class="img-round-corner" style="max-height : 375px; width : auto; max-width : 100%;">
                                    <br>
                                    <small><i>- Visit of School Children at HNP Kufri</i></small>
                                </span>
                                <ul>
                                    <li>Develop amongst visitors empathy towards wild animals and motivate them to support the cause of conservation of wildlife</li>
                                    <br><li>Develop amongst visitors awareness on Western Himalayan Fauna and Flora</li>
                                    <br><li>Develop amongst the visitors an understanding about the ecological linkages with the life supporting processes of nature and living in harmony with nature</li>
                                    <br><li>Assisting the national efforts for conservation of the Western Himalayan eco-system by ex-situ conservation, breeding of its endangered fauna, conservation education and research to augment these conservation initiatives </li>
                                </ul>
                            </p>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </section>
        <section >
            <MDBContainer class="p-5">
                <h5 class="text-center ">ANIMALS YOU CAN SEE</h5>
                <br>
                <MDBContainer fluid>
                    <MDBRow >
                        <MDBCol md="12" xs="12" class="mx-auto text-center">
                            <vue-horizontal responsive>
                                <section v-for="item in photos" :key="item.title">
                                    <MDBCard style="margin-right:30px">
                                        <a v-mdb-ripple="{ color: 'light' }">
                                            <MDBCardImg :src="item.img" top :alt="item.name" class="img-cut-edge" style="max-height :245px"/>
                                        </a>
                                        <MDBCardBody>
                                            
                                            <MDBCardText style="text-align: center">
                                                {{ item.name }}
                                            </MDBCardText>
                                        </MDBCardBody>
                                    </MDBCard>
                                </section>
                            </vue-horizontal>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>                
            </MDBContainer>
        </section>
        <!-- <section class="bg-contact p-5">
            <MDBContainer>
                
                <MDBRow>
                    <MDBCol md="6" class="mx-auto mb-5">
                        <MDBCard text="center">
                            <MDBCardHeader class="bg-dark-green text-light"><h3>ACCOMODATION</h3></MDBCardHeader>
                            <MDBCardBody>
                                <MDBCardText>
                                    Forest Rest House at Kufri
                                </MDBCardText>                                
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol md="6" class="mx-auto">
                        <MDBCard text="center">
                            <MDBCardHeader class="bg-dark-green text-light"><h3>CONTACT OFFICE</h3></MDBCardHeader>
                            <MDBCardBody>
                                <MDBCardText>
                                    Range Forest Officer , 
                                    <br>
                                    ----
                                    <br>
                                    Telephone: xxxx xxxx.
                                </MDBCardText>                                
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </section> -->
        
    </div>
</template>
<script>
  import { ref } from "vue";
  import VueHorizontal from "vue-horizontal";
  import { 
    MDBCarousel,
    MDBCol, MDBRow, MDBContainer,
    MDBCardText,
    MDBCard, MDBCardHeader, MDBCardBody, MDBCardTitle, MDBCardImg, mdbRipple
  } from "mdb-vue-ui-kit";
  export default {
    name : "Daranghati",
    components: {
      MDBCarousel,
      MDBCol, MDBRow, MDBContainer,
      MDBCardText, VueHorizontal,
      MDBCard, MDBCardHeader, MDBCardBody, MDBCardTitle, MDBCardImg
    },
    directives: {
      mdbRipple
    },
    setup() {
      const items1 = [
        {
          src: "/assets/images/hnp-1.png",
          alt: "Himalayan Nature Park",
          caption : ""
        }
      ];
      const carousel1 = ref(0);
      return {
        items1,
        carousel1,
        photos: [
          {
            img : '/assets/images/hnp-animal-1.png',
            name : 'Himalayan Brown Bear'
          },
          {
            img : '/assets/images/hnp-animal-2.png',
            name : 'Himalayan Monal'
          },
          {
            img : '/assets/images/hnp-animal-3.png',
            name : 'Western Tragopan'
          },
          {
            img : '/assets/images/hnp-animal-4.png',
            name : 'Himalayan Tahr'
          },
          {
            img : '/assets/images/hnp-animal-5.png',
            name : 'Himalayan Must Deer'
          },
        ]
      };
    }
  };
</script>
<style scoped>

    /* GRADIENTS */
    .winter-neva-gradient {
        background-image: linear-gradient(120deg,#a1c4fd,#c2e9fb);
    }

    .deep-blue-gradient {
        background-image: linear-gradient(120deg,#e0c3fc,#8ec5fc);
    }
    /* GRADIENTS END */

    .gradient-text {
        line-height: 200px; 
        font-size : 24px; 
        letter-spacing : 2px
    }

    .z-depth-1-half {
        -webkit-box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)!important;
        box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)!important;
    }

    .bg-dark-green {
        background-color: rgb(77,169,80);
    }

    .para-img {
        float: right;
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
    }

    .bg-contact {
        background: linear-gradient(rgba(44, 95, 45, 0.5), rgba(44, 95, 45,0.5)), url('/assets/img/contact-bg.svg');
    }

    .float-left {
        float: left !important;
    }

    .mr-20 {
        margin-right : 20px
    }

    .ml-20 {
        margin-left : 20px
    }

    .img-round-corner {
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
    }

    h4 {
        margin: 0;  
    }
    .color-block-5 {
        height: 200px;
        width: 200px;
    }
    .img-gradient{
        position:relative;
        display:inline-block;
    }
    .img-gradient:after {
        content:'';
        position:absolute;
        left:0; top:0;
        width:100%; height:100%;
        display:inline-block;
        background: -moz-linear-gradient(top, rgba(0,47,75,0.5) 0%, rgba(220, 66, 37, 0.5) 100%); /* FF3.6+ */
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(220, 66, 37, 0.5)), color-stop(100%,rgba(0,47,75,0.5))); /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* Opera 11.10+ */
        background: -ms-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* IE10+ */
        background: linear-gradient(to bottom, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002f4b', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
    }
    .img-gradient img{
        display:block;
    }

</style>