<template>
    <div>
        <MDBCarousel
            v-model="carousel1"
            :items="items1"
            fade
            :controls="false"
            :indicators="false"
        />
        <section class="">
            <MDBContainer class="p-5">
                <MDBRow class="">
                    <MDBCol class="item-align-center mx-auto" md="12" xs="12">
                        <MDBCard text="center ">
                            <MDBCardBody>
                                <MDBCardText style="text-align : justify;" class="">
                                    <p style="font-size: 22px;">
                                        <span class="para-img ml-20" style="text-align : right">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d13693.529240981747!2d77.54896030468531!3d30.456908478143955!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzDCsDI3JzIyLjMiTiA3N8KwMzMnMjAuNyJF!5e1!3m2!1sen!2sin!4v1682475553257!5m2!1sen!2sin" width="600" height="250" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </span>
                                        The Amargarh Inspection Hut is located adjacent to  the Range Forest Office managing Colonel Sher Jung National Park. It is a notable facility that facilitates inspection of various works in national park and for holding various workshops related to wildlife management.
                                    </p>
                                </MDBCardText>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </section>
        <section >
            <MDBContainer class="p-5" fluid>                
                <MDBRow>
                    <MDBCol class="mb-9 mx-auto" md="3" xs="6">
                        <MDBCard>
                            <MDBCardImg
                                src="/assets/images/frh/amargarh/01.jpeg"
                                alt="..."
                                style="max-height : 300px"
                            >
                            </MDBCardImg>       
                        </MDBCard>
                    </MDBCol>
                    <MDBCol class="mb-9 mx-auto" md="3" xs="6">
                        <MDBCard>
                            <MDBCardImg
                                src="/assets/images/frh/amargarh/02.jpeg"
                                alt="..."
                                style="max-height : 300px"
                            >
                            </MDBCardImg>       
                        </MDBCard>
                    </MDBCol>
                    <MDBCol class="mb-9 mx-auto" md="3" xs="6">
                        <MDBCard>
                            <MDBCardImg
                                src="/assets/images/frh/amargarh/03.jpeg"
                                alt="..."
                                style="max-height : 300px"
                            >
                            </MDBCardImg>       
                        </MDBCard>
                    </MDBCol>     
                    <MDBCol class="mb-9 mx-auto" md="3" xs="6">
                        <MDBCard>
                            <MDBCardImg
                                src="/assets/images/frh/amargarh/04.jpeg"
                                alt="..."
                                style="max-height : 300px"
                            >
                            </MDBCardImg>       
                        </MDBCard>
                    </MDBCol>    
                </MDBRow>       
            </MDBContainer>
        </section>
        <section class="bg-trago">
            <MDBContainer>
                <MDBRow class="p-5">
                <MDBCol md="8" xs="12" class="text-light">
                    <p style="font-size : 20px">
                    Provide us with your valuable feedback about our resthouse!
                    </p>
                </MDBCol>
                <MDBCol md="4" xs="12" class="mb-3 text-center d-flex justify-content-center align-items-center">
                    <MDBBtn color="success" class="text-center" @click="openPage()">GIVE FEEDBACK NOW!</MDBBtn>
                </MDBCol>
                </MDBRow>
            </MDBContainer>
        </section>
        <section class="bg-contact p-5">
            <MDBContainer>
                <MDBRow>
                    <MDBCol md="6" class="mx-auto mb-5">
                        <MDBCard text="center">
                            <MDBCardHeader class="bg-dark-green text-light"><h3>How to book?</h3></MDBCardHeader>
                            <MDBCardBody>
                                <MDBCardText>
                                    DCF Wildlife Shimla Office
                                    <br>
                                    <i class="fa fa-phone"></i> +91 177 262 3993
                                </MDBCardText>                                
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol md="6" class="mx-auto mb-5">
                        <MDBCard text="center">
                            <MDBCardHeader class="bg-dark-green text-light"><h3>CONTACT</h3></MDBCardHeader>
                            <MDBCardBody>
                                <MDBCardText>                                    
                                    <b style="font-size: 22px;">Shri Ram Pal</b><br>Dy. Ranger<br><i class="fa fa-phone"></i> +91 98167 65887<br><br>
                                    
                                    <b style="font-size: 22px;">Ankush</b><br>Forest Guard<br><i class="fa fa-phone"></i>  +91 78340 51064
                                </MDBCardText>                                
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
                
            </MDBContainer>
        </section>
        
    </div>
</template>
<script>
  import { ref } from "vue";
  import VueHorizontal from "vue-horizontal";
  import VueGallery from 'vue-gallery'
  import { 
    MDBCarousel,
    MDBCol, MDBRow, MDBContainer,
    MDBCardText,
    MDBCard, MDBCardHeader, MDBCardBody, MDBCardTitle, MDBCardImg, mdbRipple, MDBBtn
    
  } from "mdb-vue-ui-kit";
  export default {
    name : "Daranghati",
    components: {
      MDBCarousel,
      MDBCol, MDBRow, MDBContainer,
      MDBCardText, VueHorizontal,
      MDBCard, MDBCardHeader, MDBCardBody, MDBCardTitle, MDBCardImg,MDBBtn,
      'gallery': VueGallery
    },
    directives: {
      mdbRipple
    },
    methods : {
      openPage() {
        window.open(`/feedback-resthouse`, '_blank');
      }
    },
    setup() {
      const items1 = [
        {
          src: "/assets/images/frh/amargarh/banner.jpg",
          alt: "Amargarh Inspection Hut",
          caption : ""
        }
      ];
      const carousel1 = ref(0);
      return {
        images: [
        ],
        index: null,
        items1,
        carousel1,
        photos: [
          
        ]
      };
    }
  };
</script>


<style scoped>

    /* GRADIENTS */
    .winter-neva-gradient {
        background-image: linear-gradient(120deg,#a1c4fd,#c2e9fb);
    }

    .deep-blue-gradient {
        background-image: linear-gradient(120deg,#e0c3fc,#8ec5fc);
    }
    /* GRADIENTS END */

    .gradient-text {
        line-height: 200px; 
        font-size : 24px; 
        letter-spacing : 2px
    }

    .z-depth-1-half {
        -webkit-box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)!important;
        box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)!important;
    }

    .bg-dark-green {
        background-color: rgb(77,169,80);
    }

    .para-img {
        float: right;
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
    }

    .bg-contact {
        background: linear-gradient(rgba(44, 95, 45, 0.5), rgba(44, 95, 45,0.5)), url('/assets/img/contact-bg.svg');
    }

    .float-left {
        float: left !important;
    }

    .mr-20 {
        margin-right : 20px
    }

    .ml-20 {
        margin-left : 20px
    }

    .img-round-corner {
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
    }

    h4 {
        margin: 0;  
    }
    .color-block-5 {
        height: 200px;
        width: 200px;
    }
    .img-gradient{
        position:relative;
        display:inline-block;
    }
    .img-gradient:after {
        content:'';
        position:absolute;
        left:0; top:0;
        width:100%; height:100%;
        display:inline-block;
        background: -moz-linear-gradient(top, rgba(0,47,75,0.5) 0%, rgba(220, 66, 37, 0.5) 100%); /* FF3.6+ */
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(220, 66, 37, 0.5)), color-stop(100%,rgba(0,47,75,0.5))); /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* Opera 11.10+ */
        background: -ms-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* IE10+ */
        background: linear-gradient(to bottom, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002f4b', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
    }
    .img-gradient img{
        display:block;
    }

</style>