<template>
    <section class="bg-forest">
        <MDBContainer class="p-5 text-center" style="opacity : 1">
            <h3 class="text-center">Our Publications</h3>
            <br>
            <MDBRow>
                <MDBCol class="mb-9 mx-auto" md="3" xs="3">
                    <MDBCard @click="onSelectRow('https://drive.google.com/file/d/1aYMqTE8l2IyrhK2S6XCRf0BalbDlDHWe/view?usp=share_link')">
                    <a v-mdb-ripple="{ color: 'light' }">
                        <MDBCardImg src="/assets/images/publications/survey_koklass_cheer_phesants.jpg" top alt="SURVEY OF KOKLASS AND CHEER PHEASANT IN SHIMLA WATER CATCHMENT WILDLIFE SANCTUARY" />
                    </a>
                    </MDBCard>
                </MDBCol>
                <MDBCol class="mb-9 mx-auto" md="3" xs="3">
                    <MDBCard @click="onSelectRow('https://drive.google.com/file/d/1Ab2Xhn5o9b5jyE4Mf41yRBvZkd_J2B6z/view?usp=share_link')">
                    <a v-mdb-ripple="{ color: 'light' }">
                        <MDBCardImg src="/assets/images/publications/bi_annual_report.jpg" top alt="Bi annual report of Shimla Wildlife Division" />
                    </a>
                    </MDBCard>
                </MDBCol>
                <MDBCol class="mb-9 mx-auto" md="3" xs="3">
                    <MDBCard @click="onSelectRow('https://drive.google.com/file/d/1dRrvJ_euG1Ro3btZ_W5po6SWNvlcXNPC/view?usp=share_link')">
                    <a v-mdb-ripple="{ color: 'light' }">
                        <MDBCardImg src="/assets/images/publications/data_collection_simbalbara.jpg" top alt="Wildlife Data Collection of Simbalbara Range" />
                    </a>
                    </MDBCard>
                </MDBCol>
                <MDBCol class="mb-9 mx-auto" md="3" xs="3">
                    <MDBCard @click="onSelectRow('https://docs.google.com/document/d/11IQqXBC6CfLF9xS4w89AluvZkNzQ_P0q/edit?usp=share_link&ouid=112511286226438015685&rtpof=true&sd=true')">
                    <a v-mdb-ripple="{ color: 'light' }">
                        <MDBCardImg src="/assets/images/publications/majathal.jpg" top alt="Faunal Estimation Study ReportFaunal Estimation Study Report, Majathal Wildlife Sanctuary" />
                    </a>
                    </MDBCard>
                </MDBCol>
                <MDBCol class="mb-9 mx-auto" md="3" xs="3">
                    <MDBCard @click="onSelectRow('https://drive.google.com/file/d/1yZ5n88GYDPBmOj2mswX8HpZR-p1U-xIR/view?ts=652d06fd')">
                    <a v-mdb-ripple="{ color: 'light' }">
                        <MDBCardImg src="/assets/images/publications/intensive_survey.jpg" top alt="INTENSIVE SITE SURVEY For Identification of Alternate Release Site for Cheer Pheasant Reintroduction in Himachal Pradesh" />
                    </a>
                    </MDBCard>
                </MDBCol>
                <MDBCol class="mb-9 mx-auto" md="3" xs="3">
                    <MDBCard @click="onSelectRow('https://drive.google.com/file/d/1YiQJJRN6D4wXLqqL5vQ2-xRrVL0GZSax/view?usp=drive_link')">
                    <a v-mdb-ripple="{ color: 'light' }">
                        <MDBCardImg src="/assets/images/publications/mp_water_catchment.jpg" top alt="" />
                    </a>
                    </MDBCard>
                </MDBCol>
                <MDBCol class="mb-9 mx-auto" md="3" xs="3">
                    <MDBCard @click="onSelectRow('https://drive.google.com/file/d/1lwXzzg6UoEpHsFk1DxbeIKqmUvNwUEzG/view?usp=drive_link')">
                    <a v-mdb-ripple="{ color: 'light' }">
                        <MDBCardImg src="/assets/images/publications/mp_chail_ws.jpg" top alt="" />
                    </a>
                    </MDBCard>
                </MDBCol>
                <MDBCol class="mb-9 mx-auto" md="3" xs="3">
                    <MDBCard @click="onSelectRow('https://drive.google.com/file/d/10aKezv-peJjYhoEoNZvqVCe3UE06GI7Y/view?usp=drive_link')">
                    <a v-mdb-ripple="{ color: 'light' }">
                        <MDBCardImg src="/assets/images/publications/mp_churdhar.jpg" top alt="" />
                    </a>
                    </MDBCard>
                </MDBCol>
                <MDBCol class="mb-9 mx-auto" md="3" xs="3">
                    <MDBCard @click="onSelectRow('https://drive.google.com/file/d/1Pil8XlqLFTRYKu_FBezVOKUjOmC_Qu_I/view?usp=drive_link')">
                    <a v-mdb-ripple="{ color: 'light' }">
                        <MDBCardImg src="/assets/images/publications/mp_sher_jung.jpg" top alt="" />
                    </a>
                    </MDBCard>
                </MDBCol>
                <MDBCol class="mb-9 mx-auto" md="3" xs="3">
                    <MDBCard @click="onSelectRow('https://drive.google.com/file/d/1bzePs1nfUhq69CBxcVcxSp3PIMNXh5_-/view?usp=drive_link')">
                    <a v-mdb-ripple="{ color: 'light' }">
                        <MDBCardImg src="/assets/images/publications/mp_majathal.jpg" top alt="" />
                    </a>
                    </MDBCard>
                </MDBCol>
                <MDBCol class="mb-9 mx-auto" md="3" xs="3">
                    <MDBCard @click="onSelectRow('https://drive.google.com/file/d/1GCKQ4DNyR6jf0roKo_RoUdwhsheSQsVW/view?usp=drive_link')">
                    <a v-mdb-ripple="{ color: 'light' }">
                        <MDBCardImg src="/assets/images/publications/mp_renuka.jpg" top alt="" />
                    </a>
                    </MDBCard>
                </MDBCol>
                <MDBCol class="mb-9 mx-auto" md="3" xs="3">
                    <MDBCard @click="onSelectRow('https://drive.google.com/file/d/1gQDROoM8Yg1S3yLm2Wn1X5j7K_KHAXnI/view?usp=drive_link')">
                    <a v-mdb-ripple="{ color: 'light' }">
                        <MDBCardImg src="/assets/images/publications/mp_talra.jpg" top alt="" />
                    </a>
                    </MDBCard>
                </MDBCol>
                
            </MDBRow>
        </MDBContainer>
        <!-- <MDBContainer>
            <MDBRow class="p-2 pt-5 pb-5">
                <MDBCol md="12" xs="12" class="text-dark mx-auto">
                    <h4>Our Publications</h4>
                    <MDBTable>
                        <thead>
                        <tr>
                            <th scope="col">Title</th>
                            <th scope="col">Link</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr @click="onSelectRow('2023-August-001')">
                            <td>Operation of Renuka Ji Souvenir Shop by Women-led SHG</td>
                            <td style="color: blue;">Click here to read more</td>
                        </tr>
                        </tbody>
                    </MDBTable>
                    
                </MDBCol>
            </MDBRow>
        </MDBContainer> -->
    </section>
</template>

<script>
import { ref } from "vue";
import { 
    MDBTable,
    MDBCol, MDBRow, MDBContainer, 
    MDBCarousel, MDBCardText, MDBCardFooter,
    MDBCard, MDBCardBody, MDBCardTitle, MDBCardImg, MDBBtn, mdbRipple
} from 'mdb-vue-ui-kit';
import VueHorizontal from "vue-horizontal";
export default {
    name : 'Publications',
    components: {
      MDBCarousel, MDBCol, MDBRow, MDBContainer, MDBCardText, MDBCardFooter,
      MDBCard, MDBCardBody, MDBCardTitle,MDBCardImg, MDBBtn,
      VueHorizontal
    },
    directives: {
      mdbRipple
    },
    methods : {
        onSelectRow(url){
            window.open(`${url}`, '');

        },
    }
};
</script>

<style scoped>
  .img-cut-edge {
    -webkit-clip-path: polygon(0% 0%, 90% 0, 100% 10%, 100% 100%, 0% 100%);
    clip-path: polygon(0% 0%, 90% 0, 100% 10%, 100% 100%, 0% 100%);
  }
  section {
    padding: 16px 24px;
    background: #f5f5f5;
  }
  .bg-light-grey {
    background-color: #f5f5f5;
  }
  .bg-dark-green {
    background-color: rgb(77,169,80);
  }

  .bg-forest {
    background: url('/assets/img/Untitled design-15.png') ;
    background-size: cover;
  }

  .bg-trago {
    background: linear-gradient(rgba(44, 95, 45, 0.9), rgba(44, 95, 45,0.9)), url('/assets/img/re_6.jpeg') center ;
  }

  .para-img {
    float: right;
    border-top-left-radius: calc(0.5rem - 1px);
    border-top-right-radius: calc(0.5rem - 1px);
    border-bottom-left-radius: calc(0.5rem - 1px);
    border-bottom-right-radius: calc(0.5rem - 1px);
    margin: 0px 0px 15px 20px;
  }
</style>