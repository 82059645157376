<template>
    <div>
        <MDBCarousel
            v-model="carousel1"
            :items="items1"
            fade
            :controls="false"
            :indicators="false"
        />
        <section class="">
            <MDBContainer class="p-5  text-center">
                <MDBRow>
                    <MDBCol class="mb-4 item-align-center mx-auto" md="9" xs="12">
                        <MDBCardText style="text-align : justify;" class="text-dark">
                            <p>
                            <img src="/assets/images/home_1.jpeg" class="para-img" style="max-height : 375px; width : auto; max-width : 100%">
                            The Shimla Wildlife Division is a divisional establishment of Himachal Pradesh Forest Department Wildlife Wing which is vested with responsibility to look after and manage the 7 Protected Areas, 2 Zoos, 1 Rescue Center, a bird park, a conservation breeding center in the districts of Shimla, Solan and Sirmour of Himachal Pradesh. 
                            <br><br>
                            The division has a diverse mandate at its hand in enforcing the wildlife protection act and other legislations in the various protected areas, improving habitat for wildlife, wildlife survey and monitoring, developing infrastructure and visitor amenities in zoos/ pheasantries, promoting wildlife awareness and education among public especially the younger generation, rescuing and rehabilitating injured/ orphaned animals, reintroduction of vulnerable species, building capacity of staff etc. The jurisdiction and mandate of the division has undergone great change since it came into being in 1967 and hence there is a great need to document the efforts for continuity in management.
                            
                            </p>
                        </MDBCardText>
                    </MDBCol>
                </MDBRow>
                <!-- <MDBRow>
                    <MDBCol class="mb-4 item-align-center mx-auto" md="9" xs="12">
                        <figure class="figure">
                            <img
                            src="/assets/img/team.jpeg"
                            class="figure-img img-fluid rounded shadow-3 mb-3"
                            alt="..."
                            />
                            <figcaption class="figure-caption text-light">TEAM SARAHAN</figcaption>
                        </figure>
                    </MDBCol>
                </MDBRow> -->
            </MDBContainer>
        </section>
        <!-- <section>
            <MDBContainer class="p-5">
                <h5 class="text-center">OUR DIVISION IS DIVIDED INTO THREE RANGES</h5>
                <br>
                <MDBRow>
                    <MDBCol class="mb-4" md="4">
                        <div class="winter-neva-gradient color-block-5 mb-3 mx-auto rounded-circle z-depth-1-half">
                            <p class="text-center color-block-5 gradient-text"><b>DOGRA</b></p>
                        </div>                      
                    </MDBCol>
                    <MDBCol class="mb-4" md="4">
                        <div class="deep-blue-gradient color-block-5 mb-3 mx-auto rounded-circle z-depth-1-half">
                            <p class="text-center color-block-5 gradient-text" ><b>RUPI</b></p>
                        </div>                      
                    </MDBCol>
                    <MDBCol class="mb-4" md="4">
                        <div class="winter-neva-gradient color-block-5 mb-3 mx-auto rounded-circle z-depth-1-half">
                            <p class="text-center color-block-5 gradient-text"><b>SANGLA</b></p>
                        </div>                      
                    </MDBCol>
                </MDBRow>
                <h3 class="text-center mt-5">OUR SANCTUARIES</h3>
                <br>
                <MDBRow>
                    <MDBCol class="mb-9 mx-auto" md="4" xs="6">
                        <MDBCard>
                        <a v-mdb-ripple="{ color: 'light' }">
                            <MDBCardImg src="/assets/img/d_wls.jpeg" top alt="Daranghati Wildlife Sanctuary" />
                        </a>
                        <MDBCardBody>
                            <MDBCardTitle>Daranghati Wildlife Sanctuary</MDBCardTitle>
                            <MDBCardText>
                            Once the game reserve of Bushahar dynasty is also popular for the legendary Old Hindustan-Tibet Road traversed through its expanse
                            </MDBCardText>
                            <MDBBtn tag="a" href="#!" color="primary">Read More...</MDBBtn>
                        </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol class="mb-9 mx-auto" md="4" xs="6">
                        <MDBCard>
                        <a v-mdb-ripple="{ color: 'light' }">
                            <MDBCardImg src="/assets/img/r_wls.jpeg" top alt="Rupi Bhaba Wildlife Sanctuary" />
                        </a>
                        <MDBCardBody>
                            <MDBCardTitle>Rupi Bhaba Wildlife Sanctuary</MDBCardTitle>
                            <MDBCardText>
                            Situated in the lap of Shrikhand mountains of the Dhauladhar range, the sanctuary is named after two valleys of Lower Kinnaur
                            </MDBCardText>
                            <MDBBtn tag="a" href="#!" color="primary">Read More...</MDBBtn>
                        </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    </MDBRow>
                    <MDBRow>
                    <MDBCol class="mb-9 mx-auto" md="4" xs="6">
                        <MDBCard>
                        <a v-mdb-ripple="{ color: 'light' }">
                            <MDBCardImg src="/assets/img/rc_wls.jpeg" top alt="Rakchham Chitkul wildlife Sanctuary" />
                        </a>
                        <MDBCardBody>
                            <MDBCardTitle>Rakchham Chitkul wildlife Sanctuary</MDBCardTitle>
                            <MDBCardText>
                            Often described as the most beautiful of the Himachal valleys, Rakchham and Chitkul are the two old villages in this part of Himalayas
                            </MDBCardText>
                            <MDBBtn tag="a" href="#!" color="primary">Read More...</MDBBtn>
                        </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol class=" mx-auto" md="4" xs="6">
                        <MDBCard>
                        <a v-mdb-ripple="{ color: 'light' }">
                            <MDBCardImg src="/assets/img/l_wls.jpg" top alt="Lippa Asrang Wildlife Sanctuary" />
                        </a>
                        <MDBCardBody>
                            <MDBCardTitle>Lippa Asrang Wildlife Sanctuary</MDBCardTitle>
                            <MDBCardText>
                            Lippa-Asrang beholds the unique landscape of dry Trans-hImalayan region with the altitude ranging from 3000m to 5122m
                            </MDBCardText>
                            <MDBBtn tag="a" href="#!" color="primary">Read More...</MDBBtn>
                        </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </section> -->
    </div>
</template>
<script>
  import { ref } from "vue";
  import { 
    MDBCarousel,
    MDBCol, MDBRow, MDBContainer,
    MDBCardText,
    MDBCard, MDBCardBody, MDBCardTitle,MDBCardImg, MDBBtn,
    mdbRipple
  } from "mdb-vue-ui-kit";
  export default {
    components: {
      MDBCarousel,
      MDBCol, MDBRow, MDBContainer,
      MDBCardText,
      MDBCard, MDBCardBody, MDBCardTitle,MDBCardImg, MDBBtn,
    },
    directives: {
        mdbRipple
    },
    setup() {
      const items1 = [
        {
          src: "/assets/images/about-us-banner.jpg",
          alt: "...",
        }
      ];
      const carousel1 = ref(0);
      return {
        items1,
        carousel1,
      };
    }
  };
</script>
<style scoped>

    /* GRADIENTS */
    .winter-neva-gradient {
        background-image: linear-gradient(120deg,#a1c4fd,#c2e9fb);
    }

    .deep-blue-gradient {
        background-image: linear-gradient(120deg,#e0c3fc,#8ec5fc);
    }
    /* GRADIENTS END */

    .gradient-text {
        line-height: 200px; 
        font-size : 24px; 
        letter-spacing : 2px
    }

    .z-depth-1-half {
        -webkit-box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)!important;
        box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)!important;
    }

    .bg-dark-green {
        background-color: rgb(77,169,80);
    }

    .para-img {
        float: right;
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
        margin: 0px 0px 15px 20px;
    }

    h4 {
        margin: 0;  
    }
    .color-block-5 {
        height: 200px;
        width: 200px;
    }
    .img-gradient{
        position:relative;
        display:inline-block;
    }
    .img-gradient:after {
        content:'';
        position:absolute;
        left:0; top:0;
        width:100%; height:100%;
        display:inline-block;
        background: -moz-linear-gradient(top, rgba(0,47,75,0.5) 0%, rgba(220, 66, 37, 0.5) 100%); /* FF3.6+ */
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(220, 66, 37, 0.5)), color-stop(100%,rgba(0,47,75,0.5))); /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* Opera 11.10+ */
        background: -ms-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* IE10+ */
        background: linear-gradient(to bottom, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002f4b', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
    }
    .img-gradient img{
        display:block;
    }

</style>