<template>
    <div>
        <section class="bg-forest">
            <MDBContainer class="p-5 bg-form">
                <MDBRow class="mb-3">
                    <MDBCol md="12" class="mx-auto text-light item-align-center">
                        <MDBCardText style="text-align : justify;" class="">
                            <h2 class="mb-4 text-light text-center">A Proactive Protection Measure: Setting up the Fire Hydrant Safety System in Rescue and Rehabilitation Centre, Tutikandi</h2>       
                            <p>
                                <span class="para-img float-right ml-20" style="text-align : center">
                                    <img src="/assets/images/updates/2024/february/001/image001.jpg" class="img-round-corner" style="max-height : 375px; width : auto; max-width : 100%;">
                                    <br>
                                    <!-- <small><i>- Administrative map of Shimla Water Catchment Wildlife Sanctuary</i></small> -->
                                </span>
                                <span class="para-img float-left mr-20" style="text-align : center">
                                    <img src="/assets/images/updates/2024/february/001/image002.jpg" class="img-round-corner" style="max-height : 375px; width : auto; max-width : 100%;">
                                    <br>
                                    <!-- <small><i>- Administrative map of Shimla Water Catchment Wildlife Sanctuary</i></small> -->
                                </span>  
                                Rescue and Rehabilitation Centre at Tutikandi Shimla was previously zoological park maintained by Wildlife Wing before being converted to rescue center in the year 1993. The rescue center currently houses 18 leopards and 5 black bears in 11 enclosures in an area spanning 1 Ha. The campus of RRC along with Monkey Sterilization centre are key assets of the HP Wildlife Wing which provides unique services of wildlife rescue, veterinary care, treatment, population control and human-wildlife conflict management. The centre is located in the heart of Shimla city a few meters away from the ISBT. Its location is unique given the thick pine forests and associated dry grass presence in the adjoining forest area. The presence of Chil pine forests and its resinous needles make the centre highly vulnerable to forest fires and associated damages. The centre has several enclosures, cages, rescue equipment, building, staff quarters which are major assets of wildlife wing which face the threat of forest fire.
                                <br><br>                                               
                                In the year 2022, the RRC and MSC faced a massive fire from adjoining pine forests leading to damage to staff quarters, store rooms. A great risk was also posed to the wildlife and enclosures but due to timely action from forest department the enclosures and wildlife were saved. In this context, HP Wildlife Wing conceptualized a fire hydrant safety system for protecting the facility from fire, reducing its vulnerability and offering scope for immediate emergency action from staff.                                        
                            </p>                                   

                            <p>
                                <span class="para-img float-left mr-20" style="text-align : center">
                                    <img src="/assets/images/updates/2024/february/001/image004.png" class="img-round-corner" style="max-height : 375px; width : auto; max-width : 100%; margin: auto">
                                    <br>
                                    <small><i> Fig 1: Diagramatic Representation of Fire Hyrdant Safety System Installed at RRC Tutikandi</i></small>
                                </span>
                                The Fire Hydrant Protection System designed to mitigate fires especially in case of large scale with higher asset risk. It is an active fire protection measure where field functionaries are empowered on the ground through extensively designed fire protection and fire fighting network involving pipes connecting the main tank. It is designed to provide rapid access to water as an extinguishing agent in cases where fire breaks out. Fire hydrant installation consists of a system of pipe work connected directly to the water supply main to provide water to every hydrant outlet driven by a fire pump and water flow wall and is intended to provide water for the firemen to fight a fire. 
                                <br><br>
                                At RRC Tutikandi, a network of 9 hydrant heads channelizing the water from the main tank of capacity of 36k liters is made. The choice of the hydrant heads are chosen in such a way that each hydrant point enables the immediate safety and fire mitigation action of adjacent enclosures safeguarding all 9 enclosures. The main source tank was reworked and repaired to ensure that it remains capable to hold 36k litre of water. A new tank of 1 Lakh liters is being constructed to ensure further enhancement of fire protection measure.
                                <br><br>
                                The entire fire hydrant safety system consists of various specialized equipments including the Mild steel pipe network for channelization, a fire protection auto panel to control the flow of water, 9 fire hydrant points along with Hose pipe (3 inch diameter) and Hose reel (0.75 inch diameter), a water flow wall, pressure gauge and DD box to store the hose pipe and reels. The multiple sized pipes/ reels to release water to extinguish fires enables multiple action scope to resist ground and crown fires. The Hose pipes of 3 inch diameter offers scope to reach greater heights of fighting crown fires in adjoining pine forests whereas hose reels enable targeted action against ground fires.                             
                            </p>
                            <img src="/assets/images/updates/2024/february/001/image019.png" class="img-round-corner" style=" width : 100%; max-width : 100%;">
                            <p style="text-align : center"><small><i> Fig 2: The many parts of fire hydrant safety system installed at RRC Tutikandi</i></small></p>
                            
                            <img src="/assets/images/updates/2024/february/001/image017.jpg" class="img-round-corner" style=" width : 100%; max-width : 100%;">
                            <p style="text-align : center"><small><i> Fig 3: The fire hydrant system functioning in demonstration: the ability to fight crown fires visible here</i></small></p>
                            
                            <p>
                                Once the system was installed in 2023, a demonstration of the system and training for utilization of the system for field functionaries was conducted in Rescue and Rehabilitation Centre. The demonstration of the system signaled that the hydrant system is successful in reaching heights of up to 20 feet being effective in fighting crown fires. The demonstration of hose pipe at different points also indicated its effective ability in countering ground fires. The fire hydrant system is a novel addition to the centre enabling staff to better protect the assets and effect a swift counter action during fire season on the ground. This greatly enhances the safety and security of wildlife wing assets and investment in the fire hydrant safety system is part of long term vision to secure the valuable assets and ensure safety of rescued wildlife housed in the center.    
                            </p>


                            
                            

                        </MDBCardText>
                    </MDBCol>
                </MDBRow>                     
            </MDBContainer>
        </section>        
    </div>
</template>
<script>
  import { ref } from "vue";
  import { 
    MDBCol, MDBRow, MDBContainer,
    MDBInput,
    MDBCheckbox,
    MDBBtn,
    MDBTextarea,
    MDBModal,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBCarousel,
    MDBCardText,
    MDBCardImg, mdbRipple

  } from "mdb-vue-ui-kit";
  export default {
    name : "Updates_October_001",
    components: {
        MDBCol, MDBRow, MDBContainer,
        MDBInput,
        MDBCheckbox,
        MDBBtn,
        MDBTextarea,
        MDBModal,
        MDBModalHeader,
        MDBModalTitle,
        MDBModalBody,
        MDBModalFooter,
        MDBCarousel,
        MDBCardText,
        MDBCardImg
    },
    directives: {
      mdbRipple
    },
    setup() {
      const items1 = [
        {
          src: "/assets/images/contact-us.jpg",
          alt: "Shimla Wildlife Division",
        }
      ];
      const exampleModal = ref(false);
      const carousel1 = ref(0);
      return {
        exampleModal,
        items1,
        carousel1,
        form : {
            name : '',
            email : '',
            phone : '',
            query : '',
            city : '',
            newsletter : true
        },
        modal : {
            show : true,
            title : 'SUCCESS!!',
            body : 'Your query has been posted successfully! We will get back to you soon'
        }
      };
    },
    methods : {
        submitForm(){

            this.exampleModal = true;
        }
    }
  };
</script>
<style scoped>

    .bg-dark-green {
        background-color: rgb(77,169,80);
    }

    .para-img {
        float: right;
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
    }

    .bg-contact {
        background: linear-gradient(rgba(44, 95, 45, 0.5), rgba(44, 95, 45,0.5)), url('/assets/img/contact-bg.svg');
    }

    .float-left {
        float: left !important;
    }

    .mr-20 {
        margin-right : 20px
    }

    .ml-20 {
        margin-left : 20px
    }

    .bg-trago {
        background: linear-gradient(rgba(44, 95, 45, 0.9), rgba(44, 95, 45,0.9)), url('/assets/img/re_6.jpeg') center ;
    }

    .bg-forest {
        background: url('/assets/img/Untitled design-15.png') ;
        background-size: cover;
    }

    .img-round-corner {
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
    }

    .bg-form {
        background-color : rgb(66,136,121);
    }

    .form-title {

        border-left: 5px solid rgb(138,250,160);
        padding-left: 10px;
    }

    .form-color {
        color : #4F4F4F !important;
        border-color: transparent !important;
        background-color: white !important;
    }
    .update-container {
        overflow: hidden;        
    }
    .responsive-iframe {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

</style>