<template>
  <div id="app">
      <router-view name="header"></router-view>
      <main>
          <router-view/>
      </main>
      <router-view name="footer"></router-view>
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300;400;500;600;700&display=swap');
#app {
  /* font-family: 'Fredoka', sans-serif; */
}
</style>
