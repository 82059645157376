<template>
    <div>
        <section class="bg-forest">
            <MDBContainer class="p-5 bg-form">
                <MDBRow class="mb-3">
                    <MDBCol md="12" class="mx-auto text-light item-align-center">
                        <MDBCardText style="text-align : justify;" class="">
                            <h2 class="mb-4 text-light text-center">Eco Development Program at Churdhar Wildlife Sanctuary: A New Beginning of Participatory Conservation through Entry Point Activities</h2>       
                            <p>
                                <span class="para-img float-right ml-20" style="text-align : center">
                                    <img src="/assets/images/updates/2024/february/002/image001.jpg" class="img-round-corner" style="max-height : 375px; width : auto; max-width : 100%;">
                                    <br>
                                    <!-- <small><i>- Administrative map of Shimla Water Catchment Wildlife Sanctuary</i></small> -->
                                </span> 
                                The Churdhar wildlife sanctuary is a protected area spread across 55 sq km located in boundary of Sirmour and Shimla districts the state of Himachal Pradesh. The sanctuary is a crucial cog in the protected area network providing several important biological, ecological, cultural, recreational values and critical diverse ecosystem services of biodiversity, water provisioning, religious values.
                                <br><br>                                               
                                The 55 sq km area of sanctuary offers rich untouched habitat for western Himalayan wildlife. The altitudinal zonation of sanctuary from 2000 meter to 3600 meter offers immense diversity of habitat including deodar forests, fir, spruce, alpine grasslands and meadows making it a crucial habitat for wildlife given the forage potential for herbivores which in turn contributes to better food availability for carnivores. The sanctuary also has good oak patch of forest which forms a good habitat for Himalayan Black Bear, a keystone species of the ecosystem.   
                            </p>    
                            <br><br>                               

                            <p>
                                <span class="para-img float-left mr-20" style="text-align : center">
                                    <img src="/assets/images/updates/2024/february/002/image003.jpg" class="img-round-corner" style="max-height : 375px; width : auto; max-width : 100%; margin: auto">
                                    <br>
                                </span>
                                The sanctuary is a treasure tove of biodiversity with 793 taxa recorded from it involving several rare, endemic plants with high ethnobotanical and medicinal importance. The PA is a critical habitat for the Himalayan Black Bear, Common Leopard, Red Fox, Himalayan Vulture and among herpetofauna supports rare and endemic Assam Cascade Frog and Himalayan Pit Viper with a diverse array of mosaic habitat.
                                <br><br>
                                The biggest challenge faced in PA management is the significant biotic pressure exerted on the sanctuary owing to substantial livestock presence in the ESZ. 4 panchayats of Devna-Choras, Nohradhar, Jawag Chamrog and Chhogtali are dependent on sanctuary area for fodder, grazing and fuelwood. The offsetting of this biotic pressure and reducing anthropogenic interference forms a crucial aspect of wildlife conservation in sanctuary area.
                                <br><br>
                                In this context, the Shimla Wildlife Division took a unique initiative to reduce biotic pressure on sanctuary area through initiating a program of eco development for villages in ESZ area of sanctuary. As a first step, a list of major forest dependent villages and within the villages individuals and households were identified who were having greater dependence on forests and at a lower socio-economic class. Secondly, they were extensively consulted on the scope of livelihood generation, requirement of community assets, energy saving devices, training/skill development needs and involvement in community based eco tourism.    
                            </p>
     
                            <p>
                                <span class="para-img float-right ml-20" style="text-align : center">
                                    <img src="/assets/images/updates/2024/february/002/image004.jpg" class="img-round-corner" style="max-height : 375px; width : auto; max-width : 100%; margin: auto">
                                    <br>
                                </span>
                                A key part of eco development is that of entry point activity which is the first point of interaction and where trust and goodwill sprouts between forest department and the villagers. In this regard, entry point activities were skill development and training programs for women in the ESZ area from villages of Nohradhar, Talangana, Chogtali, Choras, Devna which are the major dependent villages. It was gathered that women are crucial part of the local economy given their management of cattle and if alternate livelihoods provided and women empowered, it would go a long way in reducing the dependence and paving the way for unhindered wildlife conservation.
                                <br><br>
                                Pine Needle Handicraft was one unique skill development targetted given the inter linkage it offers with forest and the branding prospects of the same given a booming market potential for pine needle based products in and out of state. Training on pine needle based handicraft items were provided for women in Chhogtali and Nohradhar villages.
                                <br> 
                            </p>

                            <p>
                                <span class="para-img float-left mr-20" style="text-align : center">
                                    <img src="/assets/images/updates/2024/february/002/image006.jpg" class="img-round-corner" style="max-height : 375px; width : auto; max-width : 100%; margin: auto">
                                    <br>
                                </span>                                
                                In Choras village, women were trained in knitting sweaters and winter clothing. Women from different villages in the panchayat participated in the knitting training and learnt the skill for winter wear knitting. Similarly, a training on stitching was also organized for the SC community in Nohradhar region after consultation with them. In Devna, women were provided training in food processing and packaging of items. These trainings have been instrumental in bringing women folk forward, empowering them and providing a sense of dignity to them.
                                <br><br>
                                Apart from the training in manufacture of products, the residents of ESZ were also imparted knowledge on backward and forward linkages of products to enable them to secure cost effective raw materials and access market through existing network of SHG/ NGOs and HIMIRA shops. A separate module on available forward market linkages in the form of various HIMIRA shops set up by Rural Development department, cluster groups sale points, Eco Shops run by HP Forest Department and JICA, National and International Fairs, E Commerce options are being conducted for the participants so that their learned skill translates to gainful income. Eco development promises to have great prospect in Churdhar Wildlife Sanctuary given the forest based dependence of community, large footfall in sanctuary area and eco tourism potential of the area.
                            </p>
                            <p>
                                <span class="para-img float-right ml-20" style="text-align : center">
                                    <img src="/assets/images/updates/2024/february/002/image007.jpg" class="img-round-corner" style="max-height : 375px; width : auto; max-width : 100%; margin: auto">
                                    <br>
                                </span>
                                In this regard, the eco development paradigm is a game changer providing alternate livelihood for women and weaning away their dependence on livestock rearing thereby creating a social fence for this biodiversity treasure. In our initiative this year, we touched around 200 lives in the buffer area of the sanctuary in villages like Nohradhar, Devna, Choras, Talangna, Chhogtali hoping to kindle their interest and provide them alternate livelihood securing their support for the cause of conservation, creating a good will with forest personnel and widening our informant network to the last mile.
                                <br><br>
                                The next step in eco development programme is the formation of Churdhar Eco Development Committee and setting up democratic institution which liaisons with the Range Forest Officer to create sources of income, channelize revenue and recycle a portion of revenue back for conservation and management of protected areas. In terms of revenue generation from Protected Areas, there exists great scope for conducting guided trekking/hiking along trails in PAs especially in Churdhar where there is already a robust inflow of visitors as a case of satellite tourism. This can provide scope for engagement of the youth in the ESZ areas as nature guides wherein they can educate the visitors about the flora and fauna of the area. The operation of eco tourism through involvement of EDCs, revenue sharing with EDCs and channelizing the revenue for wildlife awareness and conservation education shall be the hallmark of the strategy to link eco tourism with eco development and ensure sustainable tourism in wildlife areas from standpoints of both ecology and economy.
                                <br><br>
                                It is hoped that departed from the isolationist and silos approach, involving people in eco tourism and PA management, providing them alternate livelihoods and securing an alternate income source through eco development would reap dividends for habitat conservation in Churdhar sanctuary area. This can help us secure the habitat for wildlife and substantially reduce the biotic pressure on the sanctuary area furthering the good will between forest department and people transforming the strained relationship and raising the voice of conservation among the people.

                            </p>
                            <br>
                            <p style="text-align : right">
                                <i><b>Content: N Ravisankar IFS, Deputy Conservator of Forests, Shimla Wildlife Division</b></i>
                            </p>
                            


                            
                            

                        </MDBCardText>
                    </MDBCol>
                </MDBRow>                     
            </MDBContainer>
        </section>        
    </div>
</template>
<script>
  import { ref } from "vue";
  import { 
    MDBCol, MDBRow, MDBContainer,
    MDBInput,
    MDBCheckbox,
    MDBBtn,
    MDBTextarea,
    MDBModal,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBCarousel,
    MDBCardText,
    MDBCardImg, mdbRipple

  } from "mdb-vue-ui-kit";
  export default {
    name : "Updates_October_001",
    components: {
        MDBCol, MDBRow, MDBContainer,
        MDBInput,
        MDBCheckbox,
        MDBBtn,
        MDBTextarea,
        MDBModal,
        MDBModalHeader,
        MDBModalTitle,
        MDBModalBody,
        MDBModalFooter,
        MDBCarousel,
        MDBCardText,
        MDBCardImg
    },
    directives: {
      mdbRipple
    },
    setup() {
      const items1 = [
        {
          src: "/assets/images/contact-us.jpg",
          alt: "Shimla Wildlife Division",
        }
      ];
      const exampleModal = ref(false);
      const carousel1 = ref(0);
      return {
        exampleModal,
        items1,
        carousel1,
        form : {
            name : '',
            email : '',
            phone : '',
            query : '',
            city : '',
            newsletter : true
        },
        modal : {
            show : true,
            title : 'SUCCESS!!',
            body : 'Your query has been posted successfully! We will get back to you soon'
        }
      };
    },
    methods : {
        submitForm(){

            this.exampleModal = true;
        }
    }
  };
</script>
<style scoped>

    .bg-dark-green {
        background-color: rgb(77,169,80);
    }

    .para-img {
        float: right;
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
    }

    .bg-contact {
        background: linear-gradient(rgba(44, 95, 45, 0.5), rgba(44, 95, 45,0.5)), url('/assets/img/contact-bg.svg');
    }

    .float-left {
        float: left !important;
    }

    .mr-20 {
        margin-right : 20px
    }

    .ml-20 {
        margin-left : 20px
    }

    .bg-trago {
        background: linear-gradient(rgba(44, 95, 45, 0.9), rgba(44, 95, 45,0.9)), url('/assets/img/re_6.jpeg') center ;
    }

    .bg-forest {
        background: url('/assets/img/Untitled design-15.png') ;
        background-size: cover;
    }

    .img-round-corner {
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
    }

    .bg-form {
        background-color : rgb(66,136,121);
    }

    .form-title {

        border-left: 5px solid rgb(138,250,160);
        padding-left: 10px;
    }

    .form-color {
        color : #4F4F4F !important;
        border-color: transparent !important;
        background-color: white !important;
    }
    .update-container {
        overflow: hidden;        
    }
    .responsive-iframe {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

</style>