<template>
    <div>
        <section class="bg-forest">
            <MDBContainer class="p-5 bg-form">
                <MDBRow class="mb-3">
                    <MDBCol md="12" class="mx-auto text-light item-align-center">
                        <MDBCardText style="text-align : justify;" class="">
                            <h2 class="mb-4 text-light text-center">Himachal’s First Eco Bridge for Arboreal Animals at Chail Wildlife Sanctuary: An Experiment to overcome fragmented habitat landscapes</h2>       
                            <p> 
                                <span class="para-img float-left" style="text-align : center; margin-right: 40px !important;">
                                    <img src="/assets/images/updates/2024/march/002/image001.jpg" class="img-round-corner" style="max-height : 375px; width : auto; max-width : 100%;">
                                    <br>
                                    <!-- <small><i>- Sanitization of transportation cages</i></small> -->
                                </span>  
                                The Chail Wildlife sanctuary is a small protected area in Solan District of Himachal Pradesh with an area of 16 sq km. The protected area is renowned for its majestic deodar forests and immense wildlife potential of all orders including mammals, aves and herpetofauna. The sanctuary is home to a variety of fauna including arboreal animals such as the yellow throated marten, red giant flying squirrel, grey langur, rhesus macaques etc. 
                                <br><br>
                                The sanctuary faces a massive challenge of habitat fragmentation with as many as 9 roads falling within the 16 sq km area fragmenting landscapes and creating bottlenecks for hindrance free wildlife movement. Linear infrastructure in forested and wildlife landscapes are significant impediments in limiting wildlife passage which over time can lead to erosion of genetic diversity in the absence of mitigation actions taken.
                            </p>  
                            <p> 
                                <span class="para-img float-right" style="text-align : center; margin-left: 40px !important;">
                                    <img src="/assets/images/updates/2024/march/002/image002.jpg" class="img-round-corner" style="max-height : 375px; width : auto; max-width : 100%;">
                                    <br>
                                    <small><i>- Figure illustrating the fragmentation in sanctuary owing to roads</i></small>
                                </span>  
                                In order to overcome the fragmentation, the idea of eco bridges inter connecting the fragmented landscapes was mooted in the management plan of the sanctuary written with the specific objective of overcoming the limitation of fragmentation. The concept of eco bridges has been previously utilized in protected areas in Kerala and Uttarakhand enabling previous reference and conceptualization based on experiences of department there.
                                <br><br>
                                However, this was the first such initiative taken in the state of Himachal Pradesh which has seen a significant rise in building of linear infrastructure especially roads cutting through forests and wildlife habitats. In Chail Wildlife sanctuary, a survey was conducted across the length and breadth of the PA to identify suitable strategic points wherein eco bridges could be installed to reap dividends of corridor movement. Based on this, 3 locations were finalized where in more than a couple of roads divulge and dissect the existing habitat. In some situations 3 isolated habitats were interconnected through eco bridges to maximize wildlife movement between landscapes.
                            </p> 
                            <p> 
                                <span class="para-img float-left" style="text-align : center; margin-right: 40px !important;">
                                    <img src="/assets/images/updates/2024/march/002/image003.jpg" class="img-round-corner" style="max-height : 275px; width : auto; max-width : 100%;">
                                    <br>
                                    <!-- <small><i>- Sanitization of transportation cages</i></small> -->
                                </span>  
                                The eco bridges were made using bamboo poles and nylon ropes wedded together and the same was hanged between trees on opposite sides of the road and tight knotted to ensure reasonable stability for the structure. The distance between poles is kept minimal to ensure easy passage of arboreal animal utilizing the infrastructure. The natural design of the structure is also made in consideration of its use in forest landscapes and the comfort of animals in utilizing it.
                                <br><br>
                            </p>  
                            <p> 
                                <span class="para-img float-right" style="text-align : center; margin-left: 40px !important;">
                                    <img src="/assets/images/updates/2024/march/002/image005.jpg" class="img-round-corner" style="max-height : 275px; width : auto; max-width : 100%;">
                                    <br>
                                    <small><i>- Figure illustrating the fragmentation in sanctuary owing to roads</i></small>
                                </span>  
                                In order to evaluate the movement of wildlife, camera traps were also placed adjacent to eco bridge and it was observed that Rhesus macaques are commonly using the eco bridge which is heartening sign. It is expected that the arboreal animals once habituated to the new structure, they would significantly utilize this intervention thereby bringing in gains of hindrance free wildlife movement between isolated landscapes and habitats. This can also help in bringing down road kills in the sanctuary considering it witnesses heavy traffic movement especially during tourists season or as a result of grass extraction. In the long run, the interconnection of isolated landscapes can significantly help in enhancing genetic diversity by keeping in tact the gene flow of arboreal animals in the sanctuary.
                            </p> 
                            <p style="text-align : right">
                                <b> ~ Content Credit: N Ravisankar IFS</b>
                            </p>

                            
                            
                            


                            
                            

                        </MDBCardText>
                    </MDBCol>
                </MDBRow>                     
            </MDBContainer>
        </section>        
    </div>
</template>
<script>
  import { ref } from "vue";
  import { 
    MDBCol, MDBRow, MDBContainer,
    MDBInput,
    MDBCheckbox,
    MDBBtn,
    MDBTextarea,
    MDBModal,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBCarousel,
    MDBCardText,
    MDBCardImg, mdbRipple

  } from "mdb-vue-ui-kit";
  export default {
    name : "Updates_October_001",
    components: {
        MDBCol, MDBRow, MDBContainer,
        MDBInput,
        MDBCheckbox,
        MDBBtn,
        MDBTextarea,
        MDBModal,
        MDBModalHeader,
        MDBModalTitle,
        MDBModalBody,
        MDBModalFooter,
        MDBCarousel,
        MDBCardText,
        MDBCardImg
    },
    directives: {
      mdbRipple
    },
    setup() {
      const items1 = [
        {
          src: "/assets/images/contact-us.jpg",
          alt: "Shimla Wildlife Division",
        }
      ];
      const exampleModal = ref(false);
      const carousel1 = ref(0);
      return {
        exampleModal,
        items1,
        carousel1,
        form : {
            name : '',
            email : '',
            phone : '',
            query : '',
            city : '',
            newsletter : true
        },
        modal : {
            show : true,
            title : 'SUCCESS!!',
            body : 'Your query has been posted successfully! We will get back to you soon'
        }
      };
    },
    methods : {
        submitForm(){

            this.exampleModal = true;
        }
    }
  };
</script>
<style scoped>

    .bg-dark-green {
        background-color: rgb(77,169,80);
    }

    .para-img {
        float: right;
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
    }

    .bg-contact {
        background: linear-gradient(rgba(44, 95, 45, 0.5), rgba(44, 95, 45,0.5)), url('/assets/img/contact-bg.svg');
    }

    .float-left {
        float: left !important;
    }

    .mr-20 {
        margin-right : 20px
    }

    .ml-20 {
        margin-left : 20px
    }

    .bg-trago {
        background: linear-gradient(rgba(44, 95, 45, 0.9), rgba(44, 95, 45,0.9)), url('/assets/img/re_6.jpeg') center ;
    }

    .bg-forest {
        background: url('/assets/img/Untitled design-15.png') ;
        background-size: cover;
    }

    .img-round-corner {
        border-top-left-radius: calc(0.5rem - 1px);
        border-top-right-radius: calc(0.5rem - 1px);
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
    }

    .bg-form {
        background-color : rgb(66,136,121);
    }

    .form-title {

        border-left: 5px solid rgb(138,250,160);
        padding-left: 10px;
    }

    .form-color {
        color : #4F4F4F !important;
        border-color: transparent !important;
        background-color: white !important;
    }
    .update-container {
        overflow: hidden;        
    }
    .responsive-iframe {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

</style>