<template>
  <div>
    <MDBCarousel
      v-model="carousel1"
      :items="items1"
      fade
      :controls="false"
      :indicators="true"
    />
    <MDBContainer class="p-5 bg-dark-green text-center" fluid>
      <MDBRow>
        <MDBCol class="mb-4 item-align-center mx-auto" md="9" xs="12">
          <MDBCardText style="text-align : justify;" class="text-light">
            <p>
              <img src="/assets/images/home_1.jpeg" class="para-img" style="max-height : 375px; width : auto; max-width : 100%">
              The Shimla Wildlife Division is a divisional establishment of Himachal Pradesh Forest Department Wildlife Wing which is vested with responsibility to look after and manage the 7 Protected Areas, 2 Zoos, 1 Rescue Center, a bird park, a conservation breeding center in the districts of Shimla, Solan and Sirmour of Himachal Pradesh. 
              <br><br>
              The division has a diverse mandate at its hand in enforcing the wildlife protection act and other legislations in the various protected areas, improving habitat for wildlife, wildlife survey and monitoring, developing infrastructure and visitor amenities in zoos/ pheasantries, promoting wildlife awareness and education among public especially the younger generation, rescuing and rehabilitating injured/ orphaned animals, reintroduction of vulnerable species, building capacity of staff etc. The jurisdiction and mandate of the division has undergone great change since it came into being in 1967 and hence there is a great need to document the efforts for continuity in management.
              
            </p>
          </MDBCardText>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
    <section class="bg-trago">
      <MDBContainer>
        <MDBRow >
          <MDBCol md="12" xs="12" class="mx-auto text-center mt-5">
            <h3 class="text-center text-light">What's new in the division?</h3>      
            <br>      
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol class="mb-9 mx-auto" md="4" xs="6">
            <MDBCard>
              <a v-mdb-ripple="{ color: 'light' }">
                <MDBCardImg src="/assets/images/updates/2023/october/001/1.jpeg" top alt="Chail Conservation Breeding Centre" />
              </a>
              <MDBCardBody>
                <MDBCardTitle>Artistic Modification of Range Office Campus at Shimla Water Catchment</MDBCardTitle>
                <MDBBtn tag="a" href="/2023-October-001" color="primary">Read More...</MDBBtn>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol class="mb-9 mx-auto" md="4" xs="6">
            <MDBCard>
              <a v-mdb-ripple="{ color: 'light' }">
                <MDBCardImg src="/assets/images/updates/2023/october/001/2.jpeg" top alt="Chail Conservation Breeding Centre" />
              </a>
              <MDBCardBody>
                <MDBCardTitle>Eco Development: A New Beginning of Participatory Conservation at Shimla Water Catchment and Chail Wildlife Sanctuary</MDBCardTitle>
                <MDBBtn tag="a" href="/2023-October-002" color="primary">Read More...</MDBBtn>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol class="mb-9 mx-auto" md="4" xs="6">
            <MDBCard>
              <a v-mdb-ripple="{ color: 'light' }">
                <MDBCardImg src="/assets/images/updates/2023/october/001/8.jpg" top alt="Chail Conservation Breeding Centre" />
              </a>
              <MDBCardBody>
                <MDBCardTitle>Recording the First Camera Trap Image of Royal Bengal Tiger in State of Himachal Pradesh</MDBCardTitle>
                <MDBBtn tag="a" href="/2023-October-003" color="primary">Read More...</MDBBtn>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </section>
    
    <!-- <section class="bg-trago">
      <MDBContainer>
        <MDBRow class="p-5">
          <MDBCol md="8" xs="12" class="text-light">
            <h4>Himalayan Nature Park, Kufri (Zoo)</h4>
            <p style="font-size : 20px">
              In the year 1996, it was recognized as ‘Himalayan Nature Park Kufri’ in the category of Small Zoo by the Central Zoo Authority of India. 
            </p>
          </MDBCol>
          <MDBCol md="4" xs="12" class="mb-3 text-center d-flex justify-content-center align-items-center">
            <MDBBtn color="success" class="text-center" @click="openPage()">READ MORE</MDBBtn>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </section> -->
    <section class="bg-forest">
      <MDBContainer class="p-5 text-center" style="opacity : 1" fluid>
        <h3 class="text-center">Our Zoos and Pheasantries</h3>
        <br>
        <MDBRow>
          <MDBCol class="mb-9 mx-auto" md="3" xs="6">
            <MDBCard>
              <a v-mdb-ripple="{ color: 'light' }">
                <MDBCardImg src="/assets/images/breeding-banner.jpg" top alt="Chail Conservation Breeding Centre" />
              </a>
              <MDBCardBody>
                <MDBCardTitle>Chail Conservation Breeding Centre</MDBCardTitle>
                <MDBBtn tag="a" href="/chail-conservation" color="primary">Read More...</MDBBtn>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol class="mb-9 mx-auto" md="3" xs="6">
            <MDBCard>
              <a v-mdb-ripple="{ color: 'light' }">
                <MDBCardImg src="/assets/images/renuka-mini-zoo-banner.jpg" top alt="Renuka Ji Mini Zoo" />
              </a>
              <MDBCardBody>
                <MDBCardTitle>Renuka Ji Mini Zoo</MDBCardTitle>
                <MDBBtn tag="a" href="/renuka-ji-zoo" color="primary">Read More...</MDBBtn>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol class="mb-9 mx-auto" md="3" xs="6">
            <MDBCard>
              <a v-mdb-ripple="{ color: 'light' }">
                <MDBCardImg src="/assets/images/hnp-1.png" top alt="Himalayan Nature Park" />
              </a>
              <MDBCardBody>
                <MDBCardTitle>Himalayan Nature Park, Kufri</MDBCardTitle>
                <MDBBtn tag="a" href="/himalyan-nature-park" color="primary">Read More...</MDBBtn>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol class="mb-9 mx-auto" md="3" xs="6">
            <MDBCard>
              <a v-mdb-ripple="{ color: 'light' }">
                <MDBCardImg src="/assets/images/bird_park_banner.jpg" top alt="Himalayan Bird Park Shimla" />
              </a>
              <MDBCardBody>
                <MDBCardTitle>Himalayan Bird Park Shimla</MDBCardTitle>
                <MDBBtn tag="a" href="/himalayan-bird-park" color="primary">Read More...</MDBBtn>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol class=" mx-auto" md="3" xs="6">
            <MDBCard>
              <a v-mdb-ripple="{ color: 'light' }">
                <MDBCardImg src="/assets/images/rrc_banner.jpg" top alt="Rescue and Rehabilitation Centre, Shimla" />
              </a>
              <MDBCardBody>
                <MDBCardTitle>Rescue and Rehabilitation Centre, Shimla</MDBCardTitle>
                <MDBBtn tag="a" href="/rescue-centre" color="primary">Read More...</MDBBtn>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol class=" mx-auto" md="3" xs="6">
            <MDBCard>
              <a v-mdb-ripple="{ color: 'light' }">
                <MDBCardImg src="/assets/images/rescue_banner.jpg" top alt="Monkey Sterilization Centre" />
              </a>
              <MDBCardBody>
                <MDBCardTitle>Monkey Sterilization Centre</MDBCardTitle>
                <MDBBtn tag="a" href="/monkey-rescue" color="primary">Read More...</MDBBtn>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </section>

      
  </div>
    
</template>

<script>
  import { ref } from "vue";
  import { 
    MDBCarousel, MDBCol, MDBRow, MDBContainer, MDBCardText, MDBCardFooter,
    MDBCard, MDBCardBody, MDBCardTitle, MDBCardImg, MDBBtn, mdbRipple
  } from "mdb-vue-ui-kit";
  import VueHorizontal from "vue-horizontal";
  export default {
    components: {
      MDBCarousel, MDBCol, MDBRow, MDBContainer, MDBCardText, MDBCardFooter,
      MDBCard, MDBCardBody, MDBCardTitle,MDBCardImg, MDBBtn,
      VueHorizontal
    },
    directives: {
      mdbRipple
    },
    methods : {
      openPage() {
        window.open(`/himalyan-nature-park`, '_blank');
      }
    },
    setup() {
      const items1 = [
        {
          src: "/assets/images/home-banner-1.jpg",
          label: "",
          caption: ""
        },
        {
          src: "/assets/images/home-banner-2.jpg",
          label: "",
          caption: ""
        },
        {
          src: "/assets/images/home-banner-3.jpg",
          label: "",
          caption: ""
        },
        {
          src: "/assets/images/home-banner-4.jpg",
          label: "",
          caption: ""
        },
        {
          src: "/assets/images/home-banner-5.jpg",
          label: "",
          caption: ""
        },
        
      ];
      const carousel1 = ref(0);
      return {
        items1,
        carousel1,
        items: [
          {
            title : 'Mercury Rises Marginally In Himachal Pradesh, Una Hottest At 42.8 Degrees Celsius',
            img : 'https://i.ndtvimg.com/i/2016-05/himachal-forest-fire-650_650x400_61462300863.jpg'
          },
          {
            title : 'How Himachal\'s Ill-equipped Fire Fighters Are Dousing Forest Fires',
            img : 'https://i.ndtvimg.com/i/2016-05/fire-fighter_240x180_61462388459.jpg'
          },
          {
            title : 'How Himachal\'s Ill-equipped Fire Fighters Are Dousing Forest Fires',
            img : 'https://i.ndtvimg.com/i/2016-05/fire-fighter_240x180_61462388459.jpg'
          },
          {
            title : 'How Himachal\'s Ill-equipped Fire Fighters Are Dousing Forest Fires',
            img : 'https://i.ndtvimg.com/i/2016-05/fire-fighter_240x180_61462388459.jpg'
          },

        ]
      };
    }
  };
</script>
<style scoped>
  .img-cut-edge {
    -webkit-clip-path: polygon(0% 0%, 90% 0, 100% 10%, 100% 100%, 0% 100%);
    clip-path: polygon(0% 0%, 90% 0, 100% 10%, 100% 100%, 0% 100%);
  }
  section {
    padding: 16px 24px;
    background: #f5f5f5;
  }
  .bg-light-grey {
    background-color: #f5f5f5;
  }
  .bg-dark-green {
    background-color: rgb(77,169,80);
  }

  .bg-forest {
    background: url('/assets/img/Untitled design-15.png') ;
    background-size: cover;
  }

  .bg-trago {
    background: linear-gradient(rgba(44, 95, 45, 0.9), rgba(44, 95, 45,0.9)), url('/assets/img/re_6.jpeg') center ;
  }

  .para-img {
    float: right;
    border-top-left-radius: calc(0.5rem - 1px);
    border-top-right-radius: calc(0.5rem - 1px);
    border-bottom-left-radius: calc(0.5rem - 1px);
    border-bottom-right-radius: calc(0.5rem - 1px);
    margin: 0px 0px 15px 20px;
  }
</style>